import { combineReducers } from '@reduxjs/toolkit'

//import productDisplayReducer from 'features/productDisplay/productDisplaySlice'
//import productDetailsReducer from 'features/productDetails/productDetailsSlice'
import productListReducer from 'features/productList/productListSlice'
import paymentFormReducer from 'features/paymentForm/paymentFormSlice'
import loginFormReducer from 'features/loginForm/loginFormSlice'
import userReducer from 'features/user/userSlice'
import adminProductReducer from 'features/admin/adminProductSlice'
import orderReducer from 'features/order/orderSlice'
import systemReducer from 'features/system/systemSlice'
import dealReducer from 'features/deal/dealSlice'

const rootReducer = combineReducers({
  productList: productListReducer,
  paymentForm: paymentFormReducer,
  loginForm: loginFormReducer,
  user: userReducer,
  adminProduct: adminProductReducer,
  order: orderReducer,
  system: systemReducer,
  deal: dealReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
