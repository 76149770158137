import React from 'react'
import favIcon from 'images/fav-filled.svg'
import userIcon from 'images/user-icon.svg'
import adminIcon from 'images/admin-icon.svg'
import UserProfile from 'features/user/UserProfile'
import { toast } from 'react-toastify'
import backArrow from 'images/back-arrow.svg'
import { useHistory, useLocation } from 'react-router-dom'

const About = () => {
  const history = useHistory()
  const onBackClicked = e => {
    history.push('/')
    //history.goBack()

  }
  return (
    <div className="about-page">

      <section>
        <p>WOW Homestuff is a young and energetic platform for sharing and buying outstanding products. From the hundreds of millions of household products around the world, we constantly discover the most outstanding products in each sub-category. Our products have an exquisitely beautiful design, exceptionally powerful functions or are both high in quality and practicality.</p>
        <p>In order to let you get that “WOW” feeling at first sight and also enjoy the pleasant life it brings to you through your daily use, each of our products has been repeatedly compared and tested by our professional team.</p>
        <p>We redefine "cost-effectiveness". Our world no longer needs another large and comprehensive shopping platform. What it needs is a platform that will greatly save your time when choosing and comparing products and decease the cost that is wasted from purchasing flawed products- bringing leisure time back to your daily lives.</p>
        <p>The definition of a product should not be the price, but its life cycle and the memories it leaves you with. We believe that eternal memories should be stored in beautiful objects that are thoughtfully selected.</p>
        <p>Sharing is a virtue. If our collection just happens to be in line with your taste, please share it with your family or friends. Connection and love make the world a better place. The discounts offered after sharing is our way to make this world better, with the hope that every good product can create a connection between each other.</p>
      </section>
      <div className="back-arrow-wrap" onClick={onBackClicked}><img src={ backArrow } alt="back" width="24" height="24"/><button className="link-text">Back to home</button></div>
    </div>
  )
}

/*



    </div>
  )
}
*/
export default About;
