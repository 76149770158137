import axios from 'axios'
//import parseLink, { Links } from 'parse-link-header'

export const API_HOST = process.env.REACT_APP_API || 'https://wabox.anniez.net'
export const WS_HOST = process.env.REACT_WS_HOST || 'wabox.anniez.net'
//export const PRODUCT_IMAGE_BASE = `${API_HOST}/api/product/image`
export const PRODUCT_IMAGE_HOST = process.env.PRODUCT_IMAGE_HOST || `https://img.wabox.anniez.net`


axios.defaults.baseURL = API_HOST
axios.defaults.headers.common['content-type'] = 'application/json'
axios.defaults.headers.common['accept'] = 'application/json'
//axios.defaults.headers.common['authorization'] = 'AUTH_TOKEN'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.withCredentials = true

export interface Product {
  id: number
  name: string
  brand: string
  description: string
  meta: any
  dimensions: string
  unit: string
  pic?: string | null
  price: number
  images?: number[]
  weight: string
  primaryImage?: number
  primary_image_uuid: string
  uuid?: string
  deals: number[]
  status: string
}

interface IProduct {
  [key: string]: number | string
}


export type PaymentDetail = {
  address: string
}

export type UserDetail = {
  id: number,
  email: string,
  firstName: string,
  lastName: string,
  authToken: string,
  favorite: Array<number>,
  avatar: string,
  lastLogin: Date,
  phoneNumber: string,
  address: string,
  jwt: string | null,
  primaryAddressId: number | null,
  verified: boolean | null,
  principles: Array<string>,
}

export type ProductImage = {
  id?: number,
  uuid?: string,
  name: string,
  data?: any,
  dataUrl?: string,
  mimeType?: string,
  size?: number,
  uploaded: string,
  uploadedBy?: number,
  lastModified: number,
  isInSync: boolean,
  isPrimary: boolean,
}

export type OrderDetail = {
  id: number,
  product_id: number,
  payment_method: string,
  payment_status: string,
  payment_time: number,
  create_time: number,
  item_price: number,
  quantity: number,
  shipping_fee: number,
  shipping_to: string,
  shipping_address: string,
  total_amount: number,
  delivered_to: string,
  phase: string,
}

export type Shipping = {
  fee: number,
  suburbs: string[]
}

export type Address = {
  id: number | null
  first_name: string
  last_name: string
  phone: string
  line1: string
  line2: string
  admin_area_1: string
  admin_area_2: string
  postal_code: string
  country_code: string
  email?: string
}

export type PostalCodeInfo = {
  state: string,
  suburbs: string[]
}

export type Deal = {
  id: number
  name: string
  discount: number
  discount_type: string
  min_attending: number
}
export type APIStatus = {
  status: string,
}
const zipResponse = (data: string, headers: object) : IProduct[] => {
  let result:IProduct[] = []
  let j = JSON.parse(data)
  j.data.forEach((row: any[]) => {
      let item: IProduct = {}
      j.headers.forEach((h: string, idx: number) => {
        item[h] = row[idx]
      })
      result.push(item)
    })
    return result
}

const axiosWithAuth = axios.create({
  baseURL: API_HOST,
  timeout: 60000,
	headers: {
    Authorization: 'Bearer ' + localStorage.getItem('waJWT')
  }
});


export async function processGetProducts(category: string): Promise<Product[]> {
  const endpoint = `/api/products?category=${category}`
  const url = `${API_HOST}${endpoint}`
  try {
    const result = await axiosWithAuth.get<Product[]>(url, {transformResponse:[zipResponse]})
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processGetAdminProducts(): Promise<Product[]> {
  const endpoint = `/api/admin/products`
  const url = `${API_HOST}${endpoint}`
  try {
    const result = await axiosWithAuth.get<Product[]>(url, {transformResponse:[zipResponse]})
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function capturePayment(paypalOrderId: string, authorizationId: string, orderId: number): Promise<PaymentDetail> {
  const endpoint = `/api/payment/capture`
  try {
    let dev
    if (process.env.NODE_ENV === 'test') {
      dev = false
    } else {
      dev = true
    }
    const result = await axiosWithAuth.post<PaymentDetail>(endpoint, {
      paypalOrderId: paypalOrderId,
      authorizationId: authorizationId,
      orderId: orderId,
      dev: dev
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processLogin(email: string, password: string, favorites?: number[]): Promise<UserDetail> {
  const endpoint = `/api/user/login`
  const url = `${API_HOST}${endpoint}`
  try {
    const result = await axios.post<UserDetail>(url, {
      email: email,
      password: password,
      favorites: favorites
    })
    axiosWithAuth.defaults.headers['Authorization'] = `Bearer ${result.data.jwt}`
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processLogout(): Promise<void> {
  const endpoint = `/api/user/logout`
  const url = `${API_HOST}${endpoint}`
  try {
    await axiosWithAuth.post<null>(url, {
    })
    delete axiosWithAuth.defaults.headers['Authorization']
    return
  } catch (err) {
    console.error(err)
    throw err
  }
}
export async function processGoogleLogin(id_token: string, favorites?: number[]): Promise<UserDetail> {
  const endpoint = `/api/user/google_login`
  const url = `${API_HOST}${endpoint}`
  try {
    const result = await axios.post<UserDetail>(url, {
      id_token: id_token,
      favorites: favorites
    })
    axiosWithAuth.defaults.headers['Authorization'] = `Bearer ${result.data.jwt}`
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processSignup(data: any, favorites?: number[]): Promise<UserDetail> {
  const endpoint = `/api/user/signup`
  const url = `${API_HOST}${endpoint}`
  try {
    const result = await axios.post<UserDetail>(url, {
      email: data['email'],
      first_name: data['firstName'],
      password: data['password'],
      last_name: data['lastName'],
      phone_number: data['phone_number'],
      address: data['address'],
      favorites: favorites,
      avatarFg: data['avatarFg'],
      avatarBg: data['avatarBg'],
      avatarId: data['avatarId']
    })
    axiosWithAuth.defaults.headers['Authorization'] = `Bearer ${result.data.jwt}`
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function verifyEmailAvailable(email: string): Promise<boolean> {
  const endpoint = `/api/user/email_available`
  const url = `${API_HOST}${endpoint}`
  try {
    const result = await axios.post<any>(url, {
      email: email
    })
    return result.data.available
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processRefreshLoginInfo(jwt: string): Promise<UserDetail> {
  const endpoint = `/api/user/refresh_info`
  const url = `${API_HOST}${endpoint}`
  try {
    const result = await axios.post<any>(url, {
      jwt: jwt
    })
    return result.data
  } catch (err) {
    console.error('Error refresh login info:', err.response.data.error)
    throw err
  }
}

export async function processRefreshUserInfo(): Promise<UserDetail> {
  const endpoint = `/api/user/detail`
  try {
    const result = await axiosWithAuth.get<UserDetail>(`${endpoint}`)
    return result.data
  } catch (err) {
    console.error('Error refresh user info:', err)
    throw err
  }
}

export async function addFavorite(productId: number): Promise<UserDetail> {
  const endpoint = `/api/user/add_favorite`
  try {
    const result = await axiosWithAuth.post<any>(`${endpoint}/${productId}`)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}
export async function removeFavorite(productId: number): Promise<UserDetail> {
  const endpoint = `/api/user/remove_favorite`
  try {
    const result = await axiosWithAuth.post<any>(`${endpoint}/${productId}`)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}
export async function addProduct(product: Product, imageList: ProductImage[]): Promise<Product> {
  const endpoint = `/api/product/add`
  let images: number[] = []
  let primaryImage: number = 0
  for (let im of imageList) {
    images.push(im.id as number)
    if (im.isPrimary === true) {
      primaryImage = im.id as number
    }
  }
  product.images = images
  product.primaryImage = primaryImage
  try {
    const result = await axiosWithAuth.post<any>(endpoint, {
      ...product
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function updateProduct(product: Product, imageList: ProductImage[]): Promise<Product> {
  const endpoint = `/api/product/update`
  let images: number[] = []
  let primaryImage: number = 0
  for (let im of imageList) {
    images.push(im.id as number)
    if (im.isPrimary === true) {
      primaryImage = im.id as number
    }
  }
  product.images = images
  product.primaryImage = primaryImage
  try {
    const result = await axiosWithAuth.post<any>(`${endpoint}/${product.id}`, {
      ...product
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}
export async function addImage(image: ProductImage): Promise<ProductImage> {
  const endpoint = `/api/image/add`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, {
      ...image
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}


export async function processGetOrders(): Promise<OrderDetail[]> {
  const endpoint = `/api/v2/orders`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, {
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processGetAllOrders(): Promise<OrderDetail[]> {
  const endpoint = `/api/admin/orders`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, {
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function loadImages(imageIds: number[] | null): Promise<ProductImage[]> {
  const endpoint = `/api/image/load`
  try {
    const result = await axiosWithAuth.post<ProductImage[]>(endpoint, {
      imageIds: imageIds
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

/*
export async function processGetFavorites(): Promise<Product[]> {
  const endpoint = `/api/products?category=favorites`
  const config = {
    transformResponse: [zipResponse]
  }
  try {
    const result = await axiosWithAuth.get<Product[]>(endpoint, config)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}
*/

export async function getProductDetail(productUUID: string): Promise<Product> {
  const endpoint = `/api/product/detail/${productUUID}`
  try {
    const result = await axiosWithAuth.get<Product>(endpoint)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processCreateOrder(
  products: Product[], quantity: number, addressId: number, 
  dealId?: number | null, joinRunningDeal?: number | null
): Promise<OrderDetail> {
  const endpoint = `/api/order/create`
  console.log('dealId provided', dealId)
  try {
    const result = await axiosWithAuth.post<OrderDetail>(endpoint, {
      products: products,
      quantity: quantity,
      addressId: addressId,
      dealId: dealId,
      joinRunningDeal: joinRunningDeal
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function getShippingFee(postcode: string): Promise<Shipping> {
  const endpoint = `/api/order/shipping`
  try {
    const result = await axiosWithAuth.post<Shipping>(endpoint, {
      postcode: postcode
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processGetPostalCodeInfo(postal_code: string): Promise<PostalCodeInfo> {
  const endpoint = `/api/address/postal_code`
  try {
    const result = await axiosWithAuth.get<PostalCodeInfo>(`${endpoint}/${postal_code}`)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processGetAddresses(): Promise<Address[]> {
  const endpoint = `/api/user/addresses`
  try {
    const result = await axiosWithAuth.get<Address[]>(`${endpoint}`)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}
export async function processCreateAddress(data: any, makePrimary = false): Promise<Address> {
  const endpoint = `/api/user/address/create`
  try {
    const result = await axiosWithAuth.post<Address>(`${endpoint}`, {
      line1: data.line1,
      line2: data.line2,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      postal_code: data.postal_code,
      admin_area_1: data.state,
      admin_area_2: data.suburb,
      country_code: 'AU',
      make_primary: makePrimary
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processDeleteAddress(addressId: number): Promise<null> {
  const endpoint = `/api/user/address/delete`
  try {
    await axiosWithAuth.post<Address>(`${endpoint}`, {
      addressId: addressId
    })
    return null
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processEditAddress(data: any, guestJWT?: string | null): Promise<Address> {
  const endpoint = `/api/user/address`
  try {
    const result = await axiosWithAuth.post<Address>(`${endpoint}/${data.id as number}`, {
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      line1: data.line1,
      line2: data.line2,
      postal_code: data.postal_code,
      admin_area_1: data.state,
      admin_area_2: data.suburb,
      country_code: 'AU',
      email: data.email,
      guestJWT: guestJWT
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processEditPassword(oldPassword: string, password: string): Promise<APIStatus> {
  const endpoint = `/api/user/password`
  try {
    const result = await axiosWithAuth.post(endpoint, {
      old_password: oldPassword,
      password: password
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processMakeAddressPrimary(addressId: number): Promise<null> {
  const endpoint = `/api/user/address/primary`
  try {
    const result = await axiosWithAuth.post(`${endpoint}/${addressId}`)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processResetPassword(userId: string, pinCode: string, password: string): Promise<null> {
  const endpoint = `/api/user/reset_password`
	const config = {
  }
  try {
    const result = await axiosWithAuth.post(endpoint, {
      userId: userId,
      password: password,
      pinCode: pinCode,
    }, config)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processSendResetMail(email: string): Promise<null> {
  const endpoint = `/api/user/forget_password`
  const config = {
  }
  try {
    const result = await axiosWithAuth.post(endpoint, {
      email: email,
    }, config)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processResendVerificationEmail(): Promise<APIStatus> {
  const endpoint = `/api/user/resend_verify`
  try {
    const result = await axiosWithAuth.get<APIStatus>(endpoint)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
  
}

export async function processCreateGuestAddress(data: any): Promise<{user: UserDetail, address: Address}> {
  const endpoint = `/api/user/address/create/guest`
  try {
    const result = await axiosWithAuth.post<{user: UserDetail, address: Address}>(endpoint, {
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      line1: data.line1,
      line2: data.line2,
      admin_area_1: data.state,
      admin_area_2: data.suburb,
      postal_code: data.postal_code,
      country_code: data.country_code,
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }

}

export async function createGuestOrder(data: any): Promise<any> {
  const endpoint = `/api/order/guest`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, {
      products: data.products,
      quantity: data.quantity,
      address: 'a'
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}


export async function processReviseOrderAddress(orderId: number, addressId: number, guestJWT?: string | null): Promise<null> {
  const endpoint = `/api/order/revise/address`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, {
      orderId: orderId,
      addressId: addressId,
      guestJWT: guestJWT
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}
export async function processCancelOrder(orderId: number, guestJWT?: string | null): Promise<APIStatus> {
  const endpoint = `/api/order/cancel`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, {
      orderId: orderId,
      guestJWT: guestJWT
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processOrderUpdatePhase(orderId: number, nextPhase: string): Promise<APIStatus> {
  const endpoint = `/api/order/update/phase`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, {
      orderId: orderId,
      nextPhase: nextPhase
    })
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

type DealSchema = {
  id: number
  name: string
  discount: number
  discount_type: string
  min_attending: number
}

type RunningDealSchema = {
  id: number
  deal_id: number
  product_id: number
  start_user_id: number
  participants: number[]
  expiry: Date
  completed: boolean
}
export async function processGetDeals(): Promise<DealSchema> {
  const endpoint = `/api/deals`
  try {
    const result = await axiosWithAuth.get<any>(endpoint)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function processGetActiveDeals(product_id: number): Promise<DealSchema> {
  const endpoint = `/deal/running/by_product`
  try {
    const result = await axiosWithAuth.get<any>(`${endpoint}/${product_id}`)
    return result.data
  } catch (err) {
    console.error(err)
    throw err
  }
}
export async function getProductsV2(): Promise<any> {
  const endpoint = `/api/v2/products`
  try {
    const result = await axiosWithAuth.get<any>(endpoint)
    return result.data
  } catch (err) {
    throw err
  }
}

export async function processStartDeal(): Promise<any> {
  const endpoint = `/api/deal/running/start`
  try {
    const result = await axiosWithAuth.get<any>(endpoint)
    return result.data
  } catch (err) {
    throw err
  }
}

export async function processParticipateDeal(): Promise<any> {
  const endpoint = `/api/deal/running/participate/7`
  try {
    const result = await axiosWithAuth.get<any>(endpoint)
    return result.data
  } catch (err) {
    throw err
  }
}

export type RunningDeal = {
  id: number
  deal_id: number
  product_id: number
  start_user_id: number
  participants: number[]
  expiry: Date
  completed: boolean
  deal?: Deal
  number_of_deals: number
  related_product_uuids?: string[]
  related_product_thumbnails?: string[]
}
export async function getRunningDeals(productId?: number): Promise<RunningDeal[]> {
  const endpoint = `/api/deals/running`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, {
      product_id: productId
    })
    return result.data
  } catch (err) {
    throw err
  }

}

export type Notice = {
  id: number
  type_: string
  running_deal_id: number
  message: string
  create_time: Date
}
export async function processGetDealNotices(last: Date): Promise<Notice[]> {
  const endpoint = `/api/notices/deal`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, { last: last })
    return result.data
  } catch (err) {
    throw err
  }
}

export type RoyalPayResult = {
  status: string
  url: string
  code_url?: string
}

export async function processRoyalPay(order_id: number): Promise<RoyalPayResult> {
  const endpoint = `/api/v2/payment/royalpay`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, {
      order_id: order_id
    })
    return result.data
  } catch (err) {
    throw err
  }
}
export async function processRoyalPayQR(order_id: number) {
  const endpoint = `/api/v2/payment/qrcode`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, {
      order_id: order_id
    })
    return result.data
  } catch (err) {
    throw err
  }
}

export async function processContact(formData: any): Promise<any> {
  const endpoint = `/api/v2/contact`
  try {
    const result = await axiosWithAuth.post<any>(endpoint, formData)
    return result.data
  } catch (err) {
    throw err
  }
}
