import React from 'react'
import {
  useParams, useHistory, useLocation, NavLink
} from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { RootState } from 'app/rootReducer'
import { isAdminSelector } from 'features/user/userSlice'
import AdminProductList from 'features/admin/AdminProductList'
import { OrderAdmin } from 'features/admin/OrderAdmin'
import { actionFetchAdminOrders } from 'features/order/orderSlice'
import NewProductPage from 'features/admin/NewProductPage'

export default () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { authLoaded } = useSelector((state: RootState) => state.loginForm)
  const { function_ } = useParams()
  const isAdmin = useSelector(isAdminSelector)
  useEffect(() => {
    if (authLoaded) {
      if (/^\/admin/.test(location.pathname) && !isAdmin) {
        history.replace('/')
      } else {
        dispatch(actionFetchAdminOrders())
      }
    }
  }, [dispatch, authLoaded]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    isAdmin ?
    <div className="admin-dashboard">
      <div className="admin-menu">
        <div className="admin-menu-inner">
          <NavLink to={'/admin/order'} activeClassName="active">Orders</NavLink>
          <NavLink to={'/admin/product'} activeClassName="active">Products</NavLink>
        </div>
      </div>
        { (function_ === undefined || function_ === 'product') &&
          <div className="product-dashboard">
            <div className="admin-product-list">
              <AdminProductList />
            </div>
            <div className="admin-new-product-panel">
              <NewProductPage admin={true} />
            </div>
          </div>
        }
        { (function_ === 'order') &&
            <OrderAdmin/>
        }
    </div> : null
  )
}
