import React, { useEffect, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateQuantity, actionCancelPayment, Stages,
  actionSetShipping, dealsSelector, calculateDiscount,
  discountSelector
} from './paymentFormSlice'
import { actionGetRunningDeals, runningDealByProductSelector } from '../deal/dealSlice'
import { primaryAddressSelector } from 'features/user/userSlice'
import { Product, PRODUCT_IMAGE_HOST } from 'api/API'
import { RootState } from 'app/rootReducer'
import StartDeal from './StartDeal'
import JoinDeal from './JoinDeal'
import classNames from 'classnames'
import closeButton from 'images/close_grey.svg'

const QuantityButton = (prop: any) => {
  const dispatch = useDispatch()
  const { value, quantity } = prop

  const handleUpdateQuantity = (e: MouseEvent) => {
    dispatch(updateQuantity(value))
  }
  return <span key={value} className={classNames('button', {active: quantity === value})} onClick={handleUpdateQuantity} >{value}</span>
}

export default ({ product }: { product: Product }) => {
  const dispatch = useDispatch()
  const onClosePayment = (e: MouseEvent) => {
    dispatch(actionCancelPayment())
  }
  useEffect(() => {
    //dispatch(actionGetRunningDeals())
  }, [dispatch])
  const primaryAddress = useSelector(primaryAddressSelector)
  //const [postcode, setPostcode] = useState('')
  const { quantity, shippingFee, stage, joinDeal } = useSelector((state: RootState) => state.paymentForm)
  let subtotal = quantity * product!.price
  if (shippingFee) {
    subtotal += shippingFee
  }

  const onPostalCodeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.length === 4) {
      dispatch(actionSetShipping(value))
    }
  }
  const deals = useSelector((state: RootState) => dealsSelector(state, product!.id))
  const discount = useSelector((state: RootState) => discountSelector(state, product!.id))
  if (discount) {
    subtotal -= discount * quantity
  }

  const runningDeals = useSelector((state: RootState) => runningDealByProductSelector(state, product!.id))
  return (
    <div className="order-summary">
      <div className="product-thumbnail">
        <img src={`${PRODUCT_IMAGE_HOST}/product_thumbnail/` + product!.primary_image_uuid + '.jpg'} alt="Product thumbnail" />
      </div>
      <div className="payment-row product-summary flex-grid">
        <div className="product-left-column product-title">{product!.name} <span className="link link-product-detail">Product Details</span></div>
        { stage === Stages.SETUP && <div className="close-btn" onClick={onClosePayment}><img src={ closeButton } alt="close" width="20" height="20"/></div> }
      </div>
      <div className="payment-row order-detail flex-grid">
        <div className="product-left-column qty-wrap">
          <span className="qty">Qty:</span>
          {[1,2].map(value => <QuantityButton key={value} value={value} quantity={quantity} />)}
        </div>
        <div className="price pink-text">{quantity} &times; ${product!.price!.toFixed(2)}</div>
      </div>

      {discount &&
        <div className="payment-row discount flex-grid">
          <div className="product-left-column shipping-text">Discount: </div>
          <div className="price pink-text">-${(discount * quantity).toFixed(2)}</div>
        </div>
      }
      <div className="payment-row shipping-fee flex-grid">
        { stage === Stages.SETUP ?
          <>
          <div className="product-left-column shipping-text">Shipping Fee:
            { primaryAddress ?
              <>

              <input className="shipping-postcode" type="text" name="postal_code"
                defaultValue={primaryAddress.postal_code} placeholder="Postal Code"
                onChange={onPostalCodeChanged}
              />
              <div className="shipping-note">Based on primary address</div>
              </>:
              <input className="shipping-postcode" type="text" name="postal_code" placeholder="Postal Code"
                onChange={onPostalCodeChanged}
              />
            }
          </div>

          </>
          :
          <div className="product-left-column shipping-text">Shipping Fee: </div>
        }
        <div className="price pink-text">{(shippingFee && shippingFee.toFixed(2)) || '-'}</div>

      </div>
      <div className="payment-row subtotal flex-grid">
        <div className="product-left-column">Subtotal</div>
        <div className="price pink-text">${subtotal.toFixed(2)}</div>
      </div>
      {joinDeal ? <JoinDeal product={product} runningDeals={runningDeals} /> : <StartDeal product={product} />}
    </div>
  )
}
