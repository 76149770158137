import React, { MouseEvent, useState } from 'react'
import { API_HOST, PRODUCT_IMAGE_HOST } from 'api/API'
import { useSelector, useDispatch } from 'react-redux'
import {
  actionRemoveFilterFavorite,
  TFilter, FilterType, productsSelector, ignoreBuilding,
  setPopupJoinDeal
} from './productListSlice'
import {
  runningDealSelector, actionHideHint
} from 'features/deal/dealSlice'
import {
  setJoiningRunning
} from 'features/paymentForm/paymentFormSlice'
import { RootState } from 'app/rootReducer'
import ProductList from './ProductList'
import classNames from 'classnames'
import closeIcon from 'images/close.svg'
import homepageBanner from 'images/Xmas-banner.png'
import Popup from 'reactjs-popup'
import unknownUserIcon from 'images/unknown-user-icon.svg'

const DealIcon = ({
  start_user_id, deal, id, setRunning, number_of_deals,
  related_product_uuids, related_product_thumbnails, me = false,
  showProducts = true
}: any) => {
  const dispatch = useDispatch()
  const { userDetail } = useSelector((state: RootState) => state.user)
  const [ toExpand, setToExpand ] = useState(false)
  const onDealClicked = async () => {
    if (setRunning !== null) {
      await dispatch(setPopupJoinDeal(setRunning))
    } else {
      // clear deal
      await dispatch(setJoiningRunning(setRunning))
    }
    setToExpand(false)
  }
  const onExpand = () => {
    setToExpand(true)
  }
  const onCollapse = () => {
    setToExpand(false)
  }
  let myIcon = null
  if (me) {
    if (userDetail) {
      myIcon = <img src={API_HOST + `/api/user/avatar/${userDetail.id}`} className="participant-circle"/>
    } else {
      myIcon = <img src={unknownUserIcon} className="participant-circle" />
    }
  }
  return <div className={classNames('deal-avatar-container', {expand: (showProducts && toExpand)})}
      onMouseEnter={onExpand}
      onMouseLeave={onCollapse}
  >
      <div className={classNames('deal-avatar-circle')} data-running-deal-id={id}
        onClick={onDealClicked}
      >
        <img src={API_HOST + `/api/user/avatar/${start_user_id}`}  />
        {myIcon}
        <div className="deal-count">{number_of_deals}</div>
      </div>
      {(showProducts && toExpand) &&
        <div className="deal-product">
          {related_product_thumbnails.map((tn: any, idx: number) =>
            <a href={`/product/${related_product_uuids[idx]}`} className="deal-product-preview-wrap">
              <img key={tn} className="deal-product-preview" src={`${PRODUCT_IMAGE_HOST}/product_thumbnail/${tn}.jpg`} />
            </a>
          )}
        </div>
      }
    </div>
}

export const DealStarter = () => {
  const runningDeals = useSelector(runningDealSelector)
  const { showHint } = useSelector((state: RootState) => state.deal)
  const { joiningRunning } = useSelector((state: RootState) => state.paymentForm)
  const runningDeal = runningDeals.find((d: any) => d.id === joiningRunning)
  const dispatch = useDispatch()
  return (
    runningDeal ? null :
          <div className={classNames('deal-starter-bar-border', {active: showHint})}>
            <div className="deal-starter-bar">
              <div className="deal-list">
                { showHint &&
                  <div className="unlock-message-box arrow-left">
                    Click any of the Avatar to join the deal they opened.
                    <div className="got-it-link" onClick={() => dispatch(actionHideHint(null))}>Got it!</div>
                  </div>
                }
                  { runningDeals.map((r: any) => <DealIcon key={r.id} setRunning={r.id} {...r}
                    />) }

              </div>
            </div>
          </div>

  )
}

export const DealFloat = () => {
  const runningDeals = useSelector(runningDealSelector)
  const { joiningRunning } = useSelector((state: RootState) => state.paymentForm)
  const runningDeal = runningDeals.find((d: any) => d.id === joiningRunning)
  return runningDeal ?
    <>
      <div className="deal-detail-wrap">
        <div className="deal-detail">
          <DealIcon key={runningDeal.id} setRunning={null} {...runningDeal}
            me={true} showProducts={false}/>
        </div>
        <div className="message-box">
          You are joining {runningDeal.start_user_name}'s deal. Discount has been unlocked. <br/>Happy shopping!
        </div>
      </div>
    </>
    : null
}
const ProductListPage = ({ category, notices, removeNoticesByProduct }: any) => {
  const dispatch = useDispatch()

  const {
    // eslint-disable-next-line
    isLoading, page, productId, productError,
    filters, building, popupJoinDeal
  } = useSelector((state: RootState) => state.productList)
  const products = useSelector(productsSelector)
  const userState = useSelector((state: RootState) => state.user)
  const onRemoveFavoriteClicked = (e: MouseEvent) => {
    dispatch(actionRemoveFilterFavorite())
  }
  const renderFilter = (f: TFilter): string => {
    switch (f.type) {
      case FilterType.FAVORITE:
        return 'My Favourites'
      case FilterType.NAME:
        break
    }
    return 'Unknown Filter'
  }
  //<div className="price pink-text">${products.find(p => p.id === productId)!.price}</div>
  //<section className="homepage-banner">
  //  <img src={homepageBanner} className="homepage-banner-img" alt=""/>
  //</section>
  //<section className="product-filters">
  //  <div className="product-filters-title">Filter by: </div>{filters!.map((f, index) => <div className="filter-item" key={index} onClick={onRemoveFavoriteClicked}>{renderFilter(f)} <div className="close-btn" ><img alt="Close" src={closeIcon}  /></div></div>)}
  //</section>
  //
  const runningDeals = useSelector(runningDealSelector)
  const { joiningRunning } = useSelector((state: RootState) => state.paymentForm)
  const runningDeal = runningDeals.find((d: any) => d.id === joiningRunning)
  const noticeDeal = runningDeals.find((d: any) => d.id === popupJoinDeal)
  const onIgnoreBuilding = () => {
    dispatch(ignoreBuilding())
  }
  const onJoinOK = () => {
    dispatch(setJoiningRunning(popupJoinDeal))
    dispatch(setPopupJoinDeal(null))
  }
  const onCancel = () => {
    dispatch(setPopupJoinDeal(null))
  }
  const myIcon: any = userState.userDetail ?
    <img src={API_HOST + '/api/user/avatar/' + userState.userDetail.id} className="join-deal-circle" />
    :
    <img src={unknownUserIcon} width="36" height="36" className="join-deal-circle" />;
  return (
    building ? <img onDoubleClick={onIgnoreBuilding} src={process.env.PUBLIC_URL + '/images/busy-building.svg'} /> :
    <>
      <DealStarter/>
      <section className={classNames('product-list', {'profile-active': userState.mainFormActive})}>
        <section className={classNames('list-grid', {'no-starter': joiningRunning})}>
          <Popup open={popupJoinDeal !== null} closeOnDocumentClick closeOnEscape
            contentStyle={{width: '90%'}}
            onClose={onCancel} >
            <div className="join-detail">
              <div className="title">Congratulations</div>
              <div className="avatars">
                <div className="starter-icon">
                  <img src={API_HOST + '/api/user/avatar/' + noticeDeal?.start_user_id} className="join-deal-circle" />
                </div>
                <div className="plus-icon"> + </div>
                <div className="my-icon">
                  {myIcon}
                </div>
                <div className="equal-sign">
                =
                </div>
                <div className="unlock-icon">
                </div>
              </div>
              <div className="message">
                You are joining a Wow! deal, discount has unlocked! Happy shopping!
              </div>
              <div className="buttons">
                <button className="btn-ok" onClick={onJoinOK}>OK</button>
                <button className="text-button" onClick={onCancel}>Cancel</button>
              </div>
            </div>
          </Popup>
          { isLoading ?
            (<ProductList isLoading={true}/>) :
            (
              <>
              <DealFloat />
              <ProductList
              products={products} productId={productId}
              notices={notices} removeNoticesByProduct={removeNoticesByProduct}
              />
              </>
            )
            }
        </section>
      </section>
    </>
	)
}
export default ProductListPage
