import React, { MouseEvent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionBuyProduct, actionFetchProducts, selectProduct, setProductDetailImage } from './productListSlice'
import {
  actionHideUserProfile, actionAddFavorite, actionRemoveFavorite,
  favoriteProductSelector
} from 'features/user/userSlice'
import { runningDealByProductSelector, actionShowHint } from 'features/deal/dealSlice'
import { RootState } from 'app/rootReducer'
import { dealsSelector, bestDealSelector,
  actionProceedToLogin, actionProceedToDetail, actionCreateOrder,
  actionShowPaymentMethods, setJoinDeal, setStage, Stages, setActiveDealId
} from '../paymentForm/paymentFormSlice'
import classNames from 'classnames'
import { Product, PRODUCT_IMAGE_HOST, RunningDeal } from 'api/API'
import { Link, useHistory } from 'react-router-dom'
import favIcon from 'images/fav-filled.svg'
import favIconUnfilled from 'images/fav-unfilled.svg'
import moment from 'moment'

type Props = {
  product: Product
  notices: any
  removeNoticesByProduct: any
}
export const DealNotice = ({ notices, removeNoticesByProduct, productId }: any) => {
  const [now, setNow] = useState(new Date())
  const [timer, setTimer] = useState(null as any)
  const [notice, setNotice] = useState(null as any)
  let isMounted = false
  const resetNotice = () => {
    if (isMounted) {
      console.log('timeout still called')
      setNotice(null)
      removeNoticesByProduct(productId)
    }
  }
  useEffect(() => {
    isMounted = true
    if (notices.length > 0) {
      if (timer) {
        window.clearTimeout(timer)
      }
      const t = window.setTimeout(resetNotice, 5000)
      setTimer(t)
      console.log('set timer handle', t)
      setNotice(notices[notices.length-1])
    }
    return () => {
      isMounted = false
      if (timer) {
        console.log('clear timer', timer, productId)
        window.clearTimeout(timer)
        setTimer(null)
      }
    }
  }, [notices])
  if (notice) {
    if (notice.type === 'deal_start') {
      return <div className="new-deal-notification">{notice!.start_user_name} opened a new deal!</div>
    } else {
      return <div className="new-deal-notification">{notice!.user_name} joined {notice!.start_user_name + "'"}s deal!</div>
    }
  } else {
    return null
  }
}

const ProductListItem = ({ product, notices, removeNoticesByProduct }: Props) => {
  const dispatch = useDispatch()
  const [now, setNow] = useState(new Date())

  const favorites = useSelector(favoriteProductSelector)
  const { products } = useSelector((state: RootState) => state.productList)
  let runningDeals = useSelector((state: RootState) => runningDealByProductSelector(state, product!.id))
  runningDeals = runningDeals.filter((rd: RunningDeal) => moment(rd.expiry) > moment(now))
  const _notices = notices.filter((n: any) => n.product_id === product.id)

  const { deal, discountAmount } = useSelector((state: RootState) => bestDealSelector(state, product!.id))
  const isFav = favorites.some(p => p?.id === product.id)
  const onFavClicked = (e: MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
    if (isFav) {
      dispatch(actionRemoveFavorite(product.id))
    } else {
      dispatch(actionAddFavorite(product.id))
    }
  }
  /*
  let intervalHandle: number
  useEffect(() => {
    intervalHandle = window.setInterval(() => {
      setNow(new Date())
    }, 1000)
    return () => {
      window.clearInterval(intervalHandle)
    }
  }, [dispatch])
  */
  //<span className="deal-running">{ runningDeals.length }</span>
  
  const { joiningRunning, deliveryAddressId, quantity } = useSelector((state: RootState) => state.paymentForm)
  const dealJoined = joiningRunning !== null
  const { userDetail } = useSelector((state: RootState) => state.user)
  const onBuyClicked = (e: MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
    if (dealJoined) {
      return
    }
    dispatch(actionHideUserProfile())
    dispatch(actionBuyProduct(product))
  }
	const history = useHistory()
  const onDealClicked = async (e: MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
    if (!dealJoined) {
      dispatch(actionShowHint(null))
      return
    }
    dispatch(actionHideUserProfile())
    //await dispatch(actionBuyProduct(product, deal.id))
    if (products.length === 0) {
      await dispatch(actionFetchProducts('all'))
    }
    await dispatch(selectProduct(product))
    if (deal !== null) {
      await dispatch(setJoinDeal(deal.id))
      await dispatch(setActiveDealId(deal.id))
    }
    await dispatch(setStage(Stages.SETUP))
    if (userDetail === null) {
      dispatch(actionProceedToLogin())
    } else {
      await dispatch(actionCreateOrder([product!], quantity, deliveryAddressId!))
      if (deliveryAddressId !== null) {
        await dispatch(actionShowPaymentMethods())
      }
      dispatch(actionProceedToDetail())
    }
  }
  const doGotoProductDetail = (e: MouseEvent) => {
		e.preventDefault()
    sessionStorage.setItem('productDetailVisited', product.uuid as string)
    //dispatch(setProductDetailImage(null))

		history.push(`/product/${product.uuid}`)
    return
  }
  return (
    <div className={classNames('product-container')}>
      <div className="product-image-wrap" id={`product_${product.uuid}`}>
        <a href={`/product/${product.uuid}`} onClick={doGotoProductDetail} >
          <img src={`${PRODUCT_IMAGE_HOST}/product_thumbnail/${product.primary_image_uuid}.jpg`} className="product-image" alt={product.name}
          />
        </a>
        <div className="fav-icon" onClick={onFavClicked} ><img src={isFav ? favIcon : favIconUnfilled} alt="favourite" /></div>
        <div className="product-title">
          <p>{product.name}</p>
        </div>
      </div>
      <div className="order-button-wrap flex-grid">
        <div onClick={onBuyClicked} className={classNames(['action-button', 'action-button-left', 'buy-button', 'col-4'],
          {disabled: dealJoined})}>
          <div className={classNames('regular-price', {disabled: dealJoined})}>${product.price.toFixed(2)}</div>
          {!dealJoined && <div>Buy</div>}
        </div>
        <div onClick={onDealClicked} className={classNames(['action-button', 'action-button-right', 'deal-button', 'col-8'],
          {disabled: !dealJoined})}>
          <DealNotice notices={_notices} productId={product?.id} removeNoticesByProduct={removeNoticesByProduct} />
          <div>${(product.price - discountAmount).toFixed(2)}</div>
          <div>{deal?.name}</div>
          {!dealJoined && <span className="deal-locked"></span>}
        </div>
      </div>
    </div>
  )
}

export default ProductListItem
