import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import store from './app/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
//import App from './components/App'
//import { loadProducts } from './actions/productActions'
import App from './app/App'
import { toast } from 'react-toastify'

import LogRocket from 'logrocket'

LogRocket.init('qoir6t/tallybuy')

document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`)
function setDocHeight() {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`)
}

window.addEventListener('resize', setDocHeight)
window.addEventListener('orientationchange', setDocHeight)

let head = document.getElementsByTagName('head')[0]
let meta = document.createElement('meta');
meta.setAttribute('property', 'og:title')
meta.content = 'WOW Homestuaff (JS-injected)'
head.appendChild(meta)

meta = document.createElement('meta');
meta.setAttribute('property', 'og:description')
meta.content = 'The place to find stuff with a Wow moment'
head.appendChild(meta)

meta = document.createElement('meta');
meta.setAttribute('property', 'og:image')
meta.content = 'https://img.wabox.anniez.net/product_thumbnail/bc629f4d6fe435538fe84ccbed4b2468.jpg'
head.appendChild(meta)

const render = () => {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    );
}

render()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const onUpdate = (registration: ServiceWorkerRegistration) => {
  const newWorker = registration.waiting
  const onRefreshPage = () => {
    setTimeout(() => {
        window.location.reload()
    }, 100);    
  }

  if (newWorker) {
    newWorker.postMessage({ type: 'SKIP_WAITING' });
    toast(<span>Please <button className="link-text refresh" onClick={onRefreshPage}>here</button> to load latest version.</span>, {
      autoClose: false
    })
  }
}
//serviceWorker.unregister();
serviceWorker.register({onUpdate: onUpdate});
