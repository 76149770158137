import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { processLogin, processGoogleLogin, processSignup, processLogout, processRefreshLoginInfo, Address } from 'api/API'
import { AppThunk } from 'app/store'
import { setDetail as userSetDetail, actionHideUserProfile, actionGetAddresses } from 'features/user/userSlice'
import { 
  actionHandlePostLoginSuccess, actionSetDeliveryAddressId,
  setGuestJWT, setGuestAddress
} from 'features/paymentForm/paymentFormSlice'
type CallbackFunc = () => void

type activeState = {
  mainFormActive?: boolean,
  loginFormActive?: boolean,
  signupFormActive?: boolean,
  shippingFormActive?: boolean,
}
type APIError = Record<'error' | 'message', string>

type LoginFormState = {
  isLoading: boolean,
  authLoaded: boolean,
  show: boolean,
  loginError: APIError | null,
  showLoginForm: boolean,
  showSignupForm: boolean,
  showShippingForm: boolean,
  postLoginHandler: string | null,
} & activeState

let initialState: LoginFormState = {
  isLoading: false,
  authLoaded: false,
  show: false,
  loginError: null,
  showLoginForm: true,
  showSignupForm: true,
  showShippingForm: true,
  mainFormActive: false,
  loginFormActive: true,
  signupFormActive: false,
  shippingFormActive: false,
  postLoginHandler: null
}

function startLoading(state: LoginFormState) {
  state.isLoading = true
}

function loadingFailed(state: LoginFormState, action: PayloadAction<APIError>) {
  state.isLoading = false
  state.loginError = action.payload
}
const loginFormSlice = createSlice({
  name: 'loginForm',
  initialState,
  reducers: {
    loginStart: startLoading,
    loginSuccess(state) {
      state.loginError = null
      state.authLoaded = true
    },
    loginFailure: loadingFailed,
    showLoginForm(state, action: PayloadAction<string | null>) {
      state.show = true
      state.postLoginHandler = action.payload
    },
    hideLoginForm(state) {
      state.show = false
    },
    setActive(state: LoginFormState, action: PayloadAction<activeState>) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key as keyof activeState] = value
      }
    },
    logoutStart: startLoading,
    logoutSuccess(state) {
      state.loginError = null
      //state.authLoaded = false
    },
    logoutFailure: loadingFailed,
    switchToSignupForm(state) {
      state.showLoginForm = false
      state.showSignupForm = true
    },
    switchToLoginForm(state) {
      state.showLoginForm = true
      state.showSignupForm = false
    },
    setAuthLoaded(state, action: PayloadAction<boolean>) {
      state.authLoaded = action.payload
    }
  }
})

export const {
  showLoginForm, hideLoginForm,
  loginStart, loginSuccess, loginFailure,
  logoutStart, logoutSuccess, logoutFailure,
  setActive, switchToSignupForm, switchToLoginForm,
  setAuthLoaded
} = loginFormSlice.actions;

export default loginFormSlice.reducer;

export const actionShowLoginForm = (postLoginHandler: string | null = null): AppThunk => async (dispatch, getState) => {
  dispatch(showLoginForm(postLoginHandler))
  window.setTimeout(() => dispatch(setActive({mainFormActive: true})), 1)

}


export const actionHideLoginForm = (): AppThunk => async (dispatch, getState) => {
  //const user = getState().user;
  /*if (user) {
    dispatch(setGuestState(false))
  }
   */
  dispatch(setActive({mainFormActive: false}))
  window.setTimeout(() => dispatch(hideLoginForm()), 300)

}

export const actionDoLogin = (email: string, password: string, successCallback?: CallbackFunc | null, failCallback?: CallbackFunc | null): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loginStart())
    const { favorites } = getState().user
    const userDetail = await processLogin(email, password, favorites)
    await dispatch(userSetDetail(userDetail))
    dispatch(loginSuccess())
    localStorage.setItem('waJWT', userDetail.jwt as string)
    const state = getState().loginForm
    const paymentState = getState().paymentForm
    if (paymentState.asGuest) {
      dispatch(setGuestJWT(null))
      dispatch(setGuestAddress({} as Address))
    }
    if (state.postLoginHandler !== null) {
      switch (state.postLoginHandler) {
        case 'payment':
          await dispatch(actionGetAddresses())
          if (paymentState.deliveryAddressId === null && userDetail !== null) {
            await dispatch(actionSetDeliveryAddressId(userDetail.primaryAddressId))
          }
          dispatch(actionHandlePostLoginSuccess())
          break
      }
    }
    if (successCallback) {
      successCallback!()
    }
    dispatch(hideLoginForm())
  } catch (err) {
    dispatch(loginFailure(err.response.data))
    if (failCallback) {
      failCallback!()
    }
  }
}

export const actionDoGoogleLogin = (id_token: string, successCallback?: CallbackFunc | null, failCallback?: CallbackFunc | null): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loginStart())
    const { favorites } = getState().user
    const userDetail = await processGoogleLogin(id_token, favorites)
    await dispatch(userSetDetail(userDetail))
    dispatch(loginSuccess())
    localStorage.setItem('waJWT', userDetail.jwt as string)
    /* login successful, do some wrapping up */

    const state = getState().loginForm
    const paymentState = getState().paymentForm
    if (paymentState.asGuest) {
      dispatch(setGuestJWT(null))
      dispatch(setGuestAddress({} as Address))
    }
    if (state.postLoginHandler !== null) {
      switch (state.postLoginHandler) {
        case 'payment':
          await dispatch(actionGetAddresses())
          if (paymentState.deliveryAddressId === null && userDetail !== null) {
            await dispatch(actionSetDeliveryAddressId(userDetail.primaryAddressId))
          }
          dispatch(actionHandlePostLoginSuccess())
          break
      }
    }
    if (successCallback) {
      successCallback!()
    }
    dispatch(hideLoginForm())
  } catch (err) {
    dispatch(loginFailure(err.response.data))
    if (failCallback) {
      failCallback!()
    }
  }
}

export const actionSwitchToSignupForm = (): AppThunk => async (dispatch) => {
  //dispatch(switchToSignupForm())
  window.setTimeout(() => dispatch(setActive({loginFormActive: false, signupFormActive: true, shippingFormActive: false})), 1)
}
export const actionSwitchToLoginForm = (): AppThunk => async (dispatch) => {
  //dispatch(switchToSignupForm())
  window.setTimeout(() => dispatch(setActive({loginFormActive: true, signupFormActive: false, shippingFormActive: false})), 1)
}
export const actionDoSignup = (formData: any, successCallback?: CallbackFunc | null, failCallback?: CallbackFunc | null): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loginStart())
    const { favorites } = getState().user
    const userDetail = await processSignup(formData, favorites)
    await dispatch(userSetDetail(userDetail))
    dispatch(loginSuccess())
    localStorage.setItem('waJWT', userDetail.jwt as string)
    const paymentState = getState().paymentForm
    if (paymentState.asGuest) {
      dispatch(setGuestJWT(null))
      dispatch(setGuestAddress({} as Address))
    }
    if (successCallback) {
      successCallback!()
    }
  } catch (err) {
    dispatch(loginFailure(err.response.data))
    if (failCallback) {
      failCallback!()
    }
  }
}

export const actionLogout = (): AppThunk => async (dispatch) => {
  try {
    dispatch(logoutStart())
    await processLogout()
    localStorage.removeItem('waJWT')
    dispatch(actionHideUserProfile())
    dispatch(userSetDetail(null))
    dispatch(logoutSuccess())
  } catch (err) {
    dispatch(logoutFailure(err.response.data))
  }
}

export const actionRefreshAuth = (jwt: string): AppThunk => async (dispatch) => {
  try {
    console.log("loading auth")
    dispatch(loginStart())
    const userDetail = await processRefreshLoginInfo(jwt)
    await dispatch(userSetDetail(userDetail))
    dispatch(loginSuccess())
    localStorage.setItem('waJWT', userDetail.jwt as string)
  } catch (err) {
    console.log('login failure')
    localStorage.removeItem('waJWT')
    dispatch(loginFailure(err.response.data))
  }
}
export const actionSwitchToShippingForm = (): AppThunk => async (dispatch) => {
  window.setTimeout(() => dispatch(setActive({loginFormActive: false, signupFormActive: false, shippingFormActive: true})), 1)
}
