import React, { useEffect, MouseEvent } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'app/rootReducer'
import store from 'app/store'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { actionPrefillAddress,
  actionCreateAddress, actionEditAddress,
  actionMakeAddressPrimary, 
  setCurrentEditAddress, 
  primaryAddressSelector, actionDeleteAddress,
  actionUpdateAddress
} from '../user/userSlice'
import {
  actionProceedToLogin,
  actionSetDeliveryAddressId,
  actionProceedToDetail, actionCreateGuestAddress, 
  actionUpdateGuestAddress, actionSetShipping
} from '../paymentForm/paymentFormSlice'

import { observer, observe } from 'redux-observers'
import { Address, verifyEmailAvailable } from 'api/API'

export const AddressEditControls = ({
  register, errors, setValue,
  first_name = '', last_name = '', email = '', line1 = '', line2 = '', phone = '',
  postal_code = '', admin_area_1 = '', admin_area_2 = '', country_code = '', id = null,
  asGuest = false
  }: {
    register: any, errors: any, setValue: any, asGuest?: boolean
  } & Address
) => {
  const dispatch = useDispatch()
  const state = useSelector((s: RootState) => s.user)
  const onPostalCodeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value && value.length === 4) {
      await dispatch(actionPrefillAddress(value))
      if (asGuest) {
        dispatch(actionSetShipping(value))
      }
    }
  }
  useEffect(() => {
    const _observer = observer(
			(state: RootState) => state.user,
			(dispatch, current, previous) => {
				if (previous!.postalCodeInfo !== current!.postalCodeInfo) {
          if (current && current.postalCodeInfo) {
            setValue('state', current.postalCodeInfo.state, {
              shouldValidate: false,
              shouldDirty: true
            })
            if (current.postalCodeInfo.suburbs.length === 1) {
              setValue('suburb', current.postalCodeInfo.suburbs[0], {
                shouldValidate: false,
                shouldDirty: true
              })
            }
          }
					//setValue('state', current!.postalCodeInfo!.state, { shouldDirty: true })
				}
			}
		)
		observe(store, [_observer])
  }, [dispatch, setValue])
  return (
    <>
      <div className="flex-col-wrap">
        <div className="flex-2col">
          <div className="field-name">First Name *</div>
          <div className="field-content input-wrap">
            <input type="text" ref={register({required: 'First name is needed'})}
              name="first_name" defaultValue={first_name}
            />
            <span className="input-error-message">{errors.first_name && errors.first_name.message}</span>
          </div>
        </div>
        <div className="flex-2col">
          <div className="field-name">Last Name *</div>
          <div className="field-content input-wrap">
            <input type="text" ref={register({required: 'Last name is needed'})}
              name="last_name" defaultValue={last_name}
            />
            <span className="input-error-message">{errors.last_name && errors.last_name.message}</span>
          </div>
        </div>
      </div>
      <div className="flex-col-wrap">
        <div className="flex-2col">
          <div className="field-name">Phone Number *</div>
          <div className="field-content input-wrap">
          <input type="text" ref={register({required: 'Phone number is needed'})}
            placeholder="04xxxxxxxx"
            name="phone" defaultValue={phone}
          />
          <span className="input-error-message">{errors.phone && errors.phone.message}</span>
          </div>
        </div>
        <div className="flex-2col">
          <div className="field-name">Room Number</div>
          <div className="field-content input-wrap">
          <input type="text" ref={register()}
            name="line2" defaultValue={line2}
            />
          </div>
        </div>
      </div>

      <div className="field-name">Street Address *</div>
      <div className="field-content input-wrap">
        <input type="text" ref={register({required: 'Address is needed'})}
          name="line1" defaultValue={line1}
        />
        <span className="input-error-message">{errors.line_1 && errors.line_1.message}</span>
      </div>
      <div className="flex-col-wrap">
          <div className="flex-2col">
            <div className="field-name">Postal Code *</div>
            <div className="field-content input-wrap">
              <input type="text" ref={register({required: 'Postal code is needed'})}
                name="postal_code"
                onChange={onPostalCodeChange}
                defaultValue={postal_code}
              />
              <span className="input-error-message">{errors.postal_code && errors.postal_code.message}</span>
            </div>
          </div>

          <div className="flex-2col no-padding">
            <div className="field-name">Suburb</div>
            <div className="field-content field-content-suburb ">

              <select ref={register()}
                name="suburb" defaultValue={admin_area_2}
              >
                {admin_area_2 &&
                  <option
                    key={admin_area_2}
                    value={admin_area_2}>
                    {admin_area_2}
                  </option>
                }
                { state.postalCodeInfo && state.postalCodeInfo.suburbs.map(suburb => (
                  <option key={suburb} value={suburb}>{suburb}</option>
                ))}

              </select>
            </div>
          </div>
        </div>
        <div className="flex-col-wrap">
          <div className="flex-2col">
            <div className="field-name">State</div>
            <div className="field-content">
              <select ref={register()}
                name="state" defaultValue={admin_area_1}
              >
                <option value=""></option>
                <option value="VIC">Victoria</option>
                <option value="NSW">New South Wales</option>
                <option value="QLD">Queensland</option>
                <option value="SA">South Australia</option>
                <option value="TAS">Tasmania</option>
                <option value="WA">Western Australia</option>
                <option value="ACT">Australian Capital Territory</option>
                <option value="NT">Northern Territory</option>
              </select>
            </div>
          </div>
          <div className="flex-2col no-padding">
            <div className="field-name">Country</div>
            <select ref={register()}
              name="country_code" defaultValue={country_code}
              >
              <option value="AU">Australia</option>
            </select>
            <input type="hidden" ref={register()} name="id" defaultValue={id || ''} />
          </div>
        </div>
    </>
  )
}
export const AddressEditForm: React.FC<{
  address: Address | null,
  asGuest?: boolean,
  additionalClass?: string
}> = ({ address, asGuest = false, additionalClass = '' }) => {
  const dispatch = useDispatch()
  const {register, handleSubmit, errors, setValue, reset} = useForm();
	useEffect(() => {
    reset()
	}, [ dispatch, reset ])
  const { guestJWT } = useSelector((state: RootState) => state.paymentForm)
  const primaryAddress = useSelector(primaryAddressSelector)

  const onFormSubmit = async (data: any) => {
    if (!asGuest) {
      if (data.id === '') {
        dispatch(actionCreateAddress(data, !!data.primary))
      } else {
        dispatch(actionUpdateAddress(data))
        if (data.primary) {
          dispatch(actionMakeAddressPrimary(data.id))
        }
      }
    } else {
      if (data.id === '') {
        await dispatch<any>(actionCreateGuestAddress(data))
      } else {
        if (data.email !== address?.email) {
          await dispatch<any>(actionCreateGuestAddress(data))
        } else {
          await dispatch(actionUpdateGuestAddress(data, guestJWT))
        }
      }
    }
  }
  const onCancelEdit = (e: MouseEvent) => {
    reset()
    dispatch(setCurrentEditAddress(null))
  }

  const onLoginClicked = () => {
    dispatch(actionProceedToLogin())
  }

  return (
    address &&
      <form onSubmit={handleSubmit(onFormSubmit)} className={classNames('update-address-form', additionalClass)}>
        <div className={classNames('address-edit-form')}>
					{ asGuest ?
		      <>
            <div className="field-name">Email *</div>
            <div className="field-content input-wrap">
              <input ref={register({
                required: 'Email address is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalid email address'
                },
                validate: {emailExists: async value => await verifyEmailAvailable(value)}
              })} type="text" name="email" placeholder=""
                defaultValue={address?.email}
              />
              <span className="input-error-message">{ errors.email && errors.email.message }</span>
              { errors.email && errors.email.type === 'emailExists' &&
                <span className="login-prompt">This email is already linked to an account. Click here to{' '}
                  <span className="link-text" onClick={onLoginClicked}>Log in</span>
                </span>
              }
            </div>
	        </> : null
					}
          <AddressEditControls
            register={register} errors={errors} setValue={setValue} { ...address }
            asGuest={asGuest}
          />
        </div>
        { !asGuest ?
          <div className="check-primary">
            { address?.id === primaryAddress?.id ?
              <span className="text-label green-text-label">Primary</span>:
              <>
                <input type="checkbox" name="primary" value="primary" id={'make_primary_' + address.id}
                  defaultChecked={address?.id === primaryAddress?.id}
                  disabled={address?.id === primaryAddress?.id}
                  ref={register()}
                 />
                <label className="noselect" htmlFor={'make_primary_' + address.id} >Set as Primary</label>
              </>
            }
          </div> : null
        }
        <div className="action-button-wrap">
          <button onClick={onCancelEdit} className="action-button action-button-cancel action-button-small">Cancel</button>
          <button className="action-button action-button-save action-button-small">Save</button>
        </div>
      </form>
  )
}

export const AddressItem = ({ id, first_name, last_name, phone, line1, line2,
  admin_area_1, admin_area_2, postal_code, country_code, email,
  allowEdit = false, isPrimary = false,
  allowSelect = false, allowDelete = false, showEmail = false}
  : Address & {allowEdit?: boolean, isPrimary?: boolean, allowSelect?: boolean,
  allowDelete?: boolean, showEmail?: boolean}) => {
  const dispatch = useDispatch()
  const paymentState = useSelector(
    (s: RootState) => s.paymentForm
  )
  const onEdit = (e: MouseEvent) => {
    dispatch(actionEditAddress(id as number))
  }
  const onSelectAddress = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await dispatch(actionSetDeliveryAddressId(id as number))
    window.setTimeout(() => {
      dispatch(actionProceedToDetail())
    }, 500)
  }
  const onClickAddressLabel = async (e: MouseEvent) => {
    const target: HTMLInputElement | null = document.querySelector(`#radio_primary_${id}`)
    if (target?.checked) {
      await dispatch(actionSetDeliveryAddressId(id as number))
      window.setTimeout(() => {
        dispatch(actionProceedToDetail())
      }, 1)
      
    }

  }
  const onDelete = (e: MouseEvent) => {
    dispatch(actionDeleteAddress(id as number))
  }
  return (
    <div className="address-item-wrap">
      <div className="address-item">
        { allowSelect &&
          <div className="select-address">
            <label htmlFor={`radio_primary_${id}`} onClick={onClickAddressLabel}>
              <div className={classNames({'select-address-tick': id === paymentState.deliveryAddressId, 'select-address-notick': id !== paymentState.deliveryAddressId})}></div>
            </label>
            <input id={`radio_primary_${id}`} type="radio"
              className="address-radio-hidden"
              checked={id === paymentState.deliveryAddressId} 
              onChange={onSelectAddress} 
              name="select-address" />
          </div>
        }
        <div className="address-detail">
          {first_name} {last_name} {phone}<br/>
          {showEmail ? <>{email}<br/></> : null}
          {line1}{line2 && ', ' + line2},<br/> {admin_area_2} {admin_area_1}, {postal_code} {country_code}
        </div>
        {allowEdit ? (
          <div className="button-wrap">
              <button onClick={onEdit} className="function-button function-button-small address-edit-button">Edit</button>
          </div>
        ) : null}
        {allowDelete ? (
          <div className="button-wrap">
              <button onClick={onDelete} className="function-button function-button-small address-delete-button">X</button>
          </div>
        ) : null}

      </div>
    </div>
  )

}
