import React from 'react'
import { useDispatch } from 'react-redux'
import { Product, PRODUCT_IMAGE_HOST } from 'api/API'
import { actionShowOrderMoreDetail } from './orderSlice'
import { Link, useHistory } from 'react-router-dom'

type IProps = {
  product: Product
  order: any
}

const OrderDetailForm = () => {
  return <div className="order-detail">

    </div>
}
export default ({ product, order }: IProps ) => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' })
  const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(new Date(order.create_time))
  const dispatch = useDispatch()
  const history = useHistory()
  const onProductClicked = () => {
    history.push(`/product/${product.uuid}`)
  }
	return (
      <div className="order-item">
          <div className="product-link" onClick={onProductClicked}>
            <div className="product-image">
              <img src={`${PRODUCT_IMAGE_HOST}/product_images/${product.primary_image_uuid}`} className="product-image col-2" alt={product.name} />
            </div>
            <div className="product-detail">
              <div className="product-title">
                {product.name}
              </div>
              <div className="product-description">
                {product.description.substring(0, 30)}
              </div>

            </div>
            <div className="product-price">
              <div>${order.item_price.toFixed(2)} X {order.quantity}</div>
              <div className="product-shipping-fee">${order.shipping_fee.toFixed(2)}</div>
            </div>
          </div>
          <div className="order-status">
            <div>Your order is being prepared</div>
          </div>
          <div className="total-price">
            <div>${order.total_amount.toFixed(2)}</div>
          </div>
          <div className="order-detail-expand">
            <div className="order-time">
              {day}/{month}/{year}
            </div>
            <div className="delivered-to">
              {order.delivered_to}
            </div>
          </div>
          <span className="show-more-detail" onClick={() => {
            dispatch(actionShowOrderMoreDetail(order.id))
            return null
          }}>More...</span>
      </div>
	)
}
