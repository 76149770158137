import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/rootReducer'
import { AppThunk } from 'app/store'
import { createSelector } from 'reselect'

import { 
  processGetDeals, Deal, getRunningDeals, RunningDeal
} from 'api/API'


type DealState = {
  isLoading: boolean
  runningDeals: RunningDeal[]
  dealError: string | null
  showHint: boolean
}
let initialState: DealState = {
  isLoading: false,
  runningDeals: [],
  dealError: null,
  showHint: false,
}

function startLoading(state: DealState) {
  state.isLoading = true
}

function loadingFailed(state: DealState, action: PayloadAction<string>) {
  state.isLoading = false
  state.dealError = action.payload
}
const dealSlice = createSlice({
  name: 'deal',
  initialState,
  reducers: {
    setRunningDeals: (state, action: PayloadAction<RunningDeal[]>) => {
      state.runningDeals = action.payload
    },
    actionShowHint: (state, action: PayloadAction<null>) => {
      state.showHint = true
    },
    actionHideHint: (state, action: PayloadAction<null>) => {
      state.showHint = false
    }
  }
})

export const {
  setRunningDeals,
  actionShowHint,
  actionHideHint
} = dealSlice.actions;

export default dealSlice.reducer;

export const actionGetRunningDeals = (productId?: number): AppThunk =>
  async (dispatch, getState) => {
  let result = await getRunningDeals(productId)
  const state = getState().deal
  if (productId !== undefined) {
    let oldResults = state.runningDeals.filter((rd: RunningDeal) => rd.product_id !== productId)
    await dispatch(setRunningDeals(oldResults.concat(result)))
  } else {
    await dispatch(setRunningDeals(result))
  }
}

export const runningDealSelector: (state: RootState) => any = createSelector(
  (state: RootState) => state.productList.dealEntities,
  (state: RootState) => state.deal.runningDeals,
  (state: RootState) => state.user.userDetail,
  (deals, runningDeals, userDetail) => {
    let running = []
    for (let r of runningDeals) {
      let _r = {
        deal: deals[r.deal_id],
        ...r
      }
      if (userDetail !== null) {
        if (_r.start_user_id !== userDetail.id) {
          running.push(_r)
        }
      } else {
        running.push(_r)
      }
    }
    return running
  }
)

export const runningDealByProductSelector: (state: RootState, productId: number) => any = createSelector(
  (state: RootState) => state.productList.productEntities,
  (state: RootState) => state.productList.dealEntities,
  (state: RootState) => state.deal.runningDeals,
  (state: RootState, productId: number) => productId,
  (products, deals, runningDeals, productId) => {
    const product = products[productId]
    let running = []
    for (let r of runningDeals) {
      if (r.product_id === productId) {
        let _r = {
          deal: deals[r.deal_id],
          ...r
        }
        running.push(_r)
      }
    }
    return running
  }
)


