import React, { useEffect } from 'react'
import { PRODUCT_IMAGE_HOST } from 'api/API'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'app/rootReducer'
import classNames from 'classnames'
import { actionFetchOrders, actionShowOrderMoreDetail } from './orderSlice'
import { actionFetchProducts, productsSelector } from '../productList/productListSlice'
import OrderItem from './OrderItem'
import { createSelector } from 'reselect'
import Popup from 'reactjs-popup'
import { Link } from 'react-router-dom'

const detailOrderSelector = createSelector(
  (state: RootState) => state.order.orders,
  (state: RootState) => state.order.orderMoreDetailId,
  (orders, orderId) => {
    if (orders === null || orderId === null) {
      return null
    }
    for (let order of orders) {
      if (order.id === orderId) {
        return order
      }
    }
    return null
  }
)
export default () => {
  const dispatch = useDispatch()
  const { orders, orderMoreDetailId } = useSelector(
    (s: RootState) => s.order
  )
  const { productEntities } = useSelector(
    (s: RootState) => s.productList
  )
  const products = useSelector(productsSelector)
  const detail = useSelector(
    (s: RootState) => detailOrderSelector(s)
  )
  const detailProductSelector = createSelector(
    (state: RootState) => state.productList.productEntities,
    (state: RootState, productId: number | null | undefined) => productId,
    (products, productId) => {
      if (productId) {
        return products[productId]
      }
    }
  )
  const product = useSelector(
    (s: RootState) => detailProductSelector(s, detail?.product_id)
  )
  useEffect(() => {
    if (products.length === 0) {
      dispatch(actionFetchProducts('all'))
    }
    dispatch(actionFetchOrders())
  }, [dispatch, products.length])
  /*
              <ul>
                <li>
                  <p>Your order has been successfully verified and under prepaing now.</p>
                  <p className="order-status-time">2020-08-07 14:26:41</p>
                </li>
                <li>
                  <p>Thank you for shopping with wowToy! Your order has been received and is going through vertification process.</p>
                  <p className="order-status-time">2020-08-07 06:23:21</p>
                </li>
              </ul>
   */
  return (
    <div className={classNames('orders')}>
    { (detail && product) &&
      <Popup open={orderMoreDetailId !== null} closeOnDocumentClick closeOnEscape
      contentStyle={{width: '90%'}}
        onClose={() => { dispatch(actionShowOrderMoreDetail(null)) } }
      >
      <>
        <div className="popup-header">
          <h4>Order ID: #{detail.id}</h4>
          <span className="close"></span>
        </div>
          <div className="order-item">
            <div className="product-image">
              <img className="product-image col-2"
                src={`${PRODUCT_IMAGE_HOST}/product_thumbnail/${product.primary_image_uuid}`}
                alt="image" />
            </div>
            <div className="product-detail">
              <div className="product-title">
                {product.name}
              </div>
              <div className="product-description">
                {product.description}
              </div>
            </div>
            <div className="product-price">
              <div>${detail.item_price.toFixed(2)} X {detail.quantity}</div>
              <div className="product-shipping-fee">${detail.shipping_fee.toFixed(2)}</div>
              <div className="total-price">
                <div>${detail.item_price.toFixed(2)}</div>
              </div>
            </div>
            <div className="order-status-detail-update">
              <div className={classNames('status-detail', {'status-doing': detail.phase === 'Received', 'status-complete': ['Preparing', 'Shipping', 'Delivered'].includes(detail.phase)})}>Received</div>
              <div className="status-detail-line "><div></div></div>
              <div className={classNames('status-detail', {'status-doing': detail.phase === 'Preparing', 'status-complete': ['Shipping', 'Delivered'].includes(detail.phase)})}>Preparing</div>
              <div className="status-detail-line "><div></div></div>
              <div className={classNames('status-detail', {'status-doing': detail.phase === 'Shipping', 'status-complete': ['Delivered'].includes(detail.phase)})}>Shipping</div>
              <div className="status-detail-line "><div></div></div>
              <div className={classNames('status-detail', {'status-doing': detail.phase === 'Delivered'})}>Delivered</div>
            </div>
            <div className="order-status-text-update">
            </div>
          </div>
        <div className="order-invoice-wrap">
          <div className="order-invoice-row">Order ID:</div> <div className="order-invoice-row">{detail.id}</div>
          <div className="order-invoice-row">Ship to:</div><div className="order-invoice-row">{detail.shipping_to}, {detail.delivered_to}</div>
          <div className="order-invoice-row">Paid with: </div><div className="order-invoice-row">{detail.payment_method}</div>
          <div className="order-invoice-row">Subtotal: </div><div className="order-invoice-row">${detail.item_price.toFixed(2)}</div>
          <div className="order-invoice-row">Shipping: </div><div className="order-invoice-row">${detail.shipping_fee.toFixed(2)}</div>
          <div className="order-invoice-row">Order Total: </div><div className="order-invoice-row">${(detail.item_price + detail.shipping_fee).toFixed(2)}</div>
        </div>
      </>
      </Popup>
    }
      { orders &&
        <form className="form-order-list" >
          <section className="order-list">
            {orders.map(orderDetail => {
              let product = productEntities[orderDetail.product_id]
              if (product) {
                return <OrderItem key={orderDetail.id} order={orderDetail} product={product} />
              } else {
                return null
              }
            })}
          </section>
        </form>
      }
    </div>
  )
}
