import React, { MouseEvent, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Stages, dealsSelector, actionApplyDeal, calculateDiscount,
  setJoiningRunning, actionCreateOrder, actionShowPaymentMethods,
  actionProceedToDetail } from './paymentFormSlice'
import { runningDealSelector } from 'features/deal/dealSlice'
import { Product, RunningDeal, API_HOST } from 'api/API'
import { RootState } from 'app/rootReducer'
import classNames from 'classnames'
import moment from 'moment'
import userIcon from 'images/unknown-user-icon.svg'

const DealItem = ({ id, name, product, discount_amount, expiry, participants, 
  deal, start_user_id, start_user_name }: any) => {
  const dispatch = useDispatch()
  const { activeDealId, quantity, deliveryAddressId, stage } = useSelector((state: RootState) => state.paymentForm)
  const { userDetail } = useSelector((state: RootState) => state.user)
  const onJoinDeal = async () => {
    await dispatch(setJoiningRunning(id))
    await dispatch(actionCreateOrder([product!], quantity, deliveryAddressId!))
    if (deliveryAddressId !== null) {
      await dispatch(actionShowPaymentMethods())
    }
    dispatch(actionProceedToDetail())
  }
  const [now, setNow] = useState(new Date())
  let intervalHandle: number
  useEffect(() => {
    intervalHandle = window.setInterval(() => {
      setNow(new Date())
    }, 1000)
    return () => {
      window.clearInterval(intervalHandle)
    }
  }, [dispatch])

  const diff = moment.duration(moment(expiry).diff(now))
  const diffStr = diff.format('d [day] hh:mm:ss', {stopTrim: 'h', forceLength: true })
  //const diffStr = moment.duration.format([diff], 'D HH:mm:ss')
  if (moment(expiry) < moment(now)) {
    return null
  }
  if (start_user_id === userDetail?.id) {
    start_user_name = 'You'
  }

  /*
          <div className="wowdeal-time-left">
            Time left: {diffStr}
          </div>
          */
  return (
    <div className="deal-item-wrap">
      <div className="deal-item flex-grid">
          <div>
            { stage === Stages.SETUP ?
              <>
                <div className="participant-list">
                  { participants.map((p: number) => (
                    <img key={p} src={API_HOST + '/api/user/avatar/' + p} className="join-deal-circle" />

                  ))}
                  <img src={userIcon} className="join-deal-circle" />
                </div>
                {start_user_name} {start_user_id === userDetail?.id ? 'are' : 'is'} waiting someone to join. <span className="join-fraction">{participants.length} / {deal.min_attending}</span>
              </> :
              <>
                <div className="participant-list">
                  { participants.map((p: number) => (
                    <img key={p} src={API_HOST + '/api/user/avatar/' + p} className="join-deal-circle" />

                  ))}
                  <img src={API_HOST + '/api/user/avatar/' + userDetail?.id} className="join-deal-circle" />
                </div>
                Joining {start_user_name}. <span className="join-fraction">{participants.length + 1} / {deal.min_attending}</span>
              </>
            }

          </div>
          { stage === Stages.SETUP &&
            <div className="wowdeal-button-wrap">
              <button className={classNames('wowdeal-join-button', {disabled: start_user_id === userDetail?.id})} onClick={onJoinDeal}>
                Join
              </button>
            </div>
          }
        </div>
      </div>

  )
}

export default ({ product, runningDeals }: {product: Product, runningDeals: RunningDeal[]}) => {
  //const [postcode, setPostcode] = useState('')
  const { stage, joiningRunning } = useSelector((state: RootState) => state.paymentForm)
  const deals = useSelector((state: RootState) => dealsSelector(state, product!.id))
  let running: RunningDeal | undefined
  let runningTag: JSX.Element | null
  let allRunning = useSelector(runningDealSelector)
  if (joiningRunning) {
    running = allRunning.find((d: any) => d.id === joiningRunning)
    if (running) {
      runningTag = <DealItem key={running!.id} product={product}
              discount_amount={calculateDiscount(running.deal!, product.price)} {...running!} />
    } else {
      runningTag = null
    }
  } else {
    runningTag = null
  }
  return (
      <div className="payment-row">
        { stage === Stages.SETUP ? 
          (runningDeals.length > 0 ? (runningDeals.map(d => <DealItem key={d.id} product={product}
            discount_amount={calculateDiscount(d.deal!, product.price)} {...d} />)):
            <div className="no-deal-running">No running deal available. Start your own one to enjoy great savings.</div>
          ):
          runningTag
        }
      </div>
  )
}
