import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import forgotPassword from 'images/forgot-password.png'
import { actionHideLoginForm, actionShowLoginForm, actionSwitchToLoginForm, actionSwitchToSignupForm } from '../loginForm/loginFormSlice'
import { actionResetPassword, actionSendResetMail } from '../user/userSlice'
import checkMark from 'images/checkmark.svg'
import { useHistory, useParams } from 'react-router-dom'

export const EmailVerified = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const onSigninClicked = () => {
    dispatch(actionShowLoginForm())
    dispatch(actionSwitchToLoginForm())
    history.push('/')
  }
  return (
    <div className="account-communication">
    <div className="activate-account-confirmation">
      <div className="account-message-wrap">
        <div className="check-mark"><img src={checkMark} alt="" width="80px"/></div>
        <div className="account-message">
          <h3>Congratulations</h3>
          <p>You have successfully activate your account</p>
        </div>
      </div>
      <button className="action-button" onClick={onSigninClicked} >Login to your account</button>
    </div>
    </div>
  )
}


export const ForgetPassword = () => {
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm()
	const [busy, setBusy] = useState(false)
  const history = useHistory()
  useEffect(() => {
    dispatch(actionHideLoginForm())
    dispatch(actionSwitchToLoginForm())
  })
  const onSigninClicked = () => {
    dispatch(actionShowLoginForm())
    dispatch(actionSwitchToLoginForm())
  }
  const onSignupClicked = () => {
    dispatch(actionShowLoginForm())
    dispatch(actionSwitchToSignupForm())
  }
  const onInitiateReset = async (data: any) => {
    setBusy(true)
    await dispatch(actionSendResetMail(data.email))
		const location = {
			pathname: '/user/forget_password/submitted',
		}
    setBusy(false)
		history.push(location)

  }
  return (
    <div className="account-communication">
      <form onSubmit={handleSubmit(onInitiateReset)}>
        <div className="request-reset-password">
          <div className="account-message-wrap">
            <div><img src={forgotPassword} alt="forgot password"/></div>
            <div className="account-message">
              <h4>Forget password?</h4>
              <p>Please input your email address to reset your password</p>
            </div>
          </div>
          <input type="text" name="email" ref={register({required: 'Please enter your email to reset password'})} placeholder="Email" />
          <button className={classNames('action-button', {busy: busy})}>Reset Password</button>
          <p className="additional-content"><button className="link-text" onClick={onSigninClicked}>Sign In</button> again or <button className="link-text" onClick={onSignupClicked}>Create New Account</button></p>
        </div>
      </form>
    </div>
  )
}
export const ForgetPasswordSubmitted = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actionHideLoginForm())
    dispatch(actionSwitchToLoginForm())
  })
  return (
    <div className="account-communication">
      <div className="password-changed">
      <div className="account-message-wrap">
        <div className="check-mark"><img src={checkMark} alt="" width="80px"/></div>
        <div className="account-message">
          <h3>Your password reset request is received.</h3>
          <p>If you have an account with us, an email with instructions will be sent to you shortly.</p>
        </div>
      </div>
      </div>
    </div>
  )
}
export const ResetPassword = () => {
  const { register, handleSubmit, errors, getValues } = useForm()
  const dispatch = useDispatch()
	const history = useHistory()
  const { email, pinCode } = useParams();
  const onSigninClicked = () => {
    dispatch(actionShowLoginForm())
    dispatch(actionSwitchToLoginForm())
  }
  const onSignupClicked = () => {
    dispatch(actionShowLoginForm())
    dispatch(actionSwitchToSignupForm())
  }
  const onReset = async (data: any) => {
    await dispatch(actionResetPassword(data.email, data.pinCode, data.password))

		const location = {
			pathname: '/user/reset_password/success',
			state: { fromResetPassword: true }
		}
		history.push(location)
  }
  return (
    <div className="account-communication">
      <div className="reset-password">
        <div className="account-message-wrap">
          <div className="account-message">
            <h4>Reset Password</h4>
            <p>You have requested to reset your password, please fill in your new password.</p>
          </div>
        </div>
        <form onSubmit={handleSubmit(onReset)}>
          <input type="password" ref={register({required: 'Please enter new password'})} name="password" placeholder="New Password" />
          { errors.password && errors.password.message }
          <input type="password" ref={
            register({
              required: 'Please confirm your new password',
              validate: {passwordsMustBeSame: value => value === getValues('password')}
            })} name="confirm_password" placeholder="Confirm new password" />
          { errors.confirm_password && errors.confirm_password.message }
          { errors.confirm_password && errors.confirm_password.type === 'passwordsMustBeSame' &&
              <span className="login-error-msg">The two passwords entered do not match.</span>
          }
          <input type="hidden" ref={register()} defaultValue={email} name="email" />
          <input type="hidden" ref={register()} defaultValue={pinCode} name="pinCode" />
          <button className="action-button">Reset Password</button>
        </form>
        <p className="additional-content"><button className="link-text" onClick={onSigninClicked}>Sign In</button> again or <button className="link-text" onClick={onSignupClicked}>Create New Account</button></p>
      </div>
    </div>
  )
}

export const ResetPasswordSuccess = () => {
  const dispatch = useDispatch()
  const onSigninClicked = () => {
    dispatch(actionShowLoginForm())
    dispatch(actionSwitchToLoginForm())
  }
  return (
    <div className="account-communication">
    <div className="password-changed">
      <div className="account-message-wrap">
        <div className="check-mark"><img src={checkMark} alt="" width="80px"/></div>
        <div className="account-message">
          <h3>Password Changed</h3>
          <p>You have successfully reset your password.</p>
        </div>
      </div>
      <button className="action-button" onClick={onSigninClicked}>Login to your account</button>
    </div>
    </div>
  )
}
