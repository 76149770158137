import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { processGetProducts, getProductDetail, Product, getProductsV2 } from 'api/API'
import { RootState } from 'app/rootReducer'
import { createSelector } from 'reselect'
import { actionSetupPayment, resetForm } from '../paymentForm/paymentFormSlice'
import { AppThunk } from 'app/store'

type ProductListState = {
  page: number,
  productId: number | null,
  products: number[],
  productEntities: Product[],
  dealEntities: any,
  productError: string | null,
  isLoading: boolean,
  isDetailLoading: boolean,
  amount: number,
  filters: TFilter[] | null,
  productDetail: Product | null,
  productDetailImage?: number | null,
  building: boolean,
  popupJoinDeal: number | null,
}
let initialState: ProductListState = {
  page: 1,
  productId: null,
  products: [],
  productEntities: [],
  dealEntities: [],
  isLoading: true,
  isDetailLoading: true,
	productError: null,
  amount: 0,
  filters: [],
  productDetail: null,
  productDetailImage: null,
  building: false,
  popupJoinDeal: null,
}

export enum FilterType {
  FAVORITE, CATEGORY, NAME, PRICE
}
enum MatchType {
  EXACT, GREATER, GREATER_OR_EQUAL,
  LESS, LESS_OR_EQUAL, RANGE
}
export type TFilter = {
  type: FilterType,
  match?: MatchType,
  payload?: string | number | number[] | null
}

function startLoading(state: ProductListState) {
  state.isLoading = true
}

function loadingFailed(state: ProductListState, action: PayloadAction<string>) {
  state.isLoading = false
  state.productError = action.payload
}
const productListSlice = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    getProductsStart: startLoading,
    getProductsSuccess(state, action: PayloadAction<any>) {
      const payload = action.payload
      state.products = action.payload.result.products
      state.productEntities = action.payload.entities.products
      state.dealEntities = action.payload.entities.deals
      state.isLoading = false
    },
		getProductsFailure: loadingFailed,

    getProductDetailStart(state) {
      state.isDetailLoading = true
    },
    getProductDetailSuccess(state, action: PayloadAction<Product>) {
      state.productDetail = action.payload
      state.productDetailImage = state.productDetail.primaryImage
      state.isDetailLoading = false
    },
    getProductDetailFailure(state, action: PayloadAction<string>) {
      state.isDetailLoading = false
      state.productError = action.payload
    },
    selectProduct(state, action: PayloadAction<Product>) {
      state.productId = action.payload.id
    },
    clearProductSelection(state) {
      state.productId = null
    },
    addFilter(state: ProductListState, action: PayloadAction<TFilter>) {
      state.filters!.push(action.payload)
    },
    removeFilter(state: ProductListState, action: PayloadAction<TFilter>) {
      const pos = state.filters!.findIndex(f => f.type === action.payload.type)
      if (pos >= 0) {
        state.filters!.splice(pos, 1)
      }
      /*
      const pos = state.filters!.indexOf(action.payload)
      */
    },
    appendProduct(state: ProductListState, action: PayloadAction<Product>) {
      //let product = state.products!.find(p => p.id === action.payload.id)
      let product = state.productEntities[action.payload.id]
      //let product = {}
      //state.productEntities[action.payload.id] = product

      if (product) {
        Object.assign(product, action.payload)
      } else {
        state.products.push(action.payload.id)
      }
    },
    setProductDetail(state: ProductListState, action: PayloadAction<Product | null>) {
      state.productDetail = action.payload
      if (action.payload !== null) {
        state.productDetailImage = action.payload.primaryImage
      }
    },
    setProductDetailImage(state, action: PayloadAction<number | null>) {
      state.productDetailImage = action.payload
    },
    ignoreBuilding(state) {
      state.building = false
    },
    setPopupJoinDeal(state, action: PayloadAction<number|null>) {
      state.popupJoinDeal = action.payload
    }
    /*
    setCurrentDisplayType(state, action: PayloadAction<CurrentDisplayPayload>) {
      const { displayType, productId = null } = action.payload
      state.displayType = displayType
      state.productId = productId
    }
     */
    /*
    addProduct: {
      reducer(state, action) {
        state.push(action.payload);
      },
      prepare(product) {
        return { payload: {...product, _serial: nextSerial++ }}
      }
    },
    */
  }
})

export const {
  setCurrentPage,
  getProductsStart,	getProductsSuccess,	getProductsFailure,
  selectProduct, appendProduct,
  clearProductSelection,
  addFilter, removeFilter,
  setProductDetail, setProductDetailImage,
  getProductDetailStart, getProductDetailSuccess, getProductDetailFailure,
  ignoreBuilding, setPopupJoinDeal
} = productListSlice.actions;

export default productListSlice.reducer;

export const actionFetchProducts = (category: string): AppThunk => async (dispatch, getState) => {
  try {
    const productList = getState().productList
    if (productList.productEntities.length == 0) {
      console.log('entities', 0)
      dispatch(getProductsStart())
    }
      /*
    const products = await processGetProducts(category)

       */
    const response = await getProductsV2()
    dispatch(getProductsSuccess(response))
  } catch (err) {
    dispatch(getProductsFailure(err.toString()))
  }
}

export const actionFetchProductDetail = (productUUID: string): AppThunk => async dispatch => {
  try {
    dispatch(getProductDetailStart())
    const product = await getProductDetail(productUUID)
    dispatch(getProductDetailSuccess(product))
  } catch (err) {
    dispatch(getProductDetailFailure(err.toString()))
  }
}


export const actionBuyProduct = (product: Product, joinDeal: number | null = null): AppThunk => async (dispatch, getState) => {
  const state = getState().productList
  if (state.products.length === 0) {
    await dispatch(actionFetchProducts('all'))
  }
  await dispatch(selectProduct(product))
  window.setTimeout(() => {
    // set class .active slightly after the component mounts so that the animation get a chance to run
    dispatch(actionSetupPayment(product, joinDeal))
  }, 1)
}

export const actionCancelPayment = (): AppThunk => async dispatch => {
  dispatch(resetForm())
  window.setTimeout(() => {
    dispatch(clearProductSelection())
  }, 100)
}

export const actionAddFilterFavorite = (): AppThunk => async dispatch => {
  dispatch(addFilter({type: FilterType.FAVORITE}))
}
export const actionRemoveFilterFavorite = (): AppThunk => async dispatch => {
  dispatch(removeFilter({type: FilterType.FAVORITE}))
}
export const actionToggleFilterFavorite = (): AppThunk => async (dispatch, getState) => {
  const productState = getState().productList
  const pos = productState.filters!.findIndex(f => f.type === FilterType.FAVORITE)
  if (pos >= 0) {
    dispatch(removeFilter({type: FilterType.FAVORITE}))
  } else {
    dispatch(addFilter({type: FilterType.FAVORITE}))
  }
}

export const actionAddProductToList = (product: Product): AppThunk => async (dispatch, getState) => {
  dispatch(appendProduct(product))
}

export const actionShowImage = (imageId: number): AppThunk => async (dispatch) => {
  dispatch(setProductDetailImage(imageId))
}

export const selectedProductSelector: (state: RootState) => Product | null = createSelector(
  (state: RootState) => state.productList.productEntities,
  (state: RootState) => state.productList.productId,
  (products, productId) => productId ? products[productId] : null
)

export const productsSelector: (state: RootState) => Product[] = createSelector(
  (state: RootState) => state.productList.products,
  (state: RootState) => state.productList.productEntities,
  (products, entities) => products.map(productId => entities[productId])
)

