import React, {useEffect, MouseEvent, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ProductImage, PRODUCT_IMAGE_HOST } from 'api/API'
import { RootState } from 'app/rootReducer'
import classNames from 'classnames'
import { useForm, Controller } from 'react-hook-form'
import { actionAddOrUpdateProduct, actionAddImage, actionMakePrimary, actionRemoveImage } from './adminProductSlice'
import 'react-quill/dist/quill.snow.css'
import ReactQuill, {Quill} from 'react-quill';

const Textarea: React.FC = () => {
  return <div>
    <textarea>

    </textarea>
    </div>
}
const NewProductPage = (props: { admin?: boolean })  => {
  const {register, handleSubmit, errors, reset, setValue, control} = useForm({
  })
  const dispatch = useDispatch()
  const state = useSelector(
    (s: RootState) => s.adminProduct
  )
  const imagePreview = useRef(null)
  const onAddProduct = (data: any, e: any) => {
    /*
    const resetForm = () => {
      e.target!.reset()
    }
    */
    dispatch(actionAddOrUpdateProduct(data))
  }
	const readURL = (input: any) => {
		if (input.files && input.files[0]) {
			let reader = new FileReader();

			reader.onload = function (e) {
				const ref: HTMLImageElement | null = imagePreview.current
				if (e !== null) {
          if (e.target !== null) {
            if (e.target.result !== null) {
              ref!.src = e!.target!.result as string
            }
          }
				}
			}

			reader.readAsDataURL(input.files[0]);
		}
	}

	const processUpload = (input: any) => {
		if (input.files && input.files[0]) {
			let reader = new FileReader();

			reader.onload = function (e) {
				let img1 = e!.target!.result;
				const ref: HTMLImageElement | null = imagePreview.current
				if (e !== null) {
          if (e.target !== null) {
            if (e.target.result !== null) {
              ref!.src = e!.target!.result as string
            }
          }
				}
				/*
				img1 = document.createElement('img');
				img1.src = e.target.result;
				setTimeout(function() {
					EXIF.getData(img1, function() {
						console.log('working with exif', this);
						console.log(EXIF.getTag(this));
						var make = EXIF.getTag(this, 'Make');
						var model = EXIF.getTag(this, 'Model');
						var orientation= EXIF.getTag(this, 'Orientation');
						alert(orientation);
						app.exif = this;
					});
				}, 1000);
				img1 = document.getElementById('test_element');
				img1.src = e.target.result;
				croppie.bind({
					url: e.target.result,
					orientation: 6
				});
				fetch(
					'/student/photo/upload', {
						method: 'PUT',
						body: JSON.stringify({
							student_id: 17,
							photo: e.target.result
						})
					}
				)
					.then((response) => {
						return response.json();
					})
					.then((myJson) => {
						console.log(myJson);
					});
				*/
			}

			reader.readAsDataURL(input.files[0]);
		}
  }
	const onUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target
    if (input.files) {
      for (let f of input.files) {
        let reader = new FileReader()

        reader.onload = e => {
          let img1 = e!.target!.result
          dispatch(actionAddImage(f.name, img1 as string, f.type, f.size, f.lastModified))
        }
        reader.readAsDataURL(f)
      }
    }
	}

  const ImageControl = (props: ProductImage & {index: number}) => {
    const onMakePrimary = (e: MouseEvent) => {
      dispatch(actionMakePrimary(props.index))
    }
    const onRemoveImageClicked = (e: MouseEvent) => {
      dispatch(actionRemoveImage(props.index))

    }
    return (
      <div className="uploaded-image-wrap" >
        <img alt="upload image preview" className={classNames('product-preview', {primary: props.isPrimary, sync: props.isInSync})} src={props.dataUrl || `${PRODUCT_IMAGE_HOST}/product_images/${props.uuid}`} onClick={onMakePrimary} />
        <div className="close-btn" onClick={onRemoveImageClicked}><i>X</i></div>
      </div>
    )
  }
  const theme = 'snow'
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
    ],
  }
  const formats = ['bold', 'italic', 'underline', 'strike']

  useEffect(() => {
    console.log('useeffect called')
    reset()
  }, [state.productDetail])
  const onSaveAsDraft = () => {
    setValue('draft', 1)
  }
  const onSaveAndPublish = () => {
    setValue('draft', 0)

  }
  const onOffShelf = () => {
    setValue('off_shelf', 1)
  }
  /*
            render={(
              { onChange, onBlur, value, name, ref },
              { invalid, isTouched, isDirty }
            ) => (
              <Textarea ref={quillRef}
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.innerHTML)}
                inputRef={ref}
              >
              </Textarea>
            )}
	*/
  return (
    <div className={classNames('new-product', {active: props.admin })}>
      <form className="form-new-product" onSubmit={handleSubmit(onAddProduct)}>
        <section className="image-upload-grid">
          {
            state.images.map((im, index) => (
              <ImageControl key={index} index={index} {...im} />
            ))
          }
        </section>
        <div className="product-image-upload">
          <label htmlFor="uploadImage">Upload Images</label>
          <input id="uploadImage" type="file" name="upload_image" ref={register()}
            onChange={onUploadChange}
            multiple
          />
        </div>
        <div className="field-name">
          Product Name*
        </div>
        <div className="field-input">
          <input type="text" defaultValue={state.productDetail ? state.productDetail.name : ''} ref={register({
            required: 'Product name is required.',
          })} name="name" placeholder="" />
          {errors.name && <span className="form-error-msg">{errors.name.message}</span>}
        </div>


        <div className="field-name">
          Brand*
        </div>
        <div className="field-input">
          <input type="text" defaultValue={state.productDetail ? state.productDetail.brand : ''}
            ref={register({

          })} name="brand" placeholder="" />
          {errors.brand && <span className="form-error-msg">{errors.brand.message}</span>}
        </div>


        <div className="field-name">
          Price*
        </div>
        <div className="field-input">
          <input type="text" defaultValue={state.productDetail ? state.productDetail.price : ''}
            ref={register({
            required: 'Price is required.',
          })} name="price" placeholder="500000000" />
          {errors.price && <span className="form-error-msg">{errors.price.message}</span>}
        </div>


        <div className="field-name">
          Description*
        </div>
        <div className="field-input" >
					<Controller
						name="description"
            control={control}
            defaultValue={state.productDetail ? state.productDetail.description : ''}
            render={({ value, onChange }: any) => (
              <ReactQuill value={value} onChange={onChange} />
            )}
					/>
          {errors.description && <span className="form-error-msg">{errors.description.message}</span>}
        </div>

        <div className="field-name">
          Tags
        </div>
        <div className="field-input">
          <input type="text" defaultValue={state.productDetail ? state.productDetail.meta.tags : ''}
            ref={register()} name="tags" placeholder="" />
          {errors.tags && errors.tags.message}
        </div>


        <div className="field-name">
          Product Dimensions
        </div>
        <div className="field-input">
          <input type="text" defaultValue={state.productDetail ? state.productDetail.dimensions : ''}
            ref={register({
          })} name="dimensions" placeholder="" />
          {errors.dimensions && errors.dimensions.message}
        </div>

        <div className="field-name">
          Weight
        </div>
        <div className="field-input">
          <input type="text" defaultValue={state.productDetail ? state.productDetail.weight : ''}
            ref={register()} name="weight" placeholder="" />
          {errors.weight && errors.weight.message}
        </div>
        <input type="hidden" ref={register()} name="draft" defaultValue={1} />
        <input type="hidden" ref={register()} name="off_shelf" defaultValue={0} />
        {
          state.productDetail ?
          <>
            <button className="action-button submit">Update</button>
            <button className="action-button submit">Publish</button>
            <button className="action-button submit" onClick={onOffShelf}>Off Shelf</button>
            <button className="action-button submit">Archive</button>
          </>
          :
          <>
            <button className="action-button submit" onClick={onSaveAsDraft}>Save as Draft</button>
            <button className="action-button submit" onClick={onSaveAndPublish}>Save and Publish</button>
          </>
        }
        <input type="hidden" ref={register()} name="id" defaultValue={state.productDetail ? state.productDetail.id : ''} />
      </form>
    </div>
  )
}
export default NewProductPage
