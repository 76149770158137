import React, { MouseEvent, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  actionHideLoginForm,
  actionDoLogin, actionDoGoogleLogin,
  actionSwitchToSignupForm, actionSwitchToLoginForm,
  actionDoSignup, actionSwitchToShippingForm
} from './loginFormSlice'
import {
  actionShowUserProfile, setCurrentEditAddress
} from 'features/user/userSlice'
import { verifyEmailAvailable } from 'api/API'
import { RootState } from 'app/rootReducer'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import closeIcon from 'images/close.svg'
import { GoogleLogin } from 'react-google-login'
import { Link } from 'react-router-dom'
import { AddressEditControls } from 'features/address/AddressEdit'
import { actionEditNewAddress } from 'features/user/userSlice'

//<a href="#" className="btn-face m-b-20"><i className="fa fa-facebook-official"></i>Facebook</a>//

type LoginCallBack = () => void

type Props = {
  callback?: LoginCallBack,
  proceedToPayment?: boolean,
}
const LoginSubForm = ({ callback, proceedToPayment }: Props) => {
  const dispatch = useDispatch()
  const {register, handleSubmit, errors} = useForm();
  const onLogin = (data: any) => {
    dispatch(actionDoLogin(data.email, data.password, callback))
  }
  const state = useSelector(
    (s: RootState) => s.loginForm
  )
  const onCreateAccountClicked = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(actionSwitchToSignupForm())
  }
	const responseGoogle = (response: any) => {
		if (response.error) {
			console.log('login failed with error', response.error)
		} else {
			dispatch(actionDoGoogleLogin(response.tokenId, callback))
    }
	}
  return (
    <div className={classNames('subform-login', {active: state.loginFormActive})}>
      <form className="form form-login" onSubmit={handleSubmit(onLogin)}>
        <div className="title">Log In with:</div>
        <div className="social-login flex-grid">
          <GoogleLogin
            clientId="787527164080-lqun0ck6ik78kn01jc2b69mjk3vkjl7m.apps.googleusercontent.com"
            buttonText="Google"
            onSuccess={ responseGoogle }
            onFailure={ responseGoogle }
            cookiePolicy={'single_host_origin'}
          />
        </div>
        <div className="title">Or</div>
        { state.loginError &&
          <div className="error-message">{state.loginError.message}</div>
        }
        <div className="input-wrap">
          <input ref={register({
            required: 'Email address is required.',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid email address.'
            }
          })} type="text" name="email" placeholder="Email" />
          <span className="input-error-message">{ errors.email && errors.email.message }</span>
        </div>
        <div className="input-wrap">
          <input ref={register({
            required: 'Password is required.',
          })} type="password" name="password" placeholder="Password"/>
          <span className="input-error-message">{ errors.password && errors.password.message }</span>
          <Link className="link-text forgot-password-link" to="/user/forget_password">Forgot Password</Link>
        </div>
        { proceedToPayment ?
          (<button className="action-button">Log In to checkout</button>)
          :
          (<button className="action-button">Log In</button>)
        }
        <div className="link-wrap">
          No account? Join us by <button className="link-text" onClick={onCreateAccountClicked} >Create one</button> {proceedToPayment && (<span>or <button>Checkout as guest</button></span>)}
        </div>
      </form>
    </div>
  )
}

const ShippingSubForm = () => {
  const { register, handleSubmit, errors, setValue } = useForm({mode: 'onChange'});
  const dispatch = useDispatch()
  const state = useSelector(
    (s: RootState) => s.loginForm
  )
  const onSetShipping = async (data: any) => {
    dispatch(actionHideLoginForm())
    dispatch(actionShowUserProfile())
  }
  const userState = useSelector(
    (s: RootState) => s.user
  )
  const onSkip = () => {
    dispatch(actionHideLoginForm())
    dispatch(setCurrentEditAddress(null))
  }
    return (
      <div className={classNames('subform-shipping', {active: state.shippingFormActive})}>
        <form className="form sign-up-form" onSubmit={handleSubmit(onSetShipping)}>
          <div className="title">Shipping Details:</div>
          <div>
            { userState.currentEditAddress &&
              <AddressEditControls register={register} errors={errors} setValue={setValue}
                { ...userState.currentEditAddress }
              />
            }
          </div>
          <button className="action-button">Save</button>
          <button className="link-text" onClick={onSkip}>Skip for now</button>
        </form>
      </div>
    )
}

function random_color() {
  let x = Math.floor(Math.random() * 256)
  let y = Math.floor(Math.random() * 256)
  let z = Math.floor(Math.random() * 256)
  let color = "rgb(" + x + "," + y + "," + z + ")"
	return color
}

const SignupSubForm = () => {
  const { register, handleSubmit, errors, getValues } = useForm({mode: 'onChange'});
  const dispatch = useDispatch()
  const state = useSelector(
    (s: RootState) => s.loginForm
  )

  const onSwitchToLoginClicked = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(actionSwitchToLoginForm())
  }
  let avatars = [...Array(50)].map((v, i) => i + 1)
  const [ avatarId, setAvatarId ] = useState(0)

  const onAvatarClicked = (e: any) => {
    console.log('set id to', e.target.dataset.id)
    setAvatarId(parseInt(e.target.dataset.id, 10))
  }

  const [ initial, setInitial ] = useState('\u00A0')
  const [ bg, setBg ] = useState('rgb(255, 0, 0)')
  const [ fg, setFg ] = useState('rgb(255, 255, 255)')
  useEffect(() => {
    const bgs = [
      '#ff0000', 'rgb(253,112,92)', 'rgb(0,181,100)', 'rgb(204,136,247)',
      'rgb(41,39,61)', 'rgb(75,203,152)', 'rgb(253,69,129)', 'rgb(123,200,252)',
      'rgb(109,184,224)',

    ]
    const bg = bgs[Math.floor(Math.random() * bgs.length)]
    setBg(bg)
    setFg(random_color())
  }, [dispatch])
  const onSignup = async (data: any) => {
    console.log('data received is', data)
    let signupData = {...data}
    signupData.avatarFg = fg
    signupData.avatarBg = bg
    signupData.avatarId = avatarId
    await dispatch(actionDoSignup(signupData))
    await dispatch(actionEditNewAddress())
    dispatch(actionSwitchToShippingForm())
  }
  return (
    <div className={classNames('subform-signup', {active: state.signupFormActive})}>
      <form className="form sign-up-form" onSubmit={handleSubmit(onSignup)}>
      <div className="title">Hello Friend, let’s get started.</div>
      <div className="input-wrap">
        <input
          ref={register({
            required: 'First Name is required.',
            validate: value => {
              if (value) {
                if (initial !== value[0]) {
                  setInitial(value[0])
                }
                return true
              } else {
                return false
              }
            }
          })}
          className="input-half-grid" type="text" name="firstName" placeholder="First Name" />
          <span className="input-error-message">{ errors.firstName && errors.firstName.message }</span>
        <input className="input-half-grid" type="text" name="lastName" placeholder="Last Name (Optional)" />
      </div>
        <div className="input-wrap">
          <input ref={register({
            required: 'Email is required.',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid email address.'
            },
            validate: {emailExists: async value => await verifyEmailAvailable(value)}
          })} type="text" name="email" placeholder="Email" />
          <span className="input-error-message">{ errors.email && errors.email.message }
          { errors.email && errors.email.type === 'emailExists' &&
              <span>Email address is already taken.</span> }</span>
        </div>
        <div className="input-wrap">
          <input ref={register({
            required: 'Password is required'
          })} type="password" name="password" placeholder="Password" />
          <span className="input-error-message">{ errors.password && errors.password.message }</span>
        </div>
        <div className="input-wrap">
          <input ref={register({
            required: 'Confirm Password is required',
            validate: {passwordMustBeSame: value => (value === getValues('password'))}
          })} type="password" name="confirm_password" placeholder="Confirm Password" />
          { errors.confirm_password && <span className="input-error-message">{errors.confirm_password.message}</span> }
          { errors.confirm_password && errors.confirm_password.type === 'passwordMustBeSame' &&
              <span className="input-error-message">The two passwords entered do not match.</span>
          }
        </div>
        <div className="title avatar-selection-title">
          Choose your avatar
        </div>
        <div className="avatar-selection">
          <div className="avatar-item" onClick={onAvatarClicked} data-id={0}>
            <div className="dynamic-avatar" style={
              {
                backgroundColor: bg,

              }
            }>
              <span className="name-initial" style={
                {
                  color: fg,
                  fontSize: '16px'
                }
              }>{initial}</span>
            </div>
          </div>
          { avatars.map( a => {
            return (
              <div className="avatar-item" key={a} onClick={onAvatarClicked} data-id={a}>
                <img src={'https://img.wabox.anniez.net/avatar/' + a + '.png'} className={classNames('avatar-img', {selected: avatarId === a})} data-id={a} />
              </div>
            )
          })
          }
        </div>
        <button className="action-button join-button">Join</button>
        Already have an account? <button className="link-text" onClick={onSwitchToLoginClicked}>Log In here</button>
      </form>
    </div>
  )
}
const LoginForm = ({ callback, proceedToPayment }: Props) => {
  const dispatch = useDispatch()
  const state = useSelector(
    (s: RootState) => s.loginForm
  )
  const onCloseButtonClicked = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(actionHideLoginForm())
    dispatch(actionSwitchToLoginForm())
  }
  return (
    state.show ? (
      <div className={classNames('login-form', {active: state.mainFormActive})}>
        <div className="close-btn" onClick={onCloseButtonClicked}><img alt="Close" src={closeIcon}  /></div>
        {state.showLoginForm && <LoginSubForm callback={callback} proceedToPayment={proceedToPayment}/>}
        {state.showSignupForm && <SignupSubForm />}
        {state.showShippingForm && <ShippingSubForm />}
      </div>
    ) : null
  )
}
export default LoginForm
