import { RootState } from 'app/rootReducer'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { 
  Product, addProduct as APIAddProduct, addImage as APIAddImage, ProductImage,
  processGetAdminProducts, loadImages as APILoadImages,
  updateProduct as APIUpdateProduct,
} from 'api/API'
import { createSelector } from 'reselect'
import { actionAddProductToList } from '../productList/productListSlice'
import { AppThunk } from 'app/store'

type CallbackFunc = () => void

type NewProductState = {
  productDetail: Product | null,
  newProductError: string | null,
  images: ProductImage[]
  productList: Product[] | null
  currentEditing: number | null
}
let initialState: NewProductState = {
  productDetail: null,
  newProductError: null,
  images: [],
  productList: [],
  currentEditing: null

}

const adminProductSlice = createSlice({
  name: 'adminProduct',
  initialState,
  reducers: {
    setDetail(state: NewProductState, action: PayloadAction<Product>) {
      state.productDetail = action.payload
    },
    newProductFailure(state: NewProductState, action: PayloadAction<string>) {
      state.newProductError = action.payload
    },
    addImage(state: NewProductState, action: PayloadAction<ProductImage>) {
      state.images.push(action.payload)
    },
    makePrimary(state: NewProductState, action: PayloadAction<number>) {
      //state.images.forEach(im => { im.isPrimary = false })
      for (let im of state.images) {
        im.isPrimary = false
      }
      state.images[action.payload].isPrimary = true
    },
    setInSync(state: NewProductState, action: PayloadAction<ProductImage>) {
      for (let im of state.images) {
        if (im.size === action.payload.size && im.name === action.payload.name) {
          im.id = action.payload.id
          im.uuid = action.payload.uuid
          im.isInSync = true
        }
      }
    },
    removeImage(state: NewProductState, action: PayloadAction<number>) {
      const img = state.images[action.payload]
      state.images.splice(action.payload, 1)
      if (img.isPrimary) {
        if (state.images.length > 0) {
          state.images[0].isPrimary = true
        }
      }
    },
    setProductList(state: NewProductState, action: PayloadAction<Product[]>) {
      state.productList = action.payload
    },
    setCurrentEditingProduct(state: NewProductState, action: PayloadAction<number>) {
      state.currentEditing = action.payload
      state.productDetail = state.productList!.find(p => p.id === action.payload) as Product
    },
    clearEditingProduct(state: NewProductState) {
      state.currentEditing = null
      state.productDetail = null
      state.images = []
    },
    loadImages(state: NewProductState, action: PayloadAction<ProductImage[]>) {
      state.images = action.payload
    },
    addProductToList(state: NewProductState, action: PayloadAction<Product>) {
      let product = state.productList!.find(p => p.id === action.payload.id)
      if (product) {
        Object.assign(product, action.payload)
      } else {
        state.productList!.push(action.payload)
      }
    },
  }
})

export const {
  newProductFailure, addImage, makePrimary, setInSync,
  removeImage, setProductList, setCurrentEditingProduct,
  loadImages, addProductToList, clearEditingProduct
} = adminProductSlice.actions;

export default adminProductSlice.reducer;

export const actionAddOrUpdateProduct = (product: any, successCallback?: CallbackFunc): AppThunk => async (dispatch, getState) => {
  try {
    const adminProductState = getState().adminProduct
    const imageList = adminProductState.images
    if (product.id === "") {
      const productDetail = await APIAddProduct(product, imageList)
      // add product to both user product list and admin product list
      dispatch(actionAddProductToList(productDetail))
      dispatch(addProductToList(productDetail))
      dispatch(clearEditingProduct)
    } else {
      const productDetail = await APIUpdateProduct(product, imageList)
      dispatch(actionAddProductToList(productDetail))
      dispatch(addProductToList(productDetail))
      dispatch(clearEditingProduct)
    }
    if (successCallback) {
      successCallback()
    }
  } catch (err) {
    console.log('error')
    dispatch(newProductFailure(err.response.data))
  }
}

export const actionAddImage = (name: string, dataUrl: string, mimeType: string, size: number, lastModified: number): AppThunk => async (dispatch, getState) => {
  try {
    const userState = getState().user
    const productState = getState().adminProduct
    let primary = false
    if (productState.images.length === 0) {
      primary = true
    }
    let img: ProductImage = {
      name: name,
      dataUrl: dataUrl,
      mimeType: mimeType,
      size: size,
      uploaded: (new Date()).toString(),
      uploadedBy: userState!.userDetail!.id,
      lastModified: lastModified,
      isInSync: false,
      isPrimary: primary
    }
    dispatch(addImage(img))
    const imgData = await APIAddImage(img)
    dispatch(setInSync(imgData))
  } catch (err) {
    console.error(err.toString())

  }
}

export const actionMakePrimary = (index: number): AppThunk => async (dispatch) => {
  dispatch(makePrimary(index))
}

export const actionRemoveImage = (index: number): AppThunk => async (dispatch) => {
  dispatch(removeImage(index))
}

export const actionGetProducts = (): AppThunk => async (dispatch) => {
  const productList: Product[] = await processGetAdminProducts()
  dispatch(setProductList(productList))
}

export const actionEditProduct = (productId: number): AppThunk => async (dispatch) => {
  dispatch(setCurrentEditingProduct(productId))
  dispatch(actionLoadImagesForProduct(productId))
}

export const actionLoadImagesForProduct = (productId: number): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState().adminProduct
    const product = state.productList!.find(p => p.id === productId) as Product
    if (product) {
      let images = await APILoadImages(product.images as number[])
      let imageIdLookup: {[key: number]: ProductImage} = {}
      for (let im of images) {
        imageIdLookup[im.id as number] = im
        if (im.id === product.primaryImage) {
          im.isPrimary = true
        }
      }
      
      images = []
      for (let imageId of product!.images as number[]) {
        images.push(imageIdLookup[imageId])
      }
      dispatch(loadImages(images))
    }
  } catch (err) {
    console.error(err.toString())

  }
}


export const activeProductSelector: (state: RootState) => Product[] = createSelector(
  (state: RootState) => state.adminProduct.productList,
  (products) => {
    let active = []
    if (products !== null) {
      for (let p of products) {
        if (p.status === 'Active') {
          active.push(p)
        }
      }
    }
    return active
  }
)
