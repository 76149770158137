import React, {useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Product, PRODUCT_IMAGE_HOST } from 'api/API'
import { RootState } from 'app/rootReducer'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { favoriteProductSelector, actionFetchFavorites, actionHideUserProfile } from './userSlice'
import { useHistory } from 'react-router-dom'
const Favorite = () => {
  const { handleSubmit } = useForm();
  const dispatch = useDispatch()
  const history = useHistory()
  const state = useSelector(
    (s: RootState) => s.user
  )
  const favorites = useSelector(favoriteProductSelector)

  const onFormSubmit = () => {
  }
  const onFavoriteItemClicked = (p: Product) => {

    history.push(`/product/${p.uuid}`)
    dispatch(actionHideUserProfile())
  }
  useEffect(() => {
    dispatch(actionFetchFavorites())
  }, [dispatch])
  return (
    <div className={classNames('user-favorite', {active: state.favoriteActive})}>
      <form className="form-user-favorite" onSubmit={handleSubmit(onFormSubmit)}>
        { favorites.length === 0 ? <span className="favorite-placeholder">Your favorite items will be shown here.</span> : null}
        { favorites.map(p => (
          <div onClick={() => onFavoriteItemClicked(p)} className="product-item" key={p.id} >
            <img src={`${PRODUCT_IMAGE_HOST}/product_images/${p.primary_image_uuid}`} className="product-image col-2" alt={p.name} />
            <div className="product-detail">
              <div className="product-title">
                {p.name}
              </div>
              <div className="product-description" dangerouslySetInnerHTML={{__html: p.description}}>
              </div>
            </div>
            <div className="product-price">
              ${p.price.toFixed(2)}
            </div>
          </div>
        ))}
      </form>
    </div>
  )

}

export default Favorite
