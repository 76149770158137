import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { actionContact } from 'features/user/userSlice'
import favIcon from 'images/fav-filled.svg'
import userIcon from 'images/user-icon.svg'
import adminIcon from 'images/admin-icon.svg'
import { toast } from 'react-toastify'
const FAQ = () => {
  const dispatch = useDispatch()
  const { userDetail } = useSelector(state => state.user)
  const { register, handleSubmit, errors } = useForm()
  const [ submitted, setSubmitted ] = useState(false)
  const [ reference, setReference ] = useState('')
  const onFormSubmit = async (data) => {
    const result = await dispatch(actionContact(data));
    setSubmitted(true)
    setReference(result)
  }
  if (submitted) {
    return (
      <div className="contact-page list-grid contact-thank-you">
      <div className="contact-banner">
        <div className="contact-illustration"></div>
        <h2>Thank you for contacting us<br/>We will get back to you as soon as possible.<br/>Your reference No. is #{reference}</h2>
      </div>
      <div className="contact-form">
        
      </div>
      </div>
    )
  } else {
    return (
      <div className="contact-page list-grid">
      <div className="contact-banner">
        <div className="contact-illustration"></div>
        <h2>Welcome to WOW Homestuff customer support.</h2>
      </div>

      <div className="contact-form">
      <form onSubmit={handleSubmit(onFormSubmit)} className="contact-form-content">
        <div className="contact-block-wrapper">
          <div className="contact-title">
          Name*
          </div>
          <div className="contact-form-control">
            <input type="text" className="contact-input-text" name="name"
              ref={register({required: 'Please enter Name'})}
            />
            {errors.first_name && <span className="input-error-message">{errors.first_name.message}</span>}
          </div>
        </div>

        <div className="contact-block-wrapper">
          <div className="contact-title">
          Email Address*
          </div>
          <div className="contact-form-control">
            <input type="text" className="contact-input-text" defaultValue={userDetail ? userDetail.email : ''} name="email"
              ref={register({required: 'Please enter Email'})}
            />
            {errors.email && <span className="input-error-message">{errors.email.message}</span>}
          </div>
        </div>

        <div className="contact-block-wrapper">
          <div className="contact-title">
            Order No.
          </div>
          <div className="contact-form-control">
            <input type="text" className="contact-input-text" name="order_no"
              ref={register()}
            />
          </div>
        </div>

        <div className="contact-block-wrapper">
          <div className="contact-title">
            Message
          </div>
          <div className="contact-form-control">
            <textarea className="contact-textarea" name="message"
              ref={register()}
              >
            </textarea>
          </div>
        </div>

        <div className="contact-submit">
          <button className="action-button">Submit</button>
        </div>
      </form>
      </div>
      </div>
    )
  }
}

export default FAQ;
