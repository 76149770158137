import React, {MouseEvent, useState, useEffect, useLayoutEffect} from 'react'

import { RootState } from 'app/rootReducer'
import { Product, API_HOST, RunningDeal, PRODUCT_IMAGE_HOST } from 'api/API'
import { useSelector, useDispatch } from 'react-redux'
import { runningDealByProductSelector, runningDealSelector, actionShowHint } from 'features/deal/dealSlice'
import { actionShowImage, actionBuyProduct, actionFetchProducts, setPopupJoinDeal, selectProduct } from './productListSlice'
import { actionHideUserProfile, actionAddFavorite, actionRemoveFavorite, favoriteProductSelector } from 'features/user/userSlice'
import { bestDealSelector,
  setJoinDeal, setStage, Stages, setActiveDealId, actionProceedToLogin, actionCreateOrder, actionShowPaymentMethods, actionProceedToDetail, setJoiningRunning
} from '../paymentForm/paymentFormSlice'
import favIcon from 'images/fav-filled.svg'
import { useHistory, useLocation } from 'react-router-dom'
import backArrow from 'images/back-arrow.svg'
import favIconUnfilled from 'images/fav-unfilled.svg'
import blankLoading from 'images/blank-loading.jpg'
import classNames from 'classnames'
import { DealNotice } from 'features/productList/ProductListItem'
import { DealStarter, DealFloat } from './ProductListPage'
import moment from 'moment'
import Popup from 'reactjs-popup'
import unknownUserIcon from 'images/unknown-user-icon.svg'

type ThumbProps = {
  imageId: number
}

const SwitcherThumbnail: React.FC<ThumbProps> = (props) => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state.productList)
  const onSwitchImage = (e: MouseEvent) => {
    dispatch(actionShowImage(props.imageId))
  }
  return (
    <div className="product-thumbnail" onClick={onSwitchImage}>
      <img alt="Product Thumbnail" className={classNames({active: props.imageId === state.productDetailImage})} src={`${API_HOST}/api/v2/product/thumbnail/${props.imageId}`} />
    </div>
  )
}
export default (props: any) => {
  const { product, notices, removeNoticesByProduct } = props
  const state = useSelector((state: RootState) => state.productList)
  const history = useHistory()
  const dispatch = useDispatch()
  const favorites = useSelector(favoriteProductSelector)
  const isFav = favorites.some(p => p && p.id === product.id)
  const { deal, discountAmount } = useSelector((state: RootState) => bestDealSelector(state, product!.id))
  const [now, setNow] = useState(new Date())

  const { joiningRunning, deliveryAddressId, quantity } = useSelector((state: RootState) => state.paymentForm)
  const dealJoined = joiningRunning !== null
  //let runningDeals = useSelector((state: RootState) => runningDealByProductSelector(state, product!.id))
  //runningDeals = runningDeals.filter((rd: RunningDeal) => moment(rd.expiry) > moment(now))
  const { userDetail } = useSelector((state: RootState) => state.user)
  const _notices = notices.filter((n: any) => n.product_id === product.id)
  const onBackClicked = (e: MouseEvent) => {
    //history.push('/')
    history.goBack()

  }
  const location = useLocation()
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  const onFavClicked = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (isFav) {
      dispatch(actionRemoveFavorite(product.id))
    } else {
      dispatch(actionAddFavorite(product.id))
    }
  }
  const onShareToWhatsApp = (e: MouseEvent) => {
		//var x = document.URL
		//window.location.href = "whatsapp://send?text=" + encodeURIComponent(x)
	}
  //<div>{deal?.name}<span className="deal-running">{ runningDeals.length }</span></div>
  const onBuyClicked = (e: MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
    if (dealJoined) {
      return
    }
    dispatch(actionHideUserProfile())
    dispatch(actionBuyProduct(product))
  }

  const onDealClicked = async (e: MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
    if (!dealJoined) {
      dispatch(actionShowHint(null))
      return
    }
    dispatch(actionHideUserProfile())
    //await dispatch(actionBuyProduct(product, deal.id))
    //if (products.length === 0) {
    //  await dispatch(actionFetchProducts('all'))
    //}
    await dispatch(selectProduct(product))
    if (deal !== null) {
      await dispatch(setJoinDeal(deal.id))
      await dispatch(setActiveDealId(deal.id))
    }
    await dispatch(setStage(Stages.SETUP))
    if (userDetail === null) {
      dispatch(actionProceedToLogin())
    } else {
      await dispatch(actionCreateOrder([product!], quantity, deliveryAddressId!))
      if (deliveryAddressId !== null) {
        await dispatch(actionShowPaymentMethods())
      }
      console.log('abou to proceed to detail');
      dispatch(actionProceedToDetail())
    }
  }

  const runningDeals = useSelector(runningDealSelector)
  const runningDeal = runningDeals.find((d: any) => d.id === joiningRunning)
  const noticeDeal = runningDeals.find((d: any) => d.id === state.popupJoinDeal)

  const onJoinOK = () => {
    dispatch(setJoiningRunning(state.popupJoinDeal))
    dispatch(setPopupJoinDeal(null))
  }
  const onCancel = () => {
    dispatch(setPopupJoinDeal(null))
  }

  const myIcon: any = userDetail ?
    <img src={API_HOST + '/api/user/avatar/' + userDetail.id} className="join-deal-circle" />
    :
    <img src={unknownUserIcon} width="36" height="36" className="join-deal-circle" />;

  const [showHourGlass, setShowHourGlass] = useState(true)
  const onHideHourGlass = () => {
    //setShowHourGlass(false)
  }
  const buttons = <>

        <div className="favIcon" onClick={onFavClicked}>
          <img src={isFav ? favIcon : favIconUnfilled} alt="Favourite Icon" />
        </div>
        <div onClick={onBuyClicked} className={classNames(['action-button', 'action-button-left', 'col-4'],
          {disabled: dealJoined})} >
					<div className={classNames('regular-price', {disabled: dealJoined})}>${product.price.toFixed(2)}</div>
          {!dealJoined && <div>Buy</div>}
        </div>
        <div onClick={onDealClicked} className={classNames(['action-button', 'action-button-right', 'buy-button', 'col-8'])}>
        <DealNotice notices={_notices} productId={product?.id} removeNoticesByProduct={removeNoticesByProduct} />

			  <div>${(product.price - discountAmount).toFixed(2)}</div>
        <div>{deal?.name}</div>
        {!dealJoined && <span className="deal-locked"></span>}
        </div>

    </>
  return (
    <>
      <div className="product-detail-wrap">

        <div className="product-detail-panel-left">
          <DealStarter />
        </div>
        <div className={classNames('product-detail-panel', {'no-starter': joiningRunning})}>
        <div className="back-arrow-wrap" onClick={onBackClicked}><img src={ backArrow } alt="back" width="24" height="24"/><button className="link-text">Back to home</button></div>
          <Popup open={state.popupJoinDeal !== null} closeOnDocumentClick closeOnEscape
            contentStyle={{width: '90%'}}
            onClose={onCancel} >
            <div className="join-detail">
              <div className="title">Congratulations</div>
              <div className="avatars">
                <div className="starter-icon">
                  <img src={API_HOST + '/api/user/avatar/' + noticeDeal?.start_user_id} className="join-deal-circle" />
                </div>
                <div className="plus-icon"> + </div>
                <div className="my-icon">
                  {myIcon}
                </div>
                <div className="equal-sign">
                =
                </div>
                <div className="unlock-icon">
                </div>
              </div>
              <div className="message">
                You are joining a Wow! deal, discount has unlocked! Happy shopping!
              </div>
              <div className="buttons">
                <button className="btn-ok" onClick={onJoinOK}>OK</button>
                <button className="text-button" onClick={onCancel}>Cancel</button>
              </div>
            </div>
          </Popup>
          <DealFloat/>
          <div className="product-image-wrap">
            <div className="product-image">
              <div className="mobile-back-arrow-wrap" onClick={onBackClicked}><img src={ backArrow } className="product-back-arrow" alt="back" width="24" height="24"/></div>
                { showHourGlass && <div className="hourglass-container"><img src={ blankLoading } /></div> }
                { state.productDetailImage !== null &&
                  <img className="image" src={`${API_HOST}/api/product/image/${state.productDetailImage}`} onLoad={onHideHourGlass} alt={product.name + ' Image'} />
                }
            </div>
            <div className="image-switcher">
              {state.productDetailImage !== null && product.images!.map((imageId: any) => <SwitcherThumbnail key={imageId} imageId={imageId} />)}
            </div>
            <div className="share-icons mobile-share-icons">
              <span>Share to:</span>
              <a href={'whatsapp://send?text=' + encodeURIComponent(document.URL)} onClick={onShareToWhatsApp} className="whatsapp"></a>
            </div>
          </div>
          { !state.isDetailLoading &&
            <div className="product-info-wrap">
              <div className="product-info">
                <div className="product-name">{ product.name }<div className="product-brand">{ product.brand }</div></div>
                <div className="product-detail-content">
                  <div className="product-descirption" dangerouslySetInnerHTML={{__html: product.description}}></div>
                  <div className="product-tag">{ product.meta!.tags }</div>
                  <div className="product-dimension">{ product.dimensions }</div>
                  <div className="product-weight">{ product.weight }</div>
                </div>
                <div className="product-detail-buttons">
                  {buttons}

                </div>
                <div className="share-icons">
                  <span>Share to:</span>
                  <a className="share-whatsapp-icon" href={'https://api.whatsapp.com/send?text=' + encodeURIComponent(document.URL)} onClick={onShareToWhatsApp}></a>
                  <a className="share-pinterest-icon" href={'https://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent(document.URL) + '&media='+ encodeURIComponent(`${PRODUCT_IMAGE_HOST}/product_thumbnail/${product.primary_image_uuid}.jpg`) + '&description=' + encodeURIComponent(product.name + ' - WOW HomeStuff')} target="_blank"></a>
                </div>
              </div>
            </div>
          }
        </div>
        <div className="product-detail-panel-right"></div>
      </div>
      <div className="product-detail-buttons-mobile">
        {buttons}
        <a href={'whatsapp://send?text=' + encodeURIComponent(document.URL)} onClick={onShareToWhatsApp} className="whatsapp"></a>
      </div>
    </>
  )
}
