import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { OrderDetail, Product } from 'api/API'
import { createSelector } from 'reselect'
import { useSelector, useDispatch } from 'react-redux'
import {
  actionFetchAdminOrders, GroupByType, setGroupBy, groupOrderSelector, ProductGroupDetail,
  OrderGroup, AddressGroupDetail, actionUpdatePhase
} from 'features/order/orderSlice'

import { actionGetProducts } from 'features/admin/adminProductSlice'

import { RootState } from 'app/rootReducer'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const selectProduct = createSelector(
  (state: RootState) => state.adminProduct.productList,
  (_: RootState, productId: number) => productId,
  (products, productId) => {
    if (products === null || productId === null) {
      return null
    }
    return products.find((product: Product) => product.id === productId)
  }
)

const OrderItem = ({ order, className,
  showName = true, showPrice = true, showAddress = true, showStatus = true, showTime = true}: {
    order: OrderDetail, className?: string,
    showName?: boolean, showPrice?: boolean, showAddress?: boolean, showStatus?: boolean, showTime?: boolean
  }) => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' })
  const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(new Date(order.create_time))
  const product = useSelector((state: RootState) => selectProduct(state, order.product_id as number))!
  const dispatch = useDispatch() 
  const [showChoosePhase, setShowChoosePhase] = useState(false)

  const onChoosePhase = () => {
    setShowChoosePhase(true)
  }
  const onBlur = () => {
    setShowChoosePhase(false)
  }
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log('new', e.target.value)
    dispatch(actionUpdatePhase(order.id, e.target.value))
    setShowChoosePhase(false)
  }

  //<option value="Placed">Placed</option>
  return (
    <div className={classNames('order-item', className)}>
    { showName &&
      <div className="product-title">
        [{product.id}] {product.name}
      </div>
    }
    { showPrice &&
        <>
          <div className="product-price">
            <div>${order.item_price.toFixed(2)} X {order.quantity} + ${order.shipping_fee.toFixed(2)}</div>
          </div>
          <div className="total-price">
            <div>${order.total_amount.toFixed(2)}</div>
          </div>
        </>
    }
    { showAddress &&
      <div className="delivered-to">
        {order.delivered_to}
      </div>
    }
    { showStatus &&
        <>
          <div className="order-status">
            { showChoosePhase ?
              <select className="order-phase" onChange={onChange} onBlur={onBlur}
                defaultValue={order.phase}
              >
                <option value="Received">Received</option>
                <option value="Preparing">Preparing</option>
                <option value="Shipping">Shipping</option>
                <option value="Delivered">Delivered</option>
              </select>
              : 
              <div className="order-status-label"
                onClick={onChoosePhase} onMouseEnter={onChoosePhase}
              >{order.phase}</div>
            }
          </div>
          <div className="payment-status">
            <div className="payment-status-label">{order.payment_status}</div>
          </div>
        </>

    }
    { showTime &&
      <div className="order-time">
        [{order.id}] {day}/{month}/{year}
      </div>
    }
    </div>
  )
}
const OrderGroupItem = ({ orderGroup, groupBy, className }: {orderGroup: OrderGroup, groupBy: GroupByType, className: string}) => {
  const [collapse, setCollapse] = useState(true)
  const onToggleCollapse = () => {
    if (collapse) {
      setCollapse(false)
    } else {
      setCollapse(true)
    }
  }
  switch (groupBy) {
    case GroupByType.NONE:
      return (
        <div key={orderGroup.id} className="nogroup">
          { orderGroup.orders.map(o => {
            return <OrderItem key={o.id} order={o} className={className} />
          })}
        </div>
      )
    case GroupByType.PRODUCT: {
      let detail = orderGroup.groupDetail as ProductGroupDetail
      return (
        <div key={orderGroup.id} className="order-group">
          <div className="order-info" onClick={onToggleCollapse}>
            [{detail.productId}] {detail.productName} <span className="order-count">{orderGroup.orders.length}</span>
          </div>
          { collapse ||
            <div className="order-breakdown">
              { orderGroup.orders.map(o => {
                return <OrderItem key={o.id} order={o} className={className} showName={false} />
              })}
            </div>
          }
        </div>
      )
    }
    case GroupByType.ADDRESS: {
      let detail = orderGroup.groupDetail as AddressGroupDetail
      return (
        <div key={orderGroup.id} className="order-group">
          <div className="order-info" onClick={onToggleCollapse}>
            {detail.address} <span className="order-count">{orderGroup.orders.length}</span>
          </div>
          { collapse ||
            <div className="order-breakdown">
              { orderGroup.orders.map(o => {
                return <OrderItem key={o.id} order={o} className={className} showAddress={false} />
              })}
            </div>
          }
        </div>
      )
    }
    case GroupByType.USER:
      return null
  }
}

const OrderColumn = ({ phase, className }: {phase: string, className: string}) => {
  const orderGroups = useSelector((state: RootState) => groupOrderSelector(state, phase))
  const { groupBy } = useSelector((state: RootState) => state.order)
  return <>
    <TransitionGroup>
    {orderGroups.map(orderGroup => 
      <CSSTransition
        classNames="order-group-card"
        in={true}
        timeout={{enter: 300, exit: 300}}
        key={orderGroup.id}
        >
        <OrderGroupItem key={orderGroup.id} orderGroup={orderGroup} groupBy={groupBy} className={className} />
      </CSSTransition>
    )}
    </TransitionGroup>
  </>
}

export const OrderAdmin = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    (async () => {
      await dispatch(actionGetProducts())
      dispatch(actionFetchAdminOrders())
    })()
  }, [dispatch])
  const { adminOrders, groupBy } = useSelector((state: RootState) => state.order)
  console.log('adminOrders', adminOrders)
  useEffect(() => {
  })
	return (
    <div className="order-dashboard">
      <div className="order-status-update order-recieved">
        <div className="order-sorting-label">
          <h4>Group by:</h4>
          <span onClick={() => dispatch(setGroupBy(GroupByType.NONE))} className={classNames({active: groupBy === GroupByType.NONE})}>None</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.PRODUCT))} className={classNames({active: groupBy === GroupByType.PRODUCT})}>Product</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.ADDRESS))} className={classNames({active: groupBy === GroupByType.ADDRESS})}>Address</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.USER))} className={classNames({active: groupBy === GroupByType.USER})}>User</span>
        </div>
        { adminOrders &&
          <OrderColumn phase="Received" className="received" />
        }
      </div>
      <div className="order-status-update order-preparing">
        <div className="order-sorting-label">
          <h4>Group by:</h4>
          <span onClick={() => dispatch(setGroupBy(GroupByType.NONE))} className={classNames({active: groupBy === GroupByType.NONE})}>None</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.PRODUCT))} className={classNames({active: groupBy === GroupByType.PRODUCT})}>Product</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.ADDRESS))} className={classNames({active: groupBy === GroupByType.ADDRESS})}>Address</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.USER))} className={classNames({active: groupBy === GroupByType.USER})}>User</span>
        </div>
        { adminOrders && 
          <OrderColumn phase="Preparing" className="preparing" />
        }
      </div>
      <div className="order-status-update order-shipped">
        <div className="order-sorting-label">
          <h4>Group by:</h4>
          <span onClick={() => dispatch(setGroupBy(GroupByType.NONE))} className={classNames({active: groupBy === GroupByType.NONE})}>None</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.PRODUCT))} className={classNames({active: groupBy === GroupByType.PRODUCT})}>Product</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.ADDRESS))} className={classNames({active: groupBy === GroupByType.ADDRESS})}>Address</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.USER))} className={classNames({active: groupBy === GroupByType.USER})}>User</span>
        </div>
        { adminOrders &&
          <OrderColumn phase="Shipping"  className="shipped" />
        }
      </div>
      <div className="order-status-update order-delivered">
        <div className="order-sorting-label">
          <h4>Group by:</h4>
          <span onClick={() => dispatch(setGroupBy(GroupByType.NONE))} className={classNames({active: groupBy === GroupByType.NONE})}>None</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.PRODUCT))} className={classNames({active: groupBy === GroupByType.PRODUCT})}>Product</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.ADDRESS))} className={classNames({active: groupBy === GroupByType.ADDRESS})}>Address</span>
          <span onClick={() => dispatch(setGroupBy(GroupByType.USER))} className={classNames({active: groupBy === GroupByType.USER})}>User</span>
        </div>
        { adminOrders &&
          <OrderColumn phase="Delivered" className="delivered" />
        }
      </div>
    </div>
  )
}
