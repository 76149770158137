import React, { useEffect, useState } from 'react'

import ProductListItem from './ProductListItem'
import { RootState } from 'app/rootReducer'
import { Product } from 'api/API'
import { useSelector } from 'react-redux'
import { FilterType } from './productListSlice'
import { processGetDealNotices, Notice } from 'api/API'
type Props = {
  products?: Product[]
  productId?: number | null
  isLoading?: boolean
  notices?: any,
  removeNoticesByProduct?: any
}

export default function ({ products, productId, isLoading, notices, removeNoticesByProduct }: Props) {
  const { filters } = useSelector((state: RootState) => state.productList)
  const { userDetail } = useSelector((state: RootState) => state.user)
  const useMountEffect = (fun: any) => useEffect(fun, [])
  const [ lastChecked, setLastChecked ] = useState(new Date())
  useMountEffect(() => {
    /*
    let intervalHandle: number
    intervalHandle = window.setInterval(async () => {
      //const data = await processGetDealNotices(lastChecked)
      //setLastChecked(new Date())
    }, 5000)
    */
    return () => {
      //window.clearInterval(intervalHandle)
    }
  })
  return (
    <>
      {isLoading ? 
        (
          <div className="lds-grid">
            <div></div><div></div><div></div>
            <div></div><div></div><div></div>
            <div></div><div></div><div></div>
          </div>
        ) :
        (products!.filter(product => {
          for (let f of filters!) {
            if (f.type === FilterType.FAVORITE) {
              if (userDetail !== null) {
                return userDetail.favorite!.indexOf(product.id) >= 0
              }
            }
          }
          return true
        })
        .map(product => (
          <ProductListItem key={product.id} product={product} notices={notices} removeNoticesByProduct={removeNoticesByProduct} />
        )))
      }
    </>
  )
}
