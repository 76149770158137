import React, {useEffect, MouseEvent } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'app/rootReducer'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import Favorite from './Favorite'
import FunctionNav from 'components/UserFunctionNav'
import MyOrder from '../order/MyOrder'
import { actionLogout } from '../loginForm/loginFormSlice'
import { actionHideUserProfile, actionRefreshUserInfo,
  actionGetAddresses,
  setCurrentEditAddress, actionResendVerification, actionEditNewAddress,
  primaryAddressSelector, setIsEditingPassword,
  actionEditPassword,
} from './userSlice'

import { AddressEditForm, AddressItem } from 'features/address/AddressEdit'

import closeIcon from 'images/close.svg'
import { APIStatus } from 'api/API'
import Popup from "reactjs-popup"
type IPropAddressEdit = {
  addressId: number | null
}

export const PasswordEditForm = ({ id }: {id: number}) => {
  const dispatch = useDispatch()
  const { register, handleSubmit, errors, getValues, setError } = useForm({mode: 'onChange'});
  const onFormSubmit = async (data: any) => {
    const result: APIStatus = await dispatch<any>(actionEditPassword(data.old_password, data.password))
    if (result.status === 'success') {
      dispatch(setIsEditingPassword(false))
    } else {
      setError('old_password', {
        type: 'manual',
        message: 'Incorrect password. Please input your current password'
      })
    }
  }

  const onCancelEdit = (e: MouseEvent) => {
    dispatch(setIsEditingPassword(false))
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="update-password-form">
      <div className={classNames('password-edit-form')}>
				<div className="form">
          <div className="field-name">Current Password *</div>
          <div className="field-content input-wrap">
            <input type="password" ref={register({required: 'Please enter current password.'})}
              name="old_password"
              placeholder="Current Password"
            />
            {errors.old_password && <span className="input-error-message">{errors.old_password.message}</span>}
          </div>
          <div className="field-name">New Password *</div>
          <div className="field-content input-wrap">
            <input type="password" ref={register({required: 'Please enter new password.'})}
              name="password"
              placeholder="New Password"
            />
            {errors.password && <span className="input-error-message">{errors.password.message}</span>}
          </div>
          <div className="field-name">Confirm New Password *</div>
          <div className="field-content input-wrap">
            <input type="password" ref={register({
              required: 'Please enter confirm password.',
              validate: {passwordsMustBeSame: value => value === getValues('password')}
            })}
              name="confirm_password"
              placeholder="Confirm Password"
            />
            {errors.confirm_password && <span className="input-error-message">{errors.confirm_password.message}</span>}
            {errors.confirm_password && errors.confirm_password.type === 'passwordsMustBeSame' &&
              <span className="input-error-message">The two passwords entered do not match.</span>
            }
					</div>
				</div>
      </div>
      <div className="action-button-wrap">
        <button onClick={onCancelEdit} className="action-button action-button-cancel action-button-small">Cancel</button>
        <button className="action-button action-button-save action-button-small">Save</button>
      </div>
      <input type="hidden" ref={register()} name="id" defaultValue={id} />
    </form>
  )
}

const UserInfo = () => {
  const { handleSubmit } = useForm();
  const dispatch = useDispatch()
  const state = useSelector(
    (s: RootState) => s.user
  )
  const primaryAddress = useSelector(primaryAddressSelector)
  const onLogoutClicked = () => {
    dispatch(actionLogout())
  }
  useEffect(() => {
    dispatch(actionRefreshUserInfo())
    dispatch(actionGetAddresses())
  }, [dispatch])

  const onFormSubmit = (data: object) => {
  }
  const onResendVerification = (e: MouseEvent) => {
    dispatch(actionResendVerification())
  }
  const onAddAddressClicked = (e: MouseEvent) => {
    dispatch(actionEditNewAddress())
  }
  const onPasswordChangeClicked = (e: MouseEvent) => {
    dispatch(setIsEditingPassword(true))
  }
  return (
    <div className={classNames('user-info', {active: state.userInfoActive})}>
    {(state.userDetail && !state.userDetail.verified) ?
      <div className="user-notification">
        <p >Welcome! Thank you for Join Us.</p>
        <p>An email has been sent to your email account, pleaes check your email and just one click to verify your email address.</p>
      </div>
      : null}
      { state.userDetail &&
        (<form className="form-user-info" onSubmit={handleSubmit(onFormSubmit)}>
          <div className="field-name">Email{state.userDetail.verified ? <span className="text-label green-text-label">Verified</span>:<span className="text-label orange-text-label">Unverified</span>}</div>
          <div className="field-content">
            {state.userDetail!.email}
            {state.userDetail.verified ? null : <button onClick={onResendVerification} className="function-button function-button-small resend-email-verification-button">Resend Verification</button>}
          </div>
          <div className="field-name">Password</div>
          <div className="field-content password-content">
            <button className="link-text" onClick={onPasswordChangeClicked}>Change</button>
          </div>
          <div className="address">
            <div className="field-name">Address<span className="text-label green-text-label">Primary</span></div>
            <div className="field-content no-border primary-address">{primaryAddress && <AddressItem key={primaryAddress.id as number} isPrimary={true} allowEdit={true} { ...primaryAddress } />}</div>
            <div className="field-name">Other Addresses</div>
            <div className="field-content">
              { state.addresses && state.addresses.map(addr => {
                if (addr.id !== primaryAddress?.id) {
                  return <AddressItem key={addr.id as number} isPrimary={false} allowEdit={true} allowDelete={true} { ...addr } />
                } else {
                  return null
                }
              })}
              <div className="action-button-wrap"><button className="action-button action-button-small" onClick={onAddAddressClicked}>Add Address</button></div>
            </div>

          </div>
        </form>)
      }

      <div className="logout">
        <button className="function-button function-button-small" onClick={onLogoutClicked}>Logout</button>
      </div>
    </div>
  )
}

export default () => {
  const dispatch = useDispatch()
  const { showMainForm, mainFormActive, showUserInfo, showFavorite, showOrders,
    currentEditAddress, isEditingPassword, userDetail } = useSelector(
    (s: RootState) => s.user
  )
  const onCloseClicked = (e: MouseEvent) => {
    dispatch(actionHideUserProfile())
  }
  return (
    showMainForm ?
    (<div className={classNames('profile-panel', {active: mainFormActive})}>
      <div className="close-btn" onClick={onCloseClicked}><img src={closeIcon} alt="Close Button"/></div>
      <FunctionNav />
      <Popup open={currentEditAddress !== null} closeOnDocumentClick closeOnEscape
        onClose={() => dispatch(setCurrentEditAddress(null))}
        contentStyle={{width: '90%', maxHeight: '90%'}}
      >
        <div className="modal">
        <div className="popup-header">
          <h4>Edit Address</h4>
          <span className="close" onClick={() => {
            dispatch(setCurrentEditAddress(null))
          }}>
            &times;
          </span>
        </div>

          <AddressEditForm address={currentEditAddress} />
        </div>
      </Popup>
      <Popup open={isEditingPassword} closeOnDocumentClick closeOnEscape
        onClose={() => dispatch(setIsEditingPassword(false))}
        contentStyle={{width: '90%'}}
      >
        <div className="modal">
          <span className="close" onClick={() => {
            dispatch(setIsEditingPassword(false))
          }}>
            &times;
          </span>
          {userDetail && <PasswordEditForm id={userDetail!.id} />}
        </div>
      </Popup>
      {showUserInfo && <UserInfo />}
      {showFavorite && <Favorite />}
      {showOrders && <MyOrder />}
    </div>)
    : null
  )
}
