import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { 
  OrderDetail, processGetOrders, processGetAllOrders,
  Product, processOrderUpdatePhase } from 'api/API'
import { createSelector } from 'reselect'
import { RootState } from 'app/rootReducer'
import { AppThunk } from 'app/store'

export enum GroupByType {
  NONE, PRODUCT, USER, ADDRESS
}

type OrderState = {
  isLoading: boolean
  orderError: string | null
  orders: OrderDetail[] | null
  adminOrders: OrderDetail[] | null
  groupBy: GroupByType,
  orderMoreDetailId: number | null
}

export type ProductGroupDetail = {
  productId: number
  productName: string
  price: number
}

export type UserGroupDetail = {
  
}

export type AddressGroupDetail = {
  address: string,
}

export type OrderGroup = {
  id: number | string
  groupBy: GroupByType
  groupDetail: ProductGroupDetail | UserGroupDetail | AddressGroupDetail | null
  orders: OrderDetail[]

}

const initialState: OrderState = {
  isLoading: false,
  orderError: null,
  orders: [],
  adminOrders: null,
  groupBy: GroupByType.NONE,
  orderMoreDetailId: null
}

function startLoading(state: OrderState) {
  state.isLoading = true
}

function loadingFailed(state: OrderState, action: PayloadAction<string>) {
  state.isLoading = false
  state.orderError = action.payload
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    orderStart: startLoading,
    orderFailed: loadingFailed,
    setOrders(state, action: PayloadAction<OrderDetail[] | null>) {
      state.orders = action.payload
    },
    setAdminOrders(state, action: PayloadAction<OrderDetail[]>) {
      state.adminOrders = action.payload
    },
    setGroupBy(state, action: PayloadAction<GroupByType>) {
      state.groupBy = action.payload
    },
    setOrderMoreDetailId(state, action: PayloadAction<number | null>) {
      state.orderMoreDetailId = action.payload
    }
  }
})

export const {
  orderStart, orderFailed, setOrders, setAdminOrders, setGroupBy,
  setOrderMoreDetailId
} = orderSlice.actions;

export default orderSlice.reducer;

export const actionFetchOrders = (): AppThunk => async (dispatch) => {
  const orderDetails = await processGetOrders()
  dispatch(setOrders(orderDetails))
}

const productNameSort = (a: OrderGroup, b: OrderGroup) => {
  const aDetail = a.groupDetail as ProductGroupDetail
  const bDetail = b.groupDetail as ProductGroupDetail
  if (aDetail.productName < bDetail.productName) {
    return -1
  } else if (aDetail.productName === bDetail.productName) {
    return 0
  } else {
    return 1
  }
}
export const groupOrderSelector: (state: RootState, phase: string) => OrderGroup[] = createSelector(
  (state: RootState) => state.order.adminOrders,
  (state: RootState) => state.order.groupBy,
  (state: RootState, phase: string) => phase,
  (state: RootState) => state.productList.productEntities,
  (orders, groupBy, phase, products) => {
    // handler for uninitialised products
    if (orders === null) {
      return []
    }
    switch (groupBy) {
      case GroupByType.NONE: {
        let ret: OrderGroup[] = [] 
        for (let order of orders) {
          if (order.phase === phase) {
            let og: OrderGroup = {
              id: order.id,
              groupBy: groupBy,
              groupDetail: null,
              orders: [order]
            }
            ret.push(og)
          }
        }
        return ret
      }
      case GroupByType.PRODUCT: {
        let ret: OrderGroup[] = []
        let productIndex: {[key: string]: OrderDetail[]} = {}

        for (let order of orders) {
          if (order.phase === phase) {
            if (order.product_id in productIndex) {
              productIndex[order.product_id].push(order)
            } else {
              productIndex[order.product_id] = [order]
            }
          }
        }
        for (let id of Object.keys(productIndex)) {
          let productId: number = parseInt(id, 10)
          let product = products[productId]
          if (product) {
            let detail: ProductGroupDetail = {
                productId: product.id,
                productName: product.name,
                price: product.price,
            }
            let og: OrderGroup = {
              id: productId,
              groupBy: groupBy,
              groupDetail: detail,
              orders: productIndex[productId]
            }
            ret.push(og)
          }
        }
        ret.sort(productNameSort)
        return ret
      }
      case GroupByType.ADDRESS: {
        let ret: OrderGroup[] = []
        let addressIndex: {[key: string]: OrderDetail[]} = {}

        for (let order of orders) {
          if (order.phase === phase) {
            let addr = `[${order.shipping_to}] ${order.shipping_address}`
            if (addr in addressIndex) {
              addressIndex[addr].push(order)
            } else {
              addressIndex[addr] = [order]
            }
          }
        }
        for (let address of Object.keys(addressIndex)) {
          if (true) {
            let detail: AddressGroupDetail = {
              address: address
            }
            let og: OrderGroup = {
              id: address,
              groupBy: groupBy,
              groupDetail: detail,
              orders: addressIndex[address]
            }
            ret.push(og)
          }
        }
        ret.sort((a, b) => a.id < b.id ? -1 : Number(a.id > b.id))
        return ret
      }
      default:
        return []
    }
  }
)

export const actionFetchAdminOrders = (): AppThunk => async dispatch => {
  const orderDetails = await processGetAllOrders()
  dispatch(setAdminOrders(orderDetails))
}

export const actionShowOrderMoreDetail = (orderId: number | null): AppThunk => async dispatch => {
  dispatch(setOrderMoreDetailId(orderId))
}

export const actionUpdatePhase = (orderId: number, nextPhase: string): AppThunk => async dispatch => {
  const result = await processOrderUpdatePhase(orderId, nextPhase)
  dispatch(actionFetchAdminOrders())
}
