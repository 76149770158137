import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

//import banner from '../public/banner.png'
import { API_HOST } from 'api/API'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'app/rootReducer'
import favIcon from 'images/fav-filled.svg'
import { actionShowLoginForm, actionSwitchToLoginForm, actionSwitchToSignupForm } from 'features/loginForm/loginFormSlice'
import LoginForm from 'features/loginForm/LoginForm'
import userIcon from 'images/user-icon.svg'
import adminIcon from 'images/admin-icon.svg'
import UserProfile from 'features/user/UserProfile'
import { actionShowUserProfile, actionToggleUserProfile, actionSwitchToFavorite,
  actionSwitchToUserInfo,
  isAdminSelector, actionHideUserProfile } from 'features/user/userSlice'
import { toast } from 'react-toastify'

const Header = () => {
  const dispatch = useDispatch()
  const userState = useSelector((state: RootState) => state.user)
  /*
  <img src={logo} srcSet={logo2x + ' 2x,' + logo + ' 1x'} className="" alt="logo"/>
  */
  const onShowLogin = () => {
    dispatch(actionShowLoginForm())
    dispatch(actionSwitchToLoginForm())
  }
  const onShowLoginWithSignup = () => {
    dispatch(actionShowLoginForm())
    dispatch(actionSwitchToSignupForm())
  }
  const onUserIconClicked = () => {
    if (userState.showMainForm) {
      if (userState.showUserInfo) {
        dispatch(actionHideUserProfile())
      } else {
        dispatch(actionSwitchToUserInfo())
      }
    } else {
        dispatch(actionShowUserProfile())
        dispatch(actionSwitchToUserInfo())

    }
  }
  const onFavIconClicked = () => {
    //dispatch(actionToggleFilterFavorite())
    if (userState.showMainForm) {
      if (userState.showFavorite) {
        dispatch(actionHideUserProfile())
      } else {
        dispatch(actionSwitchToFavorite())
      }
    } else {
      dispatch(actionShowUserProfile())
      dispatch(actionSwitchToFavorite())
    }
  }
  let history = useHistory()
  const onAdminIconClicked = () => {
    const path = location.pathname
		if (/^\/admin/.test(path)) {
      history.push('/')
    } else {
      history.push('/admin/order')
    }
  }
  let location = useLocation()
  const onShowVersion = () => {
    toast(<span>You got me =) <button className="link-text">v3.0</button></span>)
  }
  const isAdmin = useSelector(isAdminSelector)
  //<div className="search-icon"></div>
  return (
    <header className="app-header noselect sticky">
    
      <div className="header-content flex-grid">
          <div className="col-1 col-lg-2">

          </div>
          <div className="header-inner flex-grid col-10 col-lg-8">
            <div className="col-4 col-lg-2 justify-left">
              <Link to="/"><div className="logo"></div></Link>
            </div>
            <div onDoubleClick={onShowVersion} className="col-5 col-lg-8">

            </div>
            <div className="col-3 col-lg-2 justify-right header-right-icons">
                <div className="fav-icon" onClick={onFavIconClicked}><img src={favIcon} alt="fav" width='24px'/><div className="fav-number">{userState?.userDetail?.favorite?.length}</div></div>
                {
                  userState.userDetail ?
                    <>
                      <div className="user-icon" onClick={onUserIconClicked}><img src={API_HOST + '/api/user/avatar/' + userState.userDetail.id || userIcon} alt="user" width='24px'/></div>
                    </>
                    :
                    (<div className="login-join"><button onClick={onShowLogin}>Log In</button> | <button onClick={onShowLoginWithSignup}>Join</button></div>)
                }
                {
                  isAdmin ?
                    <div className="admin-icon" onClick={onAdminIconClicked}><img src={adminIcon} alt="admin" width="24px"/></div>
                    :
                    null
                }
            </div>
          </div>
          <div className="col-1 col-lg-2"></div>
      </div>
      <LoginForm />
      <UserProfile />
    </header>
  )
}
export default Header;
