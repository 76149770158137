import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route, useParams, useHistory
} from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useRef, useLayoutEffect } from 'react'
import { RootState } from './rootReducer'

import './app.scss'
import './user.scss'
import './product.scss'
import './order.scss'
import './admin.scss'

import moment from 'moment'
import 'moment-duration-format'

import Header from '../components/Header'
import About from '../components/About'
import FAQ from '../components/FAQ'
import Contact from '../components/Contact'
import ProductListPage from 'features/productList/ProductListPage'
import ProductDetail from 'features/productList/ProductDetail'
import PaymentForm from 'features/paymentForm/PaymentForm'
import { setJoinDeal, setActiveDealId } from 'features/paymentForm/paymentFormSlice'
import { actionRefreshAuth, setAuthLoaded } from 'features/loginForm/loginFormSlice'
import { isAdminSelector } from 'features/user/userSlice'
import LoginForm from 'features/loginForm/LoginForm'
import { EmailVerified, ForgetPassword, ResetPassword, ResetPasswordSuccess, ForgetPasswordSubmitted } from 'features/user/UserCommunication'
import { toast, ToastContainer } from 'react-toastify'
import { API_HOST, WS_HOST } from 'api/API'
import { setVisibility, actionAcceptCookie, setShowCookie } from 'features/system/systemSlice'
import AdminDashboard from 'features/admin/AdminDashboard'
import 'react-toastify/dist/ReactToastify.css'
import classNames from 'classnames'

import {
  actionFetchProducts, actionFetchProductDetail, selectedProductSelector, setProductDetailImage,
  setPopupJoinDeal
} from 'features/productList/productListSlice'

import { actionGetRunningDeals } from 'features/deal/dealSlice'

const RenderProductDetail = ({ notices, removeNoticesByProduct }: any) => {
  const dispatch = useDispatch()
  const { productUUID } = useParams()
  const { productDetail } = useSelector((state: RootState) => state.productList)
  useEffect(() => {
    if (productDetail === null || productDetail!.uuid !== productUUID) {
      //dispatch(setProductDetailImage(null))
      dispatch(actionFetchProductDetail(productUUID))
    }
  }, [dispatch, productUUID]) // eslint-disable-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (productDetail === null || productDetail!.uuid !== productUUID) {
      console.log('clear product detail image')
      dispatch(setProductDetailImage(null))
    }
  }, [dispatch, productUUID])
  return productDetail && <ProductDetail product={productDetail}
    notices={notices} removeNoticesByProduct={removeNoticesByProduct} />
}

const DealJoiner = () => {
  const dispatch = useDispatch()
  const { runningDealId} = useParams()
  const history = useHistory()
  const join = async () => {
    await dispatch(actionGetRunningDeals())
    await dispatch(setPopupJoinDeal(parseInt(runningDealId, 10)))
    history.push('/')
  }
  useEffect(() => {
    //dispatch(setJoinDeal(runningDealId))
    //dispatch(setActiveDealId(runningDealId))
    join()
  })
  return <div>Hi</div>
}

export default () => {
  const dispatch = useDispatch()

  const { productId } = useSelector(
    (state: RootState) => state.productList
  )
  const selectedProduct = useSelector(selectedProductSelector)

  const { userDetail } = useSelector(
    (state: RootState) => state.user
  )
  const isAdmin = useSelector(isAdminSelector)
  const { showCookie } = useSelector((state: RootState) => state.system)
  //<button className="button-install" onClick={this.handleInstall}>Install</button>
  const { authLoaded } = useSelector((state: RootState) => state.loginForm)

  /* don't care about the SSE event for now
  useEffect(() => {
    if (authLoaded) {
      let evtSource: any = null
      const listenToEvent = () => {
        //if (!isAdmin) {
          evtSource = new EventSource(API_HOST + '/api/event/sse', {withCredentials: true})
          evtSource.onmessage = (e: any) => {
            console.log('event received', e.data)
          }
          evtSource.addEventListener('buy', (e: any) => {
            let data = JSON.parse(e.data)
            toast(`${data.name} from ${data.postal_code} bought ${data.product}`)
          })
        //}
      }
      if (document.visibilityState === 'visible') {
        listenToEvent()
      }

      const handleVisibilityChange = () => {
        dispatch(setVisibility(document.visibilityState))
        if (document.visibilityState === 'hidden') {
          evtSource && evtSource.close()
          evtSource = null
        } else {
          if (evtSource === null) {
            listenToEvent()
          }
        }
      }
      document.addEventListener('visibilitychange', handleVisibilityChange, false)
      // loading of product list could be optimised
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange)
        evtSource && evtSource.close()
        evtSource = null
      }
    }
  }, [dispatch, authLoaded]) // eslint-disable-line react-hooks/exhaustive-deps
  */
  const [notices, setNotices] = useState([] as any)
  const ref = useRef(notices)
  useEffect(() => {
    ref.current = notices
  }, [notices])
  useEffect(() => {
    // load one only on mount
    const cookieAccept = localStorage.getItem('cookie_accept')
    if (!cookieAccept) {
      dispatch(setShowCookie(true))
    }
    if (userDetail === null) {
      const waJWT = localStorage.getItem('waJWT')
      if (waJWT === null) {
        // no JWT available, authLoad for guest
        dispatch(setAuthLoaded(true))
      } else {
        // try refresh user data using jwt
        dispatch(actionRefreshAuth(waJWT))
      }
    }
    dispatch(actionFetchProducts('all'))
    dispatch(actionGetRunningDeals())

		let ws = new WebSocket(`wss://${WS_HOST}/ws`)
		ws.onmessage = function(event: any) {
      let data: any = JSON.parse(event.data)
      if (data.type === 'deal_start' || data.type === 'deal_join') {
        setNotices([...ref.current, data])
        dispatch(actionGetRunningDeals(data.product_id))
      }
		}
    /*
    function sendMessage(event: any) {
				ws.send('hello')
				//event.preventDefault()
		}
    */
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps
  const removeNoticesByProduct = (productId: number) => {
    let old = notices.filter((n: any) => n.product_id !== productId)
    setNotices(old)
  }
  return authLoaded ?
		<div className="app">
			<Router>
				<Header />
				<section className="body">
					<Switch>
						<Route exact path="/email/verified">
							<EmailVerified />
						</Route>
						<Route exact path="/user/forget_password">
							<ForgetPassword />
						</Route>
						<Route exact path="/user/forget_password/submitted">
							<ForgetPasswordSubmitted />
						</Route>
						<Route exact path="/user/reset_password/:email/:pinCode">
							<ResetPassword />
						</Route>
						<Route exact path="/user/reset_password/success">
							<ResetPasswordSuccess />
						</Route>
						<Route path="/admin/:function_?">
              <AdminDashboard />
						</Route>
            <Route exact path="/join_deal/:runningDealId">
              <DealJoiner />
            </Route>
						<Route exact path={['/product/:productUUID']}>
							<RenderProductDetail notices={notices} removeNoticesByProduct={removeNoticesByProduct} />
						</Route>
            <Route exat path="/about">
              <About />
            </Route>
            <Route exat path="/faq">
              <FAQ />
            </Route>
            <Route exat path="/contact">
              <Contact />
            </Route>
						<Route exact path='/'>
							<ProductListPage category="all" notices={notices} removeNoticesByProduct={removeNoticesByProduct} />
						</Route>
					</Switch>
				</section>
				{
          productId &&
					<PaymentForm product={selectedProduct!} />
				}
			</Router>
    <footer className={classNames("footer")}>
      <ul className="footer-links">
        <li><a href="/FAQ">FAQ</a></li>
        <li><a href="/about">About us</a></li>
        <li><a href="/contact">Contact us</a></li>
      </ul>
    </footer>
			<ToastContainer position="bottom-left"/>
      { showCookie &&
        <div className="cookie-prompt">
          <div className="cookie-prompt-content">
            <div className="title">This website uses cookies</div>
            <div className="text">By continuing to browse or by clicking Accept, you agree to the storing of cookies on your device to enhance your site experience and for analytical purpose. To learn more about how we use the cookies, please see our cookies policy.</div>
          </div>
          <div className="cookie-prompt-button">
            <button className="accept" onClick={() => {dispatch(actionAcceptCookie())} }>Accept and Close</button>
          </div>
        </div>
      }
    </div> : null
}

/*
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
        }
    });
*/
