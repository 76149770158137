import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/rootReducer'
import { APIStatus } from 'api/API'
import { createSelector } from 'reselect'
import { AppThunk } from 'app/store'

type State = {
  visibility: string,
  showCookie: boolean,
} 

let initialState: State = {
  visibility: document.visibilityState,
  showCookie: false
}

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setVisibility(state, action: PayloadAction<string>) {
      state.visibility = action.payload
    },
    setShowCookie(state, action: PayloadAction<boolean>) {
      console.log("set show cookie", action.payload)
      state.showCookie = action.payload
    }
  }
})

export const {
  setVisibility, setShowCookie
} = systemSlice.actions;

export default systemSlice.reducer;

export const actionSetVisibility = (visibility: string): AppThunk => async (dispatch) => {
  dispatch(setVisibility(visibility))
}

export const actionAcceptCookie = (): AppThunk => async (dispatch) => {
  dispatch(setShowCookie(false))
  localStorage.setItem('cookie_accept', 'yes')
}
/*
export const favoriteProductSelector: (state: RootState) => Product[] = createSelector(
  (state: RootState) => state.productList.products,
  (state: RootState) => state.user.favorites,
  (products, favorites) => {
    let fav = []
    let lut: { [key: number]: Product } = {}
    for (let p of products) {
      lut[p.id] = p
    }
    for (let pid of favorites) {
      const f = lut[pid]
      if (f) {
        fav.push(f)
      }
    }
    return fav
  })
   */
