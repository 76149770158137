import React, {useEffect, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'app/rootReducer'
import classNames from 'classnames'
import closeIcon from 'images/close.svg'
import { Product, PRODUCT_IMAGE_HOST } from 'api/API'
import { actionGetProducts, actionEditProduct, clearEditingProduct as actionClearEditingProduct,
  activeProductSelector
} from './adminProductSlice'

type IProps = {
  product: Product
}

const ProductListItem = ({ product }: IProps) => {
  const dispatch = useDispatch()
  const onClick = (e: MouseEvent) => {
    dispatch(actionEditProduct(product.id))
  }
  const renderStatus = () => {
    switch (product.status) {
      case 'Draft':
        return <div className="draft">Draft</div>
      case 'Active':
        return <div className="published">Published</div>
      case 'Hidden':
        return <div className="off-shelf">Off Shelf</div>
    }
  }
  return (
    <div className="product-container" onClick={onClick}>
      <div className="product-image">
        <img src={`${PRODUCT_IMAGE_HOST}/product_images/${product.primary_image_uuid}`} className="product-image" alt={product.name} />
      </div>
      <div className="product-detail">
        <div className="product-name">
          {product.name} [{product.id}]
        </div>
        { renderStatus() }
        <div className="product-description">
          {product.description}
        </div>
      </div>

    </div>
  )

}

export default () => {
  const dispatch = useDispatch()

  const state = useSelector((state: RootState) => state.adminProduct)
  useEffect(() => {
    dispatch(actionGetProducts())
  }, [dispatch])
  const onSwitchToNewProduct = () => {
    dispatch(actionClearEditingProduct())
  }
  const filters: any = []
  const onRemoveFilterClicked = () => {}
  let products = useSelector((state: RootState) => activeProductSelector(state))
  return (
    <>
      <section className={classNames('admin-product-list')}>
        <section className="product-filters">
          Filter by: {filters!.map((f: any) =>
            (<div className="filter-item" key={f} onClick={onRemoveFilterClicked}>
                {f}
                <div className="close-btn" ><img alt="Close" src={closeIcon} /></div>
              </div>))
          }
          <div className="add-new-product-button"><button className="action-button action-button-small" onClick={onSwitchToNewProduct}>New Product</button></div>
        </section>

        <section className="product-list">
          { products!.map(p => <ProductListItem product={p} key={p.id} />) }
        </section>
      </section>
    </>
	)
}
