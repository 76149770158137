import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
	actionCreateOrder, actionCapturePayment, discountSelector
} from 'features/paymentForm/paymentFormSlice'
import { getShippingFee } from 'api/API'
import processingLoader from 'images/processing-loader.gif';

const CLIENT = {
    sandbox:
        'AWsxBTE-GjF1KvD0mFsMZyj2XBhiok-Zjs2zBQITqPe-04CdHL8ROgO_KhI9E_AZmhXc_PRZq5v2Cz38',
    production:
        'AZYRoYWXIU9eloDpEaq7Mvim0MhA-LX5YqxlvY6N3RmOS2z-A17NXvNTSyNRnevM8qAUHe_k79nhNEgG',
}
const CLIENT_ID =
    process.env.NODE_ENV === "test" ? CLIENT.production : CLIENT.sandbox;

let PaypalButton = null;

class Paypal extends React.Component {
    state = {
        loading: true,
        isScriptLoaded: false,
        orderId: null,
    }
    constructor(props) {
        super(props)
        window.React = React
        window.ReactDOM = ReactDOM
    }

    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props
        if (isScriptLoaded && isScriptLoadSucceed) {
            PaypalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });
            this.setState({ loading: false, showButtons: true });
        }
    }
    static getDerivedStateFromProps(props, state) {
        const { isScriptLoaded, isScriptLoadSucceed } = props;
        const scriptJustLoaded =
            !state.showButtons && !state.isScriptLoaded && isScriptLoaded;

            if (scriptJustLoaded) {
                if (isScriptLoadSucceed) {
                    PaypalButton = window.paypal.Buttons.driver('react', {
                        React,
                        ReactDOM
                    });
                    return {
                      loading: false,
                      isScriptLoaded: isScriptLoaded,
                      showButtons: true
                    };
                }
            }
        if (isScriptLoaded && !state.isScriptLoaded) {
            return {isScriptLoaded: isScriptLoaded};
        }
        return null;
    }
    render() {
      const { loading } = this.state;
      return (
        <div className={classNames('paypal-component')}>
          {loading && null}
          {
            this.props.show && (
              <div className="paypal-container">
								{ this.state.showButtons &&
									<PaypalButton
										createOrder={(data, actions) => this.createOrder(data, actions)}
										onApprove={(data, actions) => this.onApprove(data, actions)}
										onShippingChange={(data, actions) => this.onShippingChange(data, actions)}
									/>
								}
                { this.state.paid ?
                  <div className="payment-success">
                    <img src={processingLoader} alt="Processing Order" className="processing-loader" />
                  </div>
                  : null
                }
              </div>
            )
          }
        </div>
      )
    }
    createOrder = (data, actions) => {
      const address = this.props.address;
      let value = 0
      let discount = 0
      let item_total = 0
      for (let i of this.props.items) {
        value += (i.price - this.props.discount) * this.props.quantity
        discount += this.props.discount * this.props.quantity
        item_total += i.price * this.props.quantity
      }
      let shipping = 5.0
      return actions.order.create({
        purchase_units: [{
          description: `Payment for items sold`,
          amount: {
            currency_code: "AUD",
            value: value + shipping,
            breakdown: {
              item_total: {
                currency_code: 'AUD',
                value: item_total
              },
              discount: {
                currency_code: 'AUD',
                value: discount.toFixed(2)
              },
              shipping: {
                currency_code: 'AUD',
                value: shipping
              },
              shipping_discount: {
                currency_code: 'AUD',
                value: '0.00'
              }
            }
          },
          items: this.props.items.map(i => {
            return {
              name: i.name,
              description: `${i.name} X ${this.props.quantity}`,
              sku: i.uuid,
              unit_amount: {
                currency_code: 'AUD',
                value: i.price,
              },
              quantity: this.props.quantity,
              category: "PHYSICAL_GOODS"
            }
          }),
          shipping: {
            address: {
              address_line_1: address.line1,
              address_line_2: address.line2,
              admin_area_2: address.admin_area_2,
              admin_area_1: address.admin_area_1,
              postal_code: address.postal_code,
              country_code: address.country_code
            }
          },
          //reference_id: '1901'
        }]
      });
    };

    onApprove = (data, actions) => {
      actions.order.authorize().then(authorization => {
        const authID = authorization.purchase_units[0]
          .payments.authorizations[0].id
        this.setState({ showButtons: false, paid: true })
        return this.props.actionCapturePayment(data.orderID, authID)
      })
      /*
        actions.order.capture().then(details => {
            const paymentData = {
                payerID: data.payerID,
                orderID: data.orderID
            };
            this.setState({ showButtons: false, paid: true });
            this.props.completePayment()
        });
        */
    };
    onShippingChange = (data, actions) => {
      if (data.shipping_address.country_code !== 'AU') {
        return actions.reject();
      }
			const prevAmount = data.amount.breakdown.item_total.value;
			const discountAmount = data.amount.breakdown.discount ? data.amount.breakdown.discount.value : 0;
      return getShippingFee(data.shipping_address.postal_code)
      	.then(res => {
					if (!res || res.error) {
							return actions.reject();
					}
					const shippingAmount = res.fee
					return actions.order.patch([{
						op: 'replace',
						path: '/purchase_units/@reference_id==\'default\'/amount',
						value: {
								currency_code: 'AUD',
								value: (parseFloat(prevAmount) - parseFloat(discountAmount) + parseFloat(shippingAmount)).toFixed(2),
								breakdown: {
										item_total: data.amount.breakdown.item_total,
                    discount: data.amount.breakdown.discount,
										shipping: {
												currency_code: 'AUD',
												value: shippingAmount
										},
										shipping_discount: {
												currency_code: 'AUD',
												value: '0.00'

										}
								}
						}
					}]);
        });
			}
}
const mapStateToProps = state => ({ productList: state.productList, user: state.user,
                                  paymentForm: state.paymentForm, discount: discountSelector(state, state.productList.productId) })
const mapDispatchToProps = { actionCapturePayment, actionCreateOrder };

export default scriptLoader(`https://www.paypal.com/sdk/js?currency=AUD&client-id=${CLIENT_ID}&intent=authorize`)(connect(mapStateToProps, mapDispatchToProps)(Paypal));
