import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/rootReducer'
import { UserDetail, addFavorite as APIAddFavorite,
  removeFavorite as APIRemoveFavorite, Product, Address,
  processRefreshUserInfo, processGetPostalCodeInfo,
  PostalCodeInfo, processGetAddresses, processCreateAddress,
  processEditAddress, processMakeAddressPrimary, processResetPassword,
  processSendResetMail, processResendVerificationEmail, processDeleteAddress,
  processEditPassword, APIStatus, processContact
} from 'api/API'
import { actionSetDeliveryAddressId, actionProceedToDetail, Stages } from 'features/paymentForm/paymentFormSlice'
import { createSelector } from 'reselect'
import { AppThunk } from 'app/store'

type FormActiveState = {
  mainFormActive?: boolean
  userInfoActive?: boolean
  favoriteActive?: boolean
  ordersActive?: boolean
  addressDetailActive?: boolean
}

type ShowState = {
	showMainForm?: boolean
  showUserInfo?: boolean
  showFavorite?: boolean
  showOrders?: boolean
  showAddressDetail?: boolean
}

type UserState = {
  isLoading: boolean
  userError: string | null
  userDetail: UserDetail | null
  favorites: number[]
  addresses: Address[]
  postalCodeInfo: PostalCodeInfo | null
  currentEditAddress: Address | null
  isEditingPassword:  boolean
} & FormActiveState & ShowState

let initialState: UserState = {
  isLoading: false,
  userError: null,
  userDetail: null,
  mainFormActive: false,
  userInfoActive: true,
  favoriteActive: false,
  ordersActive: false,
  addressDetailActive: false,
  showMainForm: false,
  showUserInfo: true,
  showFavorite: false,
  showOrders: false,
  showAddressDetail: false,
  addresses: [],
  favorites: [],
  postalCodeInfo: null,
  currentEditAddress: null,
  isEditingPassword: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setDetail(state, action: PayloadAction<UserDetail | null>) {
      state.userDetail = action.payload
      if (state.userDetail !== null) {
        const productList = action.payload?.favorite || []
        state.favorites = productList
      }
    },
    setActive(state, action: PayloadAction<FormActiveState>) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key as keyof FormActiveState] = value
      }
    },
    setShow(state, action: PayloadAction<ShowState>) {
      const p = action.payload
      for (const [key, value] of Object.entries(p)) {
        state[key as keyof ShowState] = value
      }
    },
    setFavorites(state, action: PayloadAction<number[]>) {
      state.favorites = action.payload
    },
    setPostalCodeInfo(state, action: PayloadAction<PostalCodeInfo>) {
      state.postalCodeInfo = action.payload
    },
    setAddresses(state, action: PayloadAction<Address[]>) {
      state.addresses = action.payload
    },
    setCurrentEditAddress(state, action: PayloadAction<Address | null>) {
      state.currentEditAddress = action.payload
    },
    setIsEditingPassword(state, action: PayloadAction<boolean>) {
      state.isEditingPassword = action.payload
    }
  }
})

export const {
  setDetail,
  setActive,
  setShow,
  setFavorites,
  setPostalCodeInfo, setAddresses, setCurrentEditAddress,
  setIsEditingPassword
} = userSlice.actions;

export default userSlice.reducer;

export const actionShowUserProfile = (): AppThunk => async (dispatch) => {
  dispatch(setShow({showMainForm: true}))
  window.setTimeout(() => dispatch(setActive({mainFormActive: true})), 1)
}

export const actionHideUserProfile = (): AppThunk => async (dispatch) => {
  dispatch(setActive({
    mainFormActive: false,
  }))
  window.setTimeout(() => dispatch(setShow({showMainForm: false})), 100)
}

export const actionToggleUserProfile = (): AppThunk => async (dispatch, getState) => {
  const state = getState().user
  if (!state.showMainForm) {
    dispatch(setShow({showMainForm: true}))
    window.setTimeout(() => dispatch(setActive({mainFormActive: true})), 1)
  } else {
    dispatch(setActive({mainFormActive: false}))
    window.setTimeout(() => dispatch(setShow({showMainForm: false})), 100)
  }
}

export const actionSwitchToUserInfo = (): AppThunk => async (dispatch) => {
  dispatch(setShow({
    showUserInfo: true,
    showFavorite: false,
    showOrders: false,
    showAddressDetail: false,
  }))
  dispatch(setActive({
    userInfoActive: true,
    favoriteActive: false,
    ordersActive: false,
    addressDetailActive: false,
  }))
}

export const actionSwitchToFavorite = (): AppThunk => async (dispatch) => {
  dispatch(setShow({
    showUserInfo: false,
    showFavorite: true,
    showOrders: false,
    showAddressDetail: false,
  }))
  dispatch(setActive({
    userInfoActive: false,
    favoriteActive: true,
    ordersActive: false,
    addressDetailActive: false,
  }))
}

export const actionSwitchToOrders = (): AppThunk => async (dispatch) => {
  dispatch(setShow({
    showUserInfo: false,
    showFavorite: false,
    showOrders: true,
    showAddressDetail: false,
  }))
  dispatch(setActive({
    userInfoActive: false,
    favoriteActive: false,
    ordersActive: true,
    addressDetailActive: false,
  }))
}

export const actionSwitchToAddressDetail = (): AppThunk => async (dispatch) => {
  dispatch(setShow({
    showUserInfo: false,
    showFavorite: false,
    showOrders: false,
    showAddressDetail: true,
  }))
  dispatch(setActive({
    userInfoActive: false,
    favoriteActive: false,
    ordersActive: false,
    addressDetailActive: true,
  }))
}

export const actionAddFavorite = (productId: number): AppThunk => async (dispatch, getState) => {
  console.log('add', productId)
  const isLoggedIn = isLoggedInSelector(getState())

  if (isLoggedIn) {
    const userDetail = await APIAddFavorite(productId)
    dispatch(setDetail(userDetail))
  } else {
    let fav = getState().user.favorites
    dispatch(setFavorites([...fav, productId]))
  }
}

export const actionRemoveFavorite = (productId: number): AppThunk => async (dispatch, getState) => {
  const isLoggedIn = isLoggedInSelector(getState())

  if (isLoggedIn) {
    const userDetail = await APIRemoveFavorite(productId)
    dispatch(setDetail(userDetail))
  } else {
    let fav = getState().user.favorites
    fav = fav.filter(v => v !== productId)
    dispatch(setFavorites(fav))
  }
}

export const actionRefreshUserInfo = (): AppThunk => async (dispatch) => {
  const userDetail = await processRefreshUserInfo()
  dispatch(setDetail(userDetail))
}

export const actionGetAddresses = (): AppThunk => async (dispatch) => {
  const addresses = await processGetAddresses()
  dispatch(setAddresses(addresses))
}

export const actionPrefillAddress = (postal_code: string): AppThunk => async (dispatch) => {
  const info = await processGetPostalCodeInfo(postal_code)
  dispatch(setPostalCodeInfo(info))
}

export const actionCreateAddress = (data: object, makePrimary: boolean = false): AppThunk => async (dispatch, getState) => {
  const info = await processCreateAddress(data, makePrimary)
  //const deliveryAddressId = getState().paymentForm.deliveryAddressId
  const stage = getState().paymentForm.stage
  await dispatch(actionRefreshUserInfo())
  await dispatch(actionGetAddresses())
  if (stage !== Stages.INACTIVE) {
    await dispatch(actionSetDeliveryAddressId(info.id as number))
    dispatch(actionProceedToDetail())
  }
  dispatch(setCurrentEditAddress(null))
}

export const actionEditAddress = (addressId: number): AppThunk => async (dispatch, getState) => {
  const state = getState().user
  const paymentForm = getState().paymentForm
  const addr = state.addresses.find(a => a.id === addressId)
  if (addr) {
    dispatch(setCurrentEditAddress(addr))
  } else {
    if (paymentForm.guestAddress !== null) {
      dispatch(setCurrentEditAddress(paymentForm.guestAddress))
    }
  }
}

export const actionUpdateAddress = (data: any, guestJWT?: string | null): AppThunk => async (dispatch, getState) => {
  await processEditAddress(data, guestJWT)
  // if no guest JWT is provided, thus there should be valid login
  if (guestJWT === undefined) {
    dispatch(actionGetAddresses())
  }
  dispatch(setCurrentEditAddress(null))
}

export const actionMakeAddressPrimary = (addressId: number): AppThunk => async (dispatch) => {
  await processMakeAddressPrimary(addressId)
  await dispatch(actionRefreshUserInfo())
  dispatch(setCurrentEditAddress(null))
}

export const actionResetPassword = (userId: string, pinCode: string, password: string): AppThunk => async (dispatch) => {
  processResetPassword(userId, pinCode, password)
}

export const actionDeleteAddress = (addressId: number): AppThunk => async (dispatch) => {
  await processDeleteAddress(addressId)
  await dispatch(actionRefreshUserInfo())
  dispatch(actionGetAddresses())

}

export const actionSendResetMail = (email: string): AppThunk => async (dispatch) => {
  try {
    await processSendResetMail(email)
  } 
  catch (err) {
    console.log('err', err)
  }
}

export const actionResendVerification = (): AppThunk => async (dispatch) => {
  try {
    await processResendVerificationEmail()
  } 
  catch (err) {
    console.log('err', err)
  }
}
export const actionEditNewAddress = (): AppThunk => async (dispatch) => {
  dispatch(setCurrentEditAddress({
    id: null, first_name: '', last_name: '', phone: '', line1: '', line2: '',
    admin_area_1: '', admin_area_2: '', country_code: 'AU', postal_code: ''
  }))
} 

export const primaryAddressSelector: (state: RootState) => Address | null = createSelector(
  (state: RootState) => state.user.addresses,
  (state: RootState) => state.user.userDetail?.primaryAddressId,
  (addresses, primaryAddressId) => addresses.find((addr: Address) => addr.id === primaryAddressId) || null
)

export const actionEditPassword = (oldPassword: string, newPassword: string): AppThunk => async (dispatch): Promise<APIStatus> => {
  const res = await processEditPassword(oldPassword, newPassword)
  return res
}

export const favoriteProductSelector: (state: RootState) => Product[] = createSelector(
  (state: RootState) => state.productList.productEntities,
  (state: RootState) => state.user.favorites,
  (products, favorites) => {
    /*
    let fav = []
    let lut: { [key: number]: Product } = {}
    for (let p of products) {
      lut[p.id] = p
    }
    for (let pid of favorites) {
      const f = lut[pid]
      if (f) {
        fav.push(f)
      }
    }
    return fav
     */
    let fav = []
    for (let productId of favorites) {
      let p = products[productId]
      if (p) {
        fav.push(p)
      }
    }
    return fav
  })

export const isLoggedInSelector: (state: RootState) => boolean = createSelector(
  (state: RootState) => state.user,
  (user) => user.userDetail !== null
)
export const isAdminSelector: (state: RootState) => boolean = createSelector(
  (state: RootState) => state.user.userDetail,
  (userDetail) => (userDetail && userDetail.principles?.includes('admin')) || false
)

export const actionFetchFavorites = (): AppThunk => async (dispatch, getState) => {
  try {
    //dispatch(loginStart())
    const isLoggedIn = isLoggedInSelector(getState())
    if (isLoggedIn) {
      const userDetail = await processRefreshUserInfo()
      const productList = userDetail.favorite
      dispatch(setFavorites(productList))
    } else {
      
    }
    //dispatch(loginSuccess())
  } catch (err) {
    //dispatch(loginFailure(err.response.data))
  }
}

export const actionContact = (data: any): AppThunk => async (dispatch) => {
  try {
      const status = await processContact(data)
      return status.reference
  } catch (err) {
  }
}
