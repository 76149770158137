import React, {useEffect, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  actionCancelPayment, actionProceedToLogin, actionProceedToDetail,
  actionProceedToGuest, 
  actionSetDeliveryAddressId,
  Stages, actionCreateOrder, actionShowPaymentMethods,
  setJoinDeal
} from './paymentFormSlice'
import {
  actionSwitchToSignupForm
} from 'features/loginForm/loginFormSlice'
import { Product } from 'api/API'
import {
  actionRefreshUserInfo, actionGetAddresses,
} from 'features/user/userSlice'
import { actionGetRunningDeals } from '../deal/dealSlice'
import { RootState } from 'app/rootReducer'
import classNames from 'classnames'
import PaymentSidePanel from './PaymentSidePanel'
import OrderSummary from './OrderSummary'

export default ({ product }: {product?: Product}) => {
  const dispatch = useDispatch()

  const {
    quantity, stage, deliveryAddressId, joinDeal, activeDealId
  } = useSelector((state: RootState) => state.paymentForm)

  const { userDetail } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    /*
		let ua = window.navigator.userAgent;
		let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
		let webkit = !!ua.match(/WebKit/i);
		let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);    
		if (iOSSafari) {
		}
    */
    document.body.classList.add('no-scroll');
    (async () => {
      await dispatch(actionRefreshUserInfo())
      await dispatch(actionGetAddresses())
      await dispatch(actionGetRunningDeals())
      /* if delivery address is empty and user is not guest */
      if (deliveryAddressId === null && userDetail !== null) {
        await dispatch(actionSetDeliveryAddressId(userDetail.primaryAddressId))
      }
    })()
		return () => {
			document.body.classList.remove('no-scroll');
			dispatch(actionSetDeliveryAddressId(null))
		}
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  const onGuestCheckoutClicked = (e: MouseEvent) => {
    dispatch(actionProceedToGuest())
  }
  const onLoginClicked = (e: MouseEvent) => {
    dispatch(actionProceedToLogin())
  }
  const onCreateAccountClicked = (e: MouseEvent) => {
    dispatch(actionProceedToLogin())
    dispatch(actionSwitchToSignupForm())
  }

  const onProceedToPayment = async () => {
    await dispatch(actionCreateOrder([product!], quantity, deliveryAddressId!))
    if (deliveryAddressId !== null) {
      await dispatch(actionShowPaymentMethods())
    }
    dispatch(actionProceedToDetail())
  }
  const onProceedToPaymentWithOwnDeal = async () => {
    dispatch(actionProceedToDetail())
    await dispatch(setJoinDeal(null))
    await dispatch(actionCreateOrder([product!], quantity, deliveryAddressId!))
    if (deliveryAddressId !== null) {
      await dispatch(actionShowPaymentMethods())
    }
  }
  const onWrapClicked = (e: MouseEvent) => {
    if (e.target === e.currentTarget) {
      dispatch(actionCancelPayment())
    }
  }
  return (
    <>
      <section className={classNames(['payment-overlay', 'noselect'], {hidden: stage === Stages.LOGIN})} onClick={onWrapClicked}>
        <div className={classNames('payment-bottom-panel selectable', {active: stage === Stages.SETUP || stage === Stages.LOGIN})} >
          {product && <OrderSummary product={product} />}
          {stage === Stages.SETUP && userDetail === null && 
            <>
              <button className="action-button" type="button" onClick={onLoginClicked}>Login to Checkout</button>

              <div className="link-wrap">
                No account? Join us by <button className="link-text" onClick={onCreateAccountClicked} >Create one</button>
              </div>

            </>
          }
          {stage === Stages.SETUP && userDetail === null && (<button className={classNames('action-button', {disabled: activeDealId})} disabled={activeDealId !== null} type="button" onClick={onGuestCheckoutClicked}>Checkout as guest</button>)}
          {stage === Stages.SETUP && userDetail !== null && (!joinDeal) && (activeDealId) && (<button className="action-button" type="button" onClick={onProceedToPayment}>Checkout and Open a wowDeal</button>)}
          {stage === Stages.SETUP && userDetail !== null && (!joinDeal) && (!activeDealId) && (<button className="action-button" type="button" onClick={onProceedToPayment}>Proceed to Payment</button>)}
          {stage === Stages.SETUP && userDetail !== null && (joinDeal) && (<button className="action-button" type="button" onClick={onProceedToPaymentWithOwnDeal}>Open your own wowDeal</button>)}
        </div>
      </section>
      {product && <PaymentSidePanel product={product} />}
    </>
  )
}
