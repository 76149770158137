import React from 'react'
import favIcon from 'images/fav-filled.svg'
import userIcon from 'images/user-icon.svg'
import adminIcon from 'images/admin-icon.svg'
import UserProfile from 'features/user/UserProfile'
import { toast } from 'react-toastify'

const FAQ = () => {
  //const dispatch = useDispatch()
  return (
    <div className="faq-page">

      <section>
        <h4>Create an Account:</h4>
        <p>Having an account with WOWHomestuff is free and simple. Click the “Join” button on the right
  corner of the website.</p>
        <p>With an account, you could:</p>
        <ul>
          <li>Buy at a deal price</li>
          <li>Track your order</li>
          <li>Check out faster with your saved details</li>
          <li>Save your favourite items</li>
        </ul>
      </section>

      <section>
        <h4>Guest Checkout</h4>
        <p>You can purchase any of our products on desktop and mobile website as a guest.</p>
        <p>Guest check out allows you to search and buy at full price. You will be prompted to enter your email address for order confirmation, as well as your address details for delivery.</p>
        <p>Please note:<br/>
          To buy at a deal price, join other’s deal or track your order, you need to create an account.
        </p>
      </section>

      <section>
        <h4>How do I place an order on WOWHomestuff?</h4>

        <p>To place an order, the best way is to sign in or sign up and create a WOWHomestuff account. By creating an account, you can buy at a deal price, track orders, check out faster with your saved details and save your favourite items.</p>
        <p>Click on the video for more information below:</p>
      </section>

      <section>
        <h4>Is there a limit on how many items I can purchase?</h4>
        <p>All our products are carefully picked and tried before listed by our professional buyer team. The maximum quantities per order is 2. This is to ensure that all of our customers can take advantage of the savings made on our site.</p>
      </section>

      <section>
        <h4>How can I pay for my order?</h4>
        <p>Currently, WOWHomestuff accepts payment via Credit Card, PayPal, or Royal Pay (Alipay and WeChat Pay)</p>
      </section>

      <section>
        <h4>How is my delivery costs calculated?</h4>
        <p>You will only be charged one delivery fee per order. This is calculated based on the quantity and
weight of the items you have purchased, and your postcode.</p>
        <p>Unfortunately, we cannot combine multiple orders for savings on postage.</p>
        <p>Freight chart attached here.</p>
      </section>

      <section>
        <h4>Do you deliver to an oversea address?</h4>
        <p>Unfortunately, we are only delivering within Australia.</p>
      </section>

      <section>
        <h4>Can I cancel, update or edit my order?</h4>
        <p>Unfortunately, once an order is placed, we can not cancel, update or amend it.</p>
        <p>In the event you have made a mistake for your address details, you should contact us</p>
        <p>immediately. Before the order is dispatched, we may update it for you. Otherwise, it will be delivered to the address you submitted.</p>
      </section>

      <section>
        <h4>What is my order status?</h4>
        <p>Deal Price Guaranteed: We have received your order request along with your payment. The items will be reserved for you at the deal price, but your order will only be dispatched after someone joins your deal. The deal will expire in 3 days, so you are encouraged to share your deal with your friends or on your social network.</p>
        <p>Order Confirmed: We have received your order request along with your payment and your order should be processed shortly. This means that
          <ul>
            <li>Your deal has been successfully joined by others, and you both can enjoy the deal price.</li>
            <li>You joined other’s deal with your payment, and you both can enjoy the deal price</li>
            <li>You made a full price order along with your payment</li>
            <li>Dispatched: Your order has been dispatched with the tracking number (if applicable) that has
been emailed to you.</li>
            <li>Order Completed: Your order has been delivered by Australia Post or our freight partners.</li>
          </ul>
        </p>

        <p>For in-stock items, we aim to get your order packed and dispatched within 1-2 business days; however, be aware that, in some cases orders can take 15 or more days to be received.</p>
      </section>

      <section>
        <h4>Missing Item/Product</h4>
        <p>If you require assistance, please let us know by following this link and filling the form. Our staff will be happy to investigate with the courier company to track down your parcel. The investigation process can take up to 14 days to resolve. Once our team receives an outcome, we&#39;ll be in contact with you immediately with a resolution.</p>
      </section>

      <section>
        <h4>What do I do if my item is faulty or damaged?</h4>
        <p>We apologise if you have received an item that is faulty or damaged. We are always here to help.</p>
        <p>Please let us know by following <a href="/contact" target="_blank">this link</a> and filling the form. Our Team will review your claim within 2 business days and respond with further instructions. Please keep an eye on your inbox and/or junk inbox.</p>
        <p>You will need to place items in the original box and send to us for assessment within 14 days from the date we send you the instruction. Once your item is received at our warehouse, it will be inspected by our Team. Please allow up to 2 business days from the date that we receive your return parcel from Australia Post.</p>
        <p>If your item works or it is undamaged, it may be sent back to you with no refund provided.</p>
        <p>If your item is deemed damaged or faulty, you will be entitled to a refund. And we may refund your return delivery costs in the form of store credits.</p>
        <p>Please note the following:
          <ul>
            <li>Damage during shipping or items lost in transit is at the customer’s risk.</li>
            <li>We recommend paying for tracking or product insurance.</li>
            <li>We cannot accept change of mind returns for items that come in sealed packages or boxes where seals are damaged or broken. Any products returned without proper sealed packaging will be rejected with no refund provided.</li>
          </ul>
        </p>
      </section>

    </div>
  )
}

/*



    </div>
  )
}
*/
export default FAQ;
