import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/rootReducer'
import { capturePayment as capturePaymentAPI, PaymentDetail, Product, processCreateOrder,
  OrderDetail, getShippingFee as getShippingFeeAPI, Shipping, Address,
  processCreateGuestAddress, processReviseOrderAddress,
  processEditAddress, processCancelOrder, processGetDeals,
  Deal, getRunningDeals, processRoyalPay, processRoyalPayQR
} from 'api/API'
import { AppThunk } from 'app/store'
import { clearProductSelection, selectedProductSelector } from 'features/productList/productListSlice'
import { actionShowLoginForm } from 'features/loginForm/loginFormSlice'
import { actionEditNewAddress, setCurrentEditAddress } from 'features/user/userSlice'
import { createSelector } from 'reselect'

export enum Stages {
  INACTIVE = 1,
  SETUP,
  DETAIL,
  LOGIN,
  GUEST,
  COMPLETE,
  ADDRESS
}

type PaymentFormState = {
  isLoading: boolean
  asGuest: boolean
  paymentProcessing: boolean
  paymentDetail: PaymentDetail | null
  showPaymentMethods: boolean
  paymentError: string | null
  quantity: number
  shippingFee: number | null
  suburbs: string[]
  stage: Stages
  orderId: number | null
  deliveryAddressId: number | null
  guestAddress: Address
  guestJWT: string | null
  activeDealId: number | null 
  joinDeal: number | null
  joiningRunning: number | null
}
let initialState: PaymentFormState = {
  isLoading: false,
  asGuest: false,
  paymentProcessing: false,
  paymentDetail: null,
  showPaymentMethods: false,
  paymentError: null,
  quantity: 1,
  shippingFee: null,
  suburbs: [],
  stage: Stages.INACTIVE,
  orderId: null,
  deliveryAddressId: null,
  guestAddress: {} as Address,
  guestJWT: null,
  activeDealId: null,
  joinDeal: null,
  joiningRunning: null,
}

function startLoading(state: PaymentFormState) {
  state.isLoading = true
}

function loadingFailed(state: PaymentFormState, action: PayloadAction<string>) {
  state.isLoading = false
  state.paymentError = action.payload
}
const paymentFormSlice = createSlice({
  name: 'paymentForm',
  initialState,
  reducers: {
    completePayment(state) {
      state.quantity = 0
    },
    updateQuantity(state, action: PayloadAction<number>) {
      state.quantity = action.payload
    },

    capturePaymentStart: startLoading,
    capturePaymentSuccess(state, action: PayloadAction<PaymentDetail>) {
      state.paymentDetail = action.payload
    },
    capturePaymentFailure: loadingFailed,
    
    createOrderStart: startLoading,
    createOrderSuccess(state, action: PayloadAction<OrderDetail>) {
      state.orderId = action.payload.id as number
    },
    createOrderFailure: loadingFailed,
    actionShowPaymentMethods(state) {
      state.showPaymentMethods = true
    },
    actionHidePaymentMethods(state) {
      state.showPaymentMethods = false
    },
    setStage(state, action: PayloadAction<Stages>) {
      state.stage = action.payload
    },
    setGuestState(state, action: PayloadAction<boolean>) {
      state.asGuest = action.payload
    },
    setShippingStart: startLoading,
    setShippingSuccess(state, action: PayloadAction<Shipping>) {
      state.shippingFee = action.payload.fee
      state.suburbs = action.payload.suburbs
    },
    setShippingFailure: loadingFailed,
    resetForm(state) {
      state.suburbs = []
      state.shippingFee = null
      state.asGuest = false
      state.paymentDetail = null
      state.showPaymentMethods = false
      state.paymentError = null
      state.quantity = 1
      state.orderId = null
    },
    setDeliveryAddressId(state, action: PayloadAction<number | null>) {
      state.deliveryAddressId = action.payload
    },
    setGuestAddress(state, action: PayloadAction<Address>) {
      state.guestAddress = action.payload
    },
    setGuestJWT(state, action: PayloadAction<string | null>) {
      state.guestJWT = action.payload
    },
    setOrderId(state, action: PayloadAction<number | null>) {
      state.orderId = action.payload
    },
    setActiveDealId(state, action: PayloadAction<number | null>) {
      state.activeDealId = action.payload
    },
    setJoinDeal(state, action: PayloadAction<number | null>) {
      state.joinDeal = action.payload
    },
    setJoiningRunning(state, action: PayloadAction<number | null>) {
      state.joiningRunning = action.payload
    }
  }
})

export const {
  completePayment,
  updateQuantity,
  capturePaymentStart, capturePaymentSuccess, capturePaymentFailure,
  createOrderStart, createOrderSuccess, createOrderFailure,
  actionShowPaymentMethods, actionHidePaymentMethods,
  setStage,
  setGuestState,
  setShippingStart, setShippingSuccess, setShippingFailure,
  resetForm, setDeliveryAddressId, setGuestAddress, setGuestJWT,
  setOrderId, setActiveDealId, setJoinDeal, setJoiningRunning
} = paymentFormSlice.actions;

export default paymentFormSlice.reducer;

export const actionCapturePayment = (orderID: string, authorizationID: string) : AppThunk => async (dispatch, getState) => {
  try {
    const paymentState = getState().paymentForm
    dispatch(capturePaymentStart())
    const paymentDetail = await capturePaymentAPI(orderID, authorizationID, paymentState.orderId as number)

    dispatch(capturePaymentSuccess(paymentDetail))
    dispatch(updateQuantity(1))
    /*
    if (paymentState.asGuest) {
      dispatch(setGuestJWT(null))
      dispatch(setGuestAddress({} as Address))
    }
     */
    dispatch(setStage(Stages.COMPLETE))
  } catch (err) {
    dispatch(capturePaymentFailure(err.toString()))
  }
}

export const actionSetupPayment = (product: Product, joinDeal: number | null): AppThunk => async dispatch => {
  dispatch(setStage(Stages.SETUP))
  dispatch(setJoinDeal(joinDeal))
  if (joinDeal !== null) {
    dispatch(setActiveDealId(joinDeal))
  }
  window.setTimeout(() => {
  }, 200)
}

export const actionCancelPayment = (): AppThunk => async (dispatch, getState) => {
  const state = getState().paymentForm
  // if state.orderId is a number (not Not A Number)
  if (typeof state.orderId === 'number') {
    dispatch(actionCancelOrder(state.orderId as number, state.guestJWT))
  }
  dispatch(updateQuantity(1))
  dispatch(setStage(Stages.INACTIVE))
  dispatch(setActiveDealId(null))
  dispatch(setGuestState(false))
  //dispatch(setJoiningRunning(null))
  window.setTimeout(() => {
    dispatch(clearProductSelection())
  }, 200)
}

export const actionProceedToLogin = (): AppThunk => async dispatch => {
  await dispatch(setStage(Stages.LOGIN))
  dispatch(actionShowLoginForm('payment'))
}

export const actionProceedToDetail = (): AppThunk => async (dispatch, getState) => {
	const userDetail = getState().user.userDetail
  if (userDetail) {
    dispatch(setGuestState(false))
  } else {
    dispatch(setGuestState(true))
  }
  dispatch(setStage(Stages.DETAIL))
  //dispatch(showPaymentMethods())
}
export const actionProceedToSetup = (): AppThunk => async (dispatch, getState) => {
  dispatch(actionHidePaymentMethods())
  dispatch(setStage(Stages.SETUP))
}

export const actionProceedToGuest = (): AppThunk => async (dispatch, getState) => {
  await dispatch(setGuestState(true))

  const state = getState().paymentForm
  const product = selectedProductSelector(getState())
  if (state.guestAddress?.id) {
    const addrId = state.guestAddress.id
    // if we already cached guest address info, then set delivery address to it
    dispatch(actionSetDeliveryAddressId(addrId))
    // create a new order for this item
    dispatch(actionCreateOrder([product!], state.quantity, addrId))
  } else {
    // otherwise hide payment methods and create a new address for them to fill
    dispatch(actionEditNewAddress())
    dispatch(actionHidePaymentMethods())
  }

  dispatch(setStage(Stages.DETAIL))

}

export const actionProceedToSelectAddress = (): AppThunk => async dispatch => {
  dispatch(setStage(Stages.ADDRESS))
}

export const actionHandlePostLoginSuccess = (): AppThunk => async (dispatch, getState) => {
  const state = getState().paymentForm
  const productList = getState().productList
  const product = productList.productEntities[productList.productId!]
  dispatch(actionCreateOrder([product], state.quantity, state.deliveryAddressId as number))
  dispatch(actionShowPaymentMethods())
  dispatch(actionProceedToDetail())
}

export const actionCreateOrder = (
  products: Product[], quantity: number, addressId: number
): AppThunk => async (dispatch, getState) => {
  const state = getState().paymentForm
  try {
    dispatch(createOrderStart())
    const orderDetail = await processCreateOrder(
      products, quantity, addressId, 
      state.activeDealId, state.joiningRunning)
    dispatch(createOrderSuccess(orderDetail))
  } catch (err) {
    dispatch(createOrderFailure(err.toString()))
  }
  
}

export const actionSetShipping = (postcode: string): AppThunk => async dispatch => {
  try {
    dispatch(setShippingStart())
    const shipping = await getShippingFeeAPI(postcode)
    dispatch(setShippingSuccess(shipping))
  } catch (err) {
    dispatch(setShippingFailure(err.toString()))
  }
}

export const actionContinueShopping = (): AppThunk => async dispatch => {
  dispatch(setStage(Stages.INACTIVE))
  dispatch(setActiveDealId(null))
  dispatch(resetForm())
  dispatch(setJoiningRunning(null))
  window.setTimeout(() => {
    dispatch(clearProductSelection())
  }, 100)
}

export const actionSetDeliveryAddressId = (addressId: number | null): AppThunk => async (dispatch, getState) => {
  if (addressId !== null) {
    const { addresses } = getState().user
    const { orderId } = getState().paymentForm
    const addr = addresses.find(addr => addr.id === addressId)
    if (addr) {
      await dispatch(actionSetShipping(addr.postal_code))
      if (orderId !== null) {
        await dispatch(actionReviseOrderAddress(orderId, addr.id as number))
      }
    }
  } 
  dispatch(setDeliveryAddressId(addressId))
}

export const actionCreateGuestAddress = (data: any): AppThunk<Promise<Address | undefined>> => async dispatch => {
  try {
    //dispatch(createOrderStart())
    const { user, address } = await processCreateGuestAddress(data)
    await dispatch(setGuestAddress(address))
    dispatch(setGuestJWT(user.jwt as string))
    dispatch(setDeliveryAddressId(address.id))
    return address as Address
    //dispatch(
  } catch (err) {
    //dispatch (createOrderFailure(err.toString()))
  }
}

export const actionUpdateGuestAddress = (data: any, guestJWT?: string | null): AppThunk<Promise<Address | undefined>> => async dispatch => {
  try {
    const address = await processEditAddress(data, guestJWT)
    await dispatch(setGuestAddress(address))
    dispatch(setCurrentEditAddress(null))
    return address
  } catch (err) {
  }
}

export const actionReviseOrderAddress = (orderId: number, addressId: number, guestJWT?: string | null): AppThunk => async dispatch => {
  try {
    await processReviseOrderAddress(orderId, addressId, guestJWT)
  } catch (err) {
  }
}

export const actionCancelOrder = (orderId: number, guestJWT?: string | null): AppThunk => async dispatch => {
  try {
    processCancelOrder(orderId, guestJWT)
    dispatch(setOrderId(null))
  } catch (err) {

  }
}

export const actionGetDeals = (): AppThunk => async dispatch => {
  processGetDeals()
}


export const dealsSelector: (state: RootState, productId: number) => Deal[] = createSelector(
  (state: RootState) => state.productList.productEntities,
  (state: RootState) => state.productList.dealEntities,
  (state: RootState, productId: number) => productId,
  (products, deals, productId) => {
    const product = products[productId]
    let result: Deal[] = []
    for (let dealId of product.deals) {
      result.push(deals[dealId])
    }
    return result  
  }
)

export const discountSelector: (state: RootState, productId: number) => number | null = createSelector(
  (state: RootState) => state.productList.productEntities,
  (state: RootState) => state.productList.dealEntities,
  (state: RootState, productId: number) => productId,
  (state: RootState) => state.paymentForm.activeDealId,
  (products, deals, productId, dealId) => {
    if (productId === null || dealId === null) {
      return null
    }
    const product = products[productId]
    const deal = deals[dealId]
    return calculateDiscount(deal, product.price)
  }
)

export const bestDealSelector: (state: RootState, productId: number) => any = createSelector(
  (state: RootState) => state.productList.productEntities,
  (state: RootState) => state.productList.dealEntities,
  (state: RootState, productId: number) => productId,
  (products, deals, productId) => {
    const product = products[productId]
    if (product) {
      let bestDiscount: number = 0
      let bestDiscountId: number = -1
      let result: Deal[] = []
      for (const [i, dealId] of product.deals.entries()) {
        const deal = deals[dealId]
        let discount_amount = calculateDiscount(deal, product.price)
        if (discount_amount > bestDiscount) {
          bestDiscount = discount_amount
          bestDiscountId = dealId
        }
      }
      return {
        deal: deals[bestDiscountId],
        discountAmount: bestDiscount
      }
    } else {
      return {
        deal: null,
        discountAmount: 0
      }
    }
  }
)

export const actionApplyDeal = (dealId: number | null): AppThunk => async dispatch => {
  dispatch(setActiveDealId(dealId))
}

export const calculateDiscount = (deal: Deal, price: number) => {
	let discount_amount
	if (deal.discount_type === 'percentage') {
		discount_amount = price * deal.discount
	} else if (deal.discount_type === 'amount') {
		discount_amount = deal.discount
	} else {
		discount_amount = 0.0
	}
	return discount_amount
}

export const actionGetRunningDeals = (): AppThunk => async dispatch => {
  let result = await getRunningDeals()
}

export const actionDoRoyalPay = (): AppThunk => async (dipatch, getState) => {
  try {
    const paymentForm = getState().paymentForm
    //let price = product.price - discount

    if (paymentForm.orderId !== null) {
      const royalPayOrder = await processRoyalPay(paymentForm.orderId)
      window.location.href = royalPayOrder.url
    } else {
      console.log('Order error, order Id is still not set')
    }
    return
  } catch (err) {
  }
}

export const actionDoRoyalQR = (): AppThunk => async (dispatch, getState) => {
  try {
    const paymentForm = getState().paymentForm
    //let price = product.price - discount

    if (paymentForm.orderId !== null) {
      const royalPayOrder = await processRoyalPayQR(paymentForm.orderId)
      window.location.href = royalPayOrder.url
    } else {
      console.log('Order error, order Id is still not set')
    }
    return
  } catch (err) {
  }
}
export const actionDoRoyalQRNative = (): AppThunk => async (dispatch, getState) => {
  try {
    const paymentForm = getState().paymentForm
    //let price = product.price - discount

    if (paymentForm.orderId !== null) {
      const royalPayOrder = await processRoyalPayQR(paymentForm.orderId)
      //window.location.href = royalPayOrder.url
      //
      console.log(royalPayOrder.wechat_url, royalPayOrder.alipay_url)
      return royalPayOrder
    } else {
      console.log('Order error, order Id is still not set')
    }
  } catch (err) {
  }
}
