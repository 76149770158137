import React from 'react'

import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'app/rootReducer'
import { actionSwitchToUserInfo, actionSwitchToFavorite, actionSwitchToOrders, isLoggedInSelector } from 'features/user/userSlice'


export default () => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state.user)
  const isLoggedIn = useSelector(isLoggedInSelector)

  const onUserInfoClicked = () => {
    dispatch(actionSwitchToUserInfo())
  }
  const onFavoriteClicked = () => {
    dispatch(actionSwitchToFavorite())
  }

  const onMyOrdersClicked = () => {
    dispatch(actionSwitchToOrders())
  }
  return (
    <section className="function-navigation">
      <div className="profile-tabs flex-grid">
        { isLoggedIn ?
          <>
            <div className="col-3" onClick={onUserInfoClicked}>
              <span className={classNames(['switch-btn', 'noselect'], {active: state.userInfoActive})}>Account Info</span>
            </div> 
            <div className="col-3" onClick={onFavoriteClicked}>
              <span className={classNames(['switch-btn', 'noselect'], {active: state.favoriteActive})}>My Favourite</span>
            </div>
            <div className="col-3" onClick={onMyOrdersClicked}>
              <span className={classNames(['switch-btn', 'noselect'], {active: state.ordersActive})}>My Orders</span>
            </div>
          </>:
            <div className="col-12 center-align" onClick={onFavoriteClicked}>
              <span className={classNames(['switch-btn', 'noselect'], {active: state.favoriteActive})}>My Favourite</span>
            </div>
        }
      </div>
    </section>
  )
}
