import React, { MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Stages, dealsSelector, actionApplyDeal, calculateDiscount } from './paymentFormSlice'
import { Product, API_HOST } from 'api/API'
import { RootState } from 'app/rootReducer'
import classNames from 'classnames'
import userIcon from 'images/user-icon.svg'
import unknownUserIcon from 'images/unknown-user-icon.svg'

const DealItem = ({ id, name, price, discount_amount }: any) => {
  const dispatch = useDispatch()
  const { activeDealId } = useSelector((state: RootState) => state.paymentForm)
  const { userDetail } = useSelector((state: RootState) => state.user)
  const onDealSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (activeDealId === id) {
      dispatch(actionApplyDeal(null))
    } else {
      dispatch(actionApplyDeal(id))
    }
  }
  return (
    <>
      <div className="flex-grid deal-checkbox">
        <input onChange={onDealSelect} type="checkbox"
          checked={ activeDealId === id }
          id={'start_deal_' + id} value={id} name="deal" />
        <label htmlFor={'start_deal_' + id} key={id}>Open a {name} for ${(price - discount_amount).toFixed(2)} (save of ${discount_amount.toFixed(2)})</label>
      </div>
      { activeDealId === id &&
        <div className="participant-list">
          { userDetail !== null ?
            <img src={API_HOST + '/api/user/avatar/' + userDetail.id} className="join-deal-circle" />
            :
            <img src={userIcon} className="join-deal-circle" />
          }
          <img src={unknownUserIcon} className="join-deal-circle" />
          <span>Share with your friends after payment to let them join your wowDeal!</span>
        </div>
      }
    </>
  )
}

export default ({ product }: {product: Product}) => {
  //const [postcode, setPostcode] = useState('')
  const deals = useSelector((state: RootState) => dealsSelector(state, product!.id))
  const { asGuest } = useSelector((state: RootState) => state.paymentForm)

  return (
    asGuest ? null :
      <div className="payment-row">
        { deals.map(d => <DealItem key={d.id} price={product.price}
                discount_amount={calculateDiscount(d, product.price)} {...d} />
        )}
      </div>
  )
}
